import URL from 'url';

export const getService = (url) => {
  if (url.hostname.indexOf('youtube.com') > -1 || url.hostname === 'youtu.be') {
    return 'https://www.youtube.com/embed/';
  }
  if (url.hostname === 'vimeo.com' || url.hostname === 'player.vimeo.com') {
    return 'https://player.vimeo.com/video/';
  }

  if (url.hostname.indexOf('creo.se') > -1) {
    return `${url.protocol}//${url.hostname}/i/`;
  }

  if (url.hostname.indexOf('cvm3.se') > -1) {
    return url.href;
  }

  if (url.hostname.indexOf('microsoftstream.com') > -1) {
    return 'https://web.microsoftstream.com/embed/video/';
  }

  if (url.hostname.indexOf('assaabloy-internal.creomediamanager.com') > -1) {
    return `${url.protocol}//${url.hostname}`;
  }

  if (
    url.hostname.indexOf('assaabloyeur-my.sharepoint.com') > -1 ||
    url.hostname.indexOf('assaabloy-my.sharepoint.com') > -1 ||
    url.hostname.indexOf('assaabloyeur.sharepoint.com') > -1
  ) {
    return url.href;
  }
};

export const getId = (url) => {
  if (url.hostname.indexOf('youtube.com') > -1) {
    return url.query.v;
  }

  if (url.hostname === 'youtu.be') {
    return url.pathname.split('/')[1];
  }

  if (url.hostname === 'vimeo.com') {
    return url.pathname.split('/')[1];
  }
  if (url.hostname === 'player.vimeo.com') {
    return url.pathname.split('/')[2];
  }

  if (url.hostname.indexOf('microsoftstream.com') > -1) {
    // For Urls of pattern https://web.microsoftstream.com/embed/video/id
    if (url.pathname.indexOf('embed') > -1) {
      return url.pathname.split('/')[3];
    }
    // For Urls of pattern https://web.microsoftstream.com/video/id
    return url.pathname.split('/')[2];
  }

  if (url.hostname.indexOf('creo.se') > -1) {
    return url.pathname.split('/')[2];
  }

  if (url.hostname.indexOf('assaabloy-internal.creomediamanager.com') > -1) {
    return url.pathname;
  }
};

export const createEmbedUrl = (url) => {
  if (url.indexOf('cvm3.se') > -1) {
    return url;
  }

  const parsedUrl = URL.parse(url, true);
  if (
    url.indexOf('assaabloyeur-my.sharepoint.com') > -1 ||
    url.indexOf('assaabloy-my.sharepoint.com') > -1 ||
    url.indexOf('assaabloyeur.sharepoint.com') > -1
  ) {
    return getService(parsedUrl);
  }

  return `${getService(parsedUrl)}${getId(parsedUrl)}`;
};

export const isVideoImageUrl = (url) => {
  let isVideoUrl = false;
  if (
    url.indexOf('youtube.com') > -1 ||
    url.indexOf('youtu.be') > -1 ||
    url.indexOf('vimeo.com') > -1 ||
    url.indexOf('vimeocdn.com') > -1 ||
    url.indexOf('creo') > -1 ||
    url.indexOf('cloudfront') > -1 ||
    url.indexOf('microsoftstream.com') > -1 ||
    url.indexOf('cvm3.se') > -1 ||
    url.indexOf('assaabloy-internal.creomediamanager.com') > -1 ||
    url.indexOf('assaabloyeur-my.sharepoint.com') > -1 ||
    url.indexOf('assaabloy-my.sharepoint.com') > -1 ||
    url.indexOf('assaabloyeur.sharepoint.com') > -1
  ) {
    isVideoUrl = true;
  }
  return isVideoUrl;
};
