import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { Shortcut, ShortcutTypeName } from 'api/organization/types';
import { AlertBar, LinkList, Sheet } from 'componentsNew';
import { PageContentHeader } from 'layout';
import { useCallback, useMemo } from 'react';
import { translations } from 'translations';
import {
  GAonShortcutFavoriteClick,
  GAonShortcutLinkClick,
} from 'utils/analytics';

const LABELS: Record<ShortcutTypeName, { title: string; subtitle?: string }> = {
  [ShortcutTypeName.Tools]: {
    title: translations.shortcutsManageTools,
    subtitle: translations.shortcutsPinnedToolsInfo,
  },
  [ShortcutTypeName.MyProjects]: {
    title: '',
  },
};

type ShortcutsSheetProps = {
  shortcuts: Shortcut[];
  shortcutType: ShortcutTypeName;
  maxNumberOfFavorites?: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  updateShortcutFavorite: (id: string, addedAsFavorite: boolean) => void;
};

const ShortcutsSheet = ({
  shortcuts,
  shortcutType,
  maxNumberOfFavorites = Number.MAX_SAFE_INTEGER,
  open,
  setOpen,
  updateShortcutFavorite,
}: ShortcutsSheetProps) => {
  const elementId = `home-shortcuts-sheet-${shortcutType.toLowerCase()}`;

  const visibleShortcuts = useMemo(() => {
    return shortcuts.filter(
      (shortcut) => shortcutType === shortcut.shortcutType.displayName
    );
  }, [shortcuts, shortcutType]);

  const numberOfFavorites = useMemo(() => {
    return visibleShortcuts.filter(
      (visibleShortcut) => visibleShortcut.addedAsFavorite
    ).length;
  }, [visibleShortcuts]);

  const onSwitch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value: id, name: title } = e.target;
      if (checked && numberOfFavorites >= maxNumberOfFavorites) return;
      GAonShortcutFavoriteClick(title, checked);
      updateShortcutFavorite(id, checked);
    },
    [maxNumberOfFavorites, numberOfFavorites, updateShortcutFavorite]
  );

  const { title, subtitle } = LABELS[shortcutType];

  return (
    <Sheet
      id={elementId}
      open={open}
      width="28.5rem"
      onClose={() => setOpen(false)}
    >
      <PageContentHeader text={title}>
        {subtitle && (
          <Typography
            variant="body2"
            sx={(theme) => ({ color: theme.colors.text.tertiary })}
          >
            {subtitle}
          </Typography>
        )}
      </PageContentHeader>
      <AlertBar
        type="warning"
        open={numberOfFavorites >= maxNumberOfFavorites}
        text={translations.shortcutsPinnedToolsLimit}
        sx={(theme) => ({ margin: `${theme.spacing('sm')} 0` })}
      />
      <LinkList
        id={`${elementId}-list`}
        items={visibleShortcuts.map((shortcut, index) => ({
          id: shortcut.id,
          title: shortcut.title,
          descriptions: [shortcut.description],
          href: shortcut.url,
          target: shortcut.openInNewWindow === 'true' ? '_blank' : '_self',
          divider: index < visibleShortcuts.length - 1,
          elementExcludedFromLink: true,
          element: (
            <Switch
              name={shortcut.title}
              value={shortcut.id}
              checked={shortcut.addedAsFavorite}
              onChange={onSwitch}
              disabled={
                !shortcut.addedAsFavorite &&
                numberOfFavorites >= maxNumberOfFavorites
              }
            />
          ),
        }))}
        onClick={(item) => {
          const clickedShortcut = shortcuts.find(
            (shortcut) => shortcut.id === item.id
          );
          if (clickedShortcut) {
            GAonShortcutLinkClick(
              clickedShortcut.shortcutType.displayName,
              clickedShortcut.title
            );
          }
        }}
      />
    </Sheet>
  );
};

export { ShortcutsSheet };
