import './EmbedMSPowerBIButton.scss';

import MSPowerBIModal from 'components/Editable/Files/MSPowerBIModal';

import { ReactComponent as ChartHistogram } from './icons/ChartHistogram.svg';

type EmbedMSPowerBIButtonProps = {
  onEmbedURL: (url: string) => void;
  title: string;
  close: () => void;
};

const EmbedMSPowerBIButton = ({
  onEmbedURL,
  title,
  close,
}: EmbedMSPowerBIButtonProps) => {
  return (
    <MSPowerBIModal
      onSubmit={(data) => {
        onEmbedURL(data);
        close();
      }}
    >
      <div className="embed-mspowerbi-button__container">
        <button>
          <ChartHistogram height={42} width={42} />
        </button>
        <p className="embed-mspowerbi-button__label">{title}</p>
      </div>
    </MSPowerBIModal>
  );
};

export default EmbedMSPowerBIButton;
