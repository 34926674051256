import { InputProps } from '@mui/material/Input';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Icon } from 'componentsNew/Icon/Icon';
import { useMemo, useState } from 'react';

import { ReactComponent as SelectIcon } from './SelectIcon.svg';

const EMPTY_VALUE = 'none' as const;

export type SelectItem = { name: string; value: string; disabled?: boolean };

type SingleSelectProps = {
  id?: string;
  multiple?: false;
  value: SelectItem | null;
  items: SelectItem[];
  error?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  // "allowEmpty" only works for single select, since
  // multiple select depends on user being able to unselect items
  allowEmpty?: boolean;
  // "closeOnSelect" only works for multiple select,
  // for single select the options will always close on change
  closeOnSelect?: boolean;
  disableCheckIcon?: boolean;
  placeholder?: string;
  inputProps?: InputProps['inputProps'];
  inputRef?: MuiSelectProps['inputRef'];
  sx?: SxProps<Theme>;
  onChange: (value: SelectItem | null) => void;
  onBlur?: () => void;
};

type MultipleSelectProps = {
  multiple: true;
  value: SelectItem[];
  onChange: (value: SelectItem[]) => void;
} & Omit<SingleSelectProps, 'multiple' | 'value' | 'onChange'>;

export type SelectProps = SingleSelectProps | MultipleSelectProps;

const Select = ({
  id,
  value,
  items,
  error,
  disabled,
  fullWidth,
  closeOnSelect,
  allowEmpty = false,
  disableCheckIcon = false,
  placeholder,
  inputProps,
  inputRef,
  sx,
  onChange,
  onBlur,
  multiple,
}: SelectProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const selectValue = useMemo(() => {
    if (multiple) {
      return !value.length ? [EMPTY_VALUE] : value.map((item) => item.value);
    }
    return value?.value || EMPTY_VALUE;
  }, [value, multiple]);

  const isSelectValueEmpty = useMemo(() => {
    if (multiple) {
      return selectValue[0] === EMPTY_VALUE;
    }
    return selectValue === EMPTY_VALUE;
  }, [multiple, selectValue]);

  return (
    <MuiSelect
      id={id}
      size="small"
      variant="outlined"
      fullWidth={fullWidth}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={selectValue}
      error={error}
      disabled={disabled}
      multiple={multiple}
      inputProps={inputProps}
      inputRef={inputRef}
      MenuProps={{ disablePortal: true }}
      IconComponent={SelectIcon}
      renderValue={
        placeholder && isSelectValueEmpty
          ? () => (
              <Typography
                sx={(theme) => ({ color: theme.colors.text.disabled })}
              >
                {placeholder}
              </Typography>
            )
          : undefined
      }
      sx={[
        (theme) => ({
          ...(!isSelectValueEmpty && { color: theme.colors.text.tertiary }),
          ...(!disableCheckIcon && {
            '.Mui-selected > span': { display: 'block' },
          }),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onChange={(e) => {
        if (!multiple) {
          const targetValue = e.target.value as string;
          const newValue =
            items.find((item) => item.value === targetValue) || null;
          onChange(newValue);
          return;
        }
        const targetValues = e.target.value as string[];

        const selectedEmpty =
          targetValues.some((value) => value === EMPTY_VALUE) &&
          !isSelectValueEmpty;

        if (selectedEmpty) {
          onChange([]);
          return;
        }
        const newValues = items.filter((item) =>
          targetValues.some((targetValue) => targetValue === item.value)
        );
        onChange(newValues);

        if (closeOnSelect && newValues.length > value.length) {
          setOpen(false);
        }
      }}
      onBlur={onBlur}
    >
      {placeholder && (
        <MuiMenuItem
          disabled={!allowEmpty}
          value={EMPTY_VALUE}
          sx={(theme) => ({
            color: theme.colors.text.tertiary,
            textWrapMode: 'wrap',
          })}
        >
          {placeholder}
        </MuiMenuItem>
      )}
      {items.map((item, index) => (
        <MuiMenuItem
          key={item.value || `select-${index}`}
          value={item.value}
          disabled={item.disabled}
          sx={{ textWrapMode: 'wrap' }}
        >
          {item.name}
          {!disableCheckIcon && (
            <Icon
              type="checkCircleFilled"
              color="brandBase"
              size={20}
              sx={{ display: 'none', marginLeft: 'auto' }}
            />
          )}
        </MuiMenuItem>
      ))}
    </MuiSelect>
  );
};

export { Select };
