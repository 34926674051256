import { ArticleContentTypeEnum } from 'enums';

export const DIVISION_DISPLAY_NAME_BY_ID: Record<string, string> = {
  'division-26': 'Entrance systems Division',
  'division-29': 'Americas Division',
  'division-31': 'EMEIA Division',
  'division-32': 'Global Solutions Division',
  'division-33': 'Group Center',
  'division-48': 'HID Division',
  'division-148': 'Pacific & North East Asia',
  'division-147': 'Greater China & South East Asia',
};

type NewsFlashResponseRelationshipKey =
  | 'author'
  | 'country'
  | 'division'
  | 'department'
  | 'site'
  | 'company'
  | 'segment';

type NewsFlashResponseRelationshipValue = {
  data: {
    id: string;
    type: string;
  };
};

export type NewsFlashesResponse = {
  data: {
    data: Array<{
      id: string;
      type: string;
      attributes: {
        title: string;
        subTitle?: string;
        description?: string;
        locale: string;
        isTopNews?: boolean;
        disableComments?: boolean;
        imageAltText?: string;
        imageHeight?: number;
        imageWidth?: number;
        imageUrl?: string;
        publishedUtc?: string;
        tags?: string[];
        readingTime?: number;
        containsImageGallery: boolean;
      };
      relationships?: Partial<
        Record<
          NewsFlashResponseRelationshipKey,
          NewsFlashResponseRelationshipValue
        >
      >;
    }>;
    included?: Array<{
      id: string;
      type: string;
      attributes: { name: string };
    }>;
  };
};

export type NewsFlash = {
  id: string;
  type: ArticleContentTypeEnum;
  title: string;
  subTitle?: string;
  description?: string;
  isTopNews: boolean;
  disableComments: boolean;
  imageAltText?: string;
  imageUrl?: string;
  imageWidth?: number;
  imageHeight?: number;
  publishedUtc: string;
  readingTime?: number;
  containsImageGallery: boolean;
  tags: string[];
  author?: {
    id: string;
    name: string;
  };
  country?: {
    id: string;
    name: string;
  };
  division?: {
    id: string;
    name: string;
  };
  department?: {
    id: string;
    name: string;
  };
  site?: {
    id: string;
    name: string;
  };
  company?: {
    id: string;
    name: string;
  };
  segment?: {
    id: string;
    name: string;
  };
};

const newsFlashesResponseToNewsFlashes = (response: NewsFlashesResponse) => {
  const newsFlashes = response.data.data
    .map((item) => {
      if (!item.attributes.publishedUtc) {
        return null;
      }
      const newsFlash: NewsFlash = {
        id: item.id,
        type: item.type as ArticleContentTypeEnum,
        title: item.attributes.title,
        subTitle: item.attributes.subTitle,
        description: item.attributes.description,
        imageAltText: item.attributes.imageAltText,
        imageUrl: item.attributes.imageUrl,
        imageWidth: item.attributes.imageWidth,
        imageHeight: item.attributes.imageHeight,
        publishedUtc: item.attributes.publishedUtc,
        readingTime: item.attributes.readingTime,
        tags: item.attributes.tags || [],
        disableComments: item.attributes.disableComments || false,
        isTopNews: item.attributes.isTopNews || false,
        containsImageGallery: item.attributes.containsImageGallery,
      };

      const relationshipMap = item.relationships;
      const includedRelationshipData = response.data.included;

      if (!relationshipMap || !includedRelationshipData) {
        return newsFlash;
      }
      const relationshipsKeys = Object.keys(
        relationshipMap
      ) as NewsFlashResponseRelationshipKey[];

      relationshipsKeys.forEach((relationshipsKey) => {
        const relationshipValue = relationshipMap[relationshipsKey];
        const relationshipId = relationshipValue?.data.id;
        if (!relationshipId) return;
        const relationshipData = includedRelationshipData.find(
          (data) => data.id === relationshipId
        );
        if (!relationshipData) return;
        const relationshipName = relationshipData.attributes.name;
        newsFlash[relationshipsKey] = {
          id: relationshipId,
          name: relationshipName,
        };
      });
      return newsFlash;
    })
    .filter(Boolean) as NewsFlash[];
  return newsFlashes;
};

export { newsFlashesResponseToNewsFlashes };
