import './JobsArticle.scss';

import { getPosition, headers } from 'api/jobs';
import Box from 'components/Box';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import ShareLink from 'components/Button/ShareLink';
import Error from 'components/Error';
import Fetcher from 'components/Fetcher';
import { Column, Container } from 'components/Grid';
import { ReactComponent as PinIcon } from 'components/Icon/Pin.svg';
import Img from 'components/Image';
import CareerItem from 'components/JobsList/careerItem/creerItem';
import PageTitle from 'components/PageTitle';
import Parser from 'components/Parser';
import Title from 'components/Title';
import { parseISO } from 'date-fns';
import { Jobs as NewJobsPage } from 'pagesAvenue';
import { GAitemNotFound } from 'utils/analytics';
import { FeatureToggle, featureToggle } from 'utils/misc/featureToggle';
import { getDaysAgo } from 'utils/misc/time';

import SimilarJobs from './components/SimilarJobs';
import ContentLoader from './ContentLoader';
import headerImage from './Images/header_assaabloy_v3.jpg';

const RenderDate = ({ due }) => {
  const daysDue = getDaysAgo(due);
  const isSoonDue = daysDue >= -10 && daysDue < 0;

  const date = parseISO(due);
  let text;
  if (isSoonDue) {
    const day = daysDue === -1 ? 'day' : 'days';
    const daysDueText = `${Math.abs(daysDue)} ${day} left to apply`;
    text = daysDueText;
  } else {
    text = `Last application date: ${date.toLocaleDateString()}`;
  }

  return <span className="jobs-article__date">{text}</span>;
};

const ApplyButton = ({ url, title, id }) => (
  <Button
    className="jobs-article__apply-button"
    width="narrow"
    outbound={true}
    analyticsAction="Found job"
    analyticsLabel={`${title} (${id})`}
    to={url}
  >
    Apply to this position
  </Button>
);

const onError = (status, message) => {
  GAitemNotFound(status, message, 'job');
  return <Error message={message} status={status} redirectOn404={true} />;
};

const JobArticle = ({ match }) => {
  const isNewJobsPageActive = featureToggle(FeatureToggle.NewDesignJobsPage);

  if (isNewJobsPageActive) {
    return <NewJobsPage />;
  }

  return (
    <Fetcher
      source={getPosition}
      headers={headers}
      param={match.params.id}
      onLoading={ContentLoader}
      onError={(status, message) => onError(status, message, match.params.id)}
    >
      {({ response }) => (
        <>
          <PageTitle titles={['Jobs', response.name]} />
          <Container>
            <Column>
              <Breadcrumbs
                path="/jobs"
                includeHome
                items={[{ title: 'Jobs' }, { title: response.name }]}
              />
            </Column>
          </Container>
          <Container className="jobs-article" mobileColumnSpacing={true}>
            <Column span={2}>
              <Box className="p-b-2 m-t-4 lg-m-t-0">
                <Title className="p-0">{response.name}</Title>
                <hr className="m-t-4 m-b-4" />

                <div className="jobs-article__info-wrapper">
                  <div>
                    <span className="jobs-article__category">
                      {response.category.name}
                    </span>
                    <span className="jobs-article__seniority">
                      {response.seniorityLevel}
                    </span>
                    {response.department && (
                      <span className="jobs-article__department">
                        {response.department.name}
                      </span>
                    )}
                    {response.hiringManager ? (
                      <div className="jobs-article__hiring-manager">{`Hiring Manager: ${response.hiringManager?.firstName} ${response.hiringManager?.lastName}`}</div>
                    ) : (
                      <></>
                    )}
                    {response.locations.map(({ name }) => {
                      if (name === 'USA') {
                        return null;
                      }

                      return (
                        <span
                          key={name}
                          className="jobs-article__location-item"
                        >
                          <PinIcon className="jobs-article__pin-icon" />
                          <span>{name}</span>
                        </span>
                      );
                    })}
                  </div>
                </div>
                <hr className="m-t-4 m-b-4" />

                <div className="jobs-article__button-wrapper m-b-4">
                  <ApplyButton
                    url={response.applicationFormUrl}
                    title={response.name}
                    id={response.id}
                  />
                  <ShareLink
                    className="jobs-article__share-link-button"
                    articleTitle={response.name}
                    contentType="jobAdvert"
                  />
                  {response.applicationDueUtc && (
                    <RenderDate due={response.applicationDueUtc} />
                  )}
                </div>
                <div className="content">
                  <Img
                    src={headerImage}
                    alt="Group of smiling people"
                    className="m-b-2"
                  />
                  <Parser
                    html={response.description}
                    type="Jobs"
                    country={response.country}
                    division={response.division}
                  />
                </div>

                <div className="m-t-6">
                  <hr className="m-b-6" />
                  <div className="mobile-center m-b-6">
                    <ApplyButton
                      url={response.applicationFormUrl}
                      title={response.name}
                      id={response.id}
                    />
                  </div>
                </div>
              </Box>
            </Column>
            <Column>
              <SimilarJobs
                seniorityLevel={response.seniorityLevel}
                category={response.category}
                locations={response.locations}
                id={response.id}
              />
              <ul className="jobslist no-list-style">
                <CareerItem jobsPage={true} className="article-career" />
              </ul>
            </Column>
          </Container>
        </>
      )}
    </Fetcher>
  );
};
export default JobArticle;
