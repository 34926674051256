import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Job } from 'api/jobs/types';
import { useUser } from 'components/Context/User';
import { Badge, ShareButton } from 'componentsNew';
import * as shareButtonHelpers from 'componentsNew/ShareButton/helpers';
import { AvenueRouteEnum } from 'enums';
import { useMemo } from 'react';
import { translations } from 'translations';
import { GAonApplyToJobClick, GAonArticleShared } from 'utils/analytics';
import * as textUtils from 'utils/misc/text';
import * as timeUtils from 'utils/misc/time';
import { getNormalFormattedDate } from 'utils/misc/time';

type JobContentActionsProps = {
  job: Job;
  elementId: string;
};

const JobContentActions = ({ job, elementId }: JobContentActionsProps) => {
  const user = useUser();

  const jobLink = `${window.location.origin}${AvenueRouteEnum.Jobs}?id=${job.id}`;

  const applyNowElement = useMemo(() => {
    if (!job.applicationFormUrl) {
      return null;
    }
    return (
      <Button
        id={`${elementId}-apply`}
        variant="outlined"
        href={job.applicationFormUrl}
        target="_blank"
        onClick={() => GAonApplyToJobClick(job.id, job.title)}
      >
        {translations.jobsApply}
      </Button>
    );
  }, [elementId, job.applicationFormUrl, job.id, job.title]);

  const shareElement = useMemo(() => {
    return (
      <ShareButton
        id={`${elementId}-share`}
        variant="textButton"
        sx={{ marginRight: 'auto' }}
        copyLink={jobLink}
        emailLink={shareButtonHelpers.getShareJobEmailLink({
          title: job.title,
          link: jobLink,
          senderName: user.displayName,
        })}
        onCopyLinkClick={() => GAonArticleShared(job.title)}
        onSendEmailClick={() => GAonArticleShared(job.title)}
      />
    );
  }, [elementId, job.title, jobLink, user.displayName]);

  const badgeElement = useMemo(() => {
    const daysToExpiration = timeUtils.getDaysAgo(job.applicationDueUtc) * -1;
    const isSoonToExpire = daysToExpiration <= 10 && daysToExpiration > 0;

    if (isSoonToExpire) {
      const label = textUtils.replaceTranslationAliases(
        daysToExpiration === 1
          ? translations.jobsApplicationDaysLeftOne
          : translations.jobsApplicationDaysLeftMany,
        { count: daysToExpiration }
      );
      return (
        <Badge
          id={`${elementId}-expiry-info`}
          size="small"
          color="warning"
          variant="outlined"
          label={label}
        />
      );
    }

    const daysSinceCreation = timeUtils.getDaysAgo(job.createdUtc);
    const isRecentlyCreated = daysSinceCreation >= 0 && daysSinceCreation < 4;

    if (isRecentlyCreated) {
      return (
        <Badge
          id={`${elementId}-created-info`}
          size="small"
          color="success"
          variant="outlined"
          label="New"
        />
      );
    } else {
      const formattedDate = getNormalFormattedDate(job.applicationDueUtc);
      return (
        <Badge
          id={`${elementId}-expiry-info`}
          size="small"
          color="warning"
          variant="outlined"
          label={`${translations.jobsLastApplicationdate} ${formattedDate}`}
        />
      );
    }
  }, [elementId, job.applicationDueUtc, job.createdUtc]);

  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: theme.spacing('xs'),
        alignItems: 'center',
        padding: `${theme.spacing('xxs')} 0`,
      })}
    >
      {applyNowElement}
      {shareElement}
      {badgeElement}
    </Stack>
  );
};

export { JobContentActions };
