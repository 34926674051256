import * as alertApi from 'api/cms/alert';
import { useSnackbar } from 'context';
import { AvenueRouteEnum } from 'enums';
import { Page } from 'layout';
import { useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { translations } from 'translations';
import * as formUtils from 'utils/form';

import { AlertForm, DEFAULT_VALUES, FormValues } from './AlertForm';
import { transformFormValuesToCreatePayload } from './helpers';

const AlertCreate = () => {
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  const methods = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
    shouldFocusError: true,
    mode: 'all',
  });

  const { setError, setValue, getValues } = methods;

  const setFormError = useCallback(
    (message: string) => {
      showSnackbar({
        type: 'error',
        text: message,
      });
      setError('root', {});
    },
    [setError, showSnackbar]
  );

  const beforeSubmit = useCallback(() => {
    const formValues = getValues();
    const trimmedValues = formUtils.trimValues(formValues);
    const fieldNames = Object.keys(formValues) as (keyof FormValues)[];

    for (const fieldName of fieldNames) {
      const formValue = formValues[fieldName];
      const trimmedValue = trimmedValues[fieldName];
      if (trimmedValue !== formValue) {
        setValue(fieldName, trimmedValue, { shouldValidate: true });
      }
    }
  }, [getValues, setValue]);

  const handleSubmit: SubmitHandler<FormValues> = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = transformFormValuesToCreatePayload(formValues);

        await alertApi.createAlert(payload);
        showSnackbar({
          type: 'success',
          text: translations.alertCreateSuccess,
        });
        history.push(AvenueRouteEnum.ProfileManageAlerts);
      } catch {
        setFormError(translations.alertCreateError);
        return;
      }
    },
    [history, setFormError, showSnackbar]
  );

  return (
    <Page
      title={[translations.alert, translations.create]}
      breadcrumbs={[
        {
          text: translations.pageTitleHome,
          to: AvenueRouteEnum.Home,
        },
        {
          text: translations.alert,
          to: AvenueRouteEnum.ProfileManageAlerts,
        },
        { text: translations.create },
      ]}
    >
      <FormProvider {...methods}>
        <AlertForm beforeSubmit={beforeSubmit} onSubmit={handleSubmit} />
      </FormProvider>
    </Page>
  );
};

export { AlertCreate };
