import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Icon } from 'componentsNew';
import { translations } from 'translations';

type SheetWidth = '28.5rem' | '33.125rem' | '40.5rem';

type SheetProps = {
  id?: string;
  open: boolean;
  width?: SheetWidth;
  showCloseIcon?: boolean;
  sx?: SxProps<Theme>;
  children: React.ReactNode | React.ReactNode[];
  onClose: () => void;
};

const Sheet = ({
  id,
  open,
  width = '28.5rem',
  showCloseIcon = true,
  sx,
  children,
  onClose,
}: SheetProps) => {
  return (
    <Drawer
      id={id}
      anchor="right"
      keepMounted
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: [
          (theme) => ({
            maxWidth: '100%',
            width,
            border: 'none',
            boxShadow: theme.elevation.md,
            padding: theme.spacing('md'),
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ],
      }}
    >
      {showCloseIcon && (
        <IconButton
          size="large"
          aria-label={translations.close}
          sx={(theme) => ({
            position: 'absolute',
            top: theme.spacing('xs'),
            right: theme.spacing('xs'),
            zIndex: 2,
          })}
          onClick={onClose}
        >
          <Icon type="xMark" color="secondary" />
        </IconButton>
      )}
      {children}
    </Drawer>
  );
};

export { Sheet };
