import LibraryMenu from 'components/LibraryMenu';
import Title from 'components/Title';
import { useLibraryMenu, useLibraryRoot } from 'context';
import { NavLink } from 'react-router-dom';

const TreePicker = () => {
  const libraryRoot = useLibraryRoot();
  const libraryMenu = useLibraryMenu();

  if (libraryRoot.isLoading) {
    return null;
  }
  const rootNodes = libraryRoot.nodes;

  if (libraryMenu.isGroupLibrary) {
    return (
      <Title inline="true" color="white">
        Group Library
      </Title>
    );
  }
  if (rootNodes.length === 2) {
    return (
      <Title inline="true" color="white">
        {rootNodes[1].title}
      </Title>
    );
  }

  return (
    <LibraryMenu>
      {rootNodes.slice(1, rootNodes.length).map((item, i, arr) => {
        const path = item.path;
        const nextPath = arr[i + 1] ? arr[i + 1].path : null;
        const nextPlusOnePath = arr[i + 2] ? arr[i + 2].path : null;

        return (
          <NavLink
            key={path}
            to={`/library${path}`}
            isActive={(_, location) => {
              return (
                location.pathname.includes(path) &&
                !location.pathname.includes(nextPath) &&
                !location.pathname.includes(nextPlusOnePath)
              );
            }}
          >
            {item.title}
          </NavLink>
        );
      })}
    </LibraryMenu>
  );
};

export default TreePicker;
