import { Component } from 'react';

import { updateGAWithUserData } from '../../../utils/analytics';

class UserMetaGA extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.division !== this.props.division ||
      prevProps.department !== this.props.department ||
      prevProps.country !== this.props.country ||
      prevProps.segmentId !== this.props.segmentId
    ) {
      const {
        division,
        department,
        regionId,
        isManager,
        countryName,
        segmentId,
      } = this.props;

      if (division) {
        updateGAWithUserData(
          division,
          department,
          regionId,
          isManager,
          countryName,
          segmentId
        );
      }
    }
  }

  render() {
    return null;
  }
}

export default UserMetaGA;
