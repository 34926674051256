import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function useSearchParams() {
  const location = useLocation();
  const history = useHistory();

  const urlSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  return useMemo(
    () => ({
      get: (key: string) => {
        return urlSearchParams.get(key);
      },
      addOrUpdate: (key: string, value: string) => {
        urlSearchParams.set(key, value);
        history.replace({
          pathname: location.pathname,
          search: urlSearchParams.toString(),
        });
      },
      remove: (key: string) => {
        urlSearchParams.delete(key);
        history.replace({
          pathname: location.pathname,
          search: urlSearchParams.toString(),
        });
      },
    }),
    [history, location.pathname, urlSearchParams]
  );
}

export { useSearchParams };
