import './Comments.scss';

import Button from '@mui/material/Button';
import { CommentDataTypeEnum } from 'api/feedback/types';
import Comment from 'api/models/Comment';
import PageHandler from 'api/models/PageHandler';
import classNames from 'classnames';
import { UserConsumer } from 'components/Context/User';
import withConsumer from 'components/Context/withConsumer';
import Error from 'components/Error';
import { Icon } from 'componentsNew';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { translations } from 'translations';
import { GAonSubmitCommentOrReply } from 'utils/analytics';
import animateScroll from 'utils/misc/animateScroll';

import { CommentInput } from './CommentInput/CommentInput';
import { CommentItem } from './CommentItem/CommentItem';

const getInitialState = (limit) => ({
  isCreating: false,
  isLoading: true,
  comments: [],
  commentCount: 0,
  pageHandler: new PageHandler({ limit: limit || 5 }),
});

class Comments extends Component {
  state = getInitialState(this.props.limit);

  componentDidMount() {
    this._isMounted = true;

    this.loadData();
  }

  onRefChange = (node) => {
    if (!node) {
      return;
    }
    const search = window.location.search;
    const params = new URLSearchParams(search);

    if (params.has('commentId')) {
      node.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    if (params.has('targetId')) {
      if (node.id === params.get('targetId')) {
        node.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.articleId !== prevProps.articleId) {
      this.setState(getInitialState(this.props.limit), this.loadData);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  asyncSetState = (newState) => {
    if (this._isMounted) {
      this.setState(newState);
    }
  };

  doAsyncAction = (fn) => {
    return fn().catch((error) => {
      this.asyncSetState({
        error: true,
        errorMessage: error.message,
      });

      return false;
    });
  };

  loadData = async () => {
    const { articleId } = this.props;
    const { pageHandler, comments } = this.state;

    const out = await this.doAsyncAction(() =>
      Comment.getComments(articleId, pageHandler.getQuery())
    );

    if (out && this._isMounted) {
      const newPageHandler = pageHandler.createFromResponse(out);

      const { data: newComments } = out;

      const allComments = comments.concat(newComments);

      // Remove duplicate object by ID from array, since pagination doesn't include newly added comments
      const uniqueById = _.uniqBy(allComments, 'id');

      const sorted = uniqueById.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      this.setState({
        comments: sorted,
        isLoading: false,
        pageHandler: newPageHandler,
        commentCount: newPageHandler.totalPublished,
      });
    }
  };

  onSubmitComment = async (text) => {
    this.setState({ isCreating: true });

    const {
      userContext,
      articleId,
      articleTitle,
      author,
      editor,
      type,
      itemSubType,
      additionalFeedbackReceivers,
    } = this.props;

    const comment = await this.doAsyncAction(() =>
      Comment.create(
        text,
        articleId,
        userContext,
        author && author.oid,
        editor && editor.oid,
        type,
        itemSubType,
        articleId,
        CommentDataTypeEnum.Comment,
        additionalFeedbackReceivers
      )
    );

    if (comment && this._isMounted) {
      GAonSubmitCommentOrReply(articleTitle, false);
      const newCommentCount = this.state.commentCount + 1;
      this.setState((prevState) => ({
        comments: [comment, ...prevState.comments],
        isCreating: false,
        commentCount: newCommentCount,
      }));
      this.props.onCommentsCountUpdated &&
        this.props.onCommentsCountUpdated(newCommentCount);
      return true;
    }
    return false;
  };

  increaseCommentCount = () => {
    const newCommentCount = this.state.commentCount + 1;
    this.setState({
      ...this.state,
      commentCount: newCommentCount,
    });
    this.props.onCommentsCountUpdated &&
      this.props.onCommentsCountUpdated(newCommentCount);
  };

  decreaseCommentCount = (decreaseValue = 1) => {
    const newCommentCount = this.state.commentCount - decreaseValue;
    this.setState({
      ...this.state,
      commentCount: newCommentCount,
    });
    this.props.onCommentsCountUpdated &&
      this.props.onCommentsCountUpdated(newCommentCount);
  };

  updateComment = async (comment, newAttributes) => {
    const updatedComment = await this.doAsyncAction(() =>
      comment.update(CommentDataTypeEnum.Comment, newAttributes)
    );

    if (updatedComment && this._isMounted) {
      this.setState((prevState) => ({
        // replace old comment with new one
        comments: prevState.comments.map((comment) =>
          comment.id === updatedComment.id ? updatedComment : comment
        ),
      }));
    }
  };

  onDeleteComment = async (comment) => {
    const result = await this.doAsyncAction(() =>
      comment.delete(CommentDataTypeEnum.Comment, this.props.articleId)
    );

    if (result !== false && this._isMounted) {
      const newCommentCount = comment.attributes.published
        ? this.state.commentCount - 1
        : this.state.commentCount;

      this.setState((prevState) => ({
        // remove deleted comment from list
        comments: prevState.comments.filter((item) => item.id !== comment.id),
        commentCount: newCommentCount,
      }));
      this.props.onCommentsCountUpdated &&
        this.props.onCommentsCountUpdated(newCommentCount);
    }

    return result !== false;
  };

  onUnpublishComment = async (comment) => {
    await this.updateComment(comment, { published: false });
    this.decreaseCommentCount();
  };

  onRepublishComment = async (comment) => {
    await this.updateComment(comment, { published: true });
    this.increaseCommentCount();
  };

  render() {
    const { userContext, className } = this.props;
    const {
      comments,
      isCreating,
      isLoading,
      error,
      pageHandler,
      errorMessage,
    } = this.state;

    if (isLoading) {
      return null;
    }

    if (error) {
      return <Error message={errorMessage} />;
    }

    const commentClassNames = classNames('comments', className);

    return (
      <div className={commentClassNames} ref={this.onRefChange}>
        {!this.props.hideTopDivider && <hr className="m-b-6 m-t-6" />}
        <CommentInput
          ref={
            this.props.commentInputRef
              ? this.props.commentInputRef
              : (e) => (this.commentInput = e)
          }
          onSubmit={this.onSubmitComment}
          userContext={userContext}
          isCreating={isCreating}
          maxLength={this.props.commentInputMaxLength}
          commentInputTexts={this.props.commentInputTexts}
        />
        {comments.length > 0 && (
          <ul>
            {comments.map((comment) => (
              <li key={comment.id} id={comment.id} ref={this.onRefChange}>
                <CommentItem
                  comment={comment}
                  userContext={userContext}
                  onDelete={this.onDeleteComment}
                  onUnpublish={this.onUnpublishComment}
                  onRepublish={this.onRepublishComment}
                  articleTitle={this.props.articleTitle}
                  increaseCommentCount={this.increaseCommentCount}
                  decreaseCommentCount={this.decreaseCommentCount}
                  articleId={this.props.articleId}
                  type={this.props.type}
                  itemSubType={this.props.itemSubType}
                />
              </li>
            ))}
          </ul>
        )}
        {pageHandler.hasMore() && (
          <Button
            variant="outlined"
            size="small"
            disabled={isLoading}
            sx={(theme) => ({
              display: 'flex',
              margin: `${theme.spacing('sm')} auto 0 auto`,
            })}
            onClick={() => {
              this.loadData();
              animateScroll(220, 50);
            }}
            endIcon={
              <Icon type="ellipsisHorizontal" color="brandBase" size={16} />
            }
          >
            {this.props.commentInputTexts?.loadMore ||
              translations.loadMoreComments}
          </Button>
        )}
      </div>
    );
  }
}

Comments.propTypes = {
  articleId: PropTypes.string.isRequired,
  limit: PropTypes.number,
  hideTopDivider: PropTypes.bool,
  commentInputMaxLength: PropTypes.number,
  commentInputTexts: PropTypes.object,
  commentInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  onCommentsCountUpdated: PropTypes.func,
};

Comments.defaultProps = {
  hideTopDivider: false,
};

export default withConsumer(UserConsumer, Comments, 'userContext');
