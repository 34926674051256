import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

type PageTitleProps = { hidden?: string; display?: string };

const PageTitle = ({ hidden, display }: PageTitleProps) => {
  const hiddenElement = useMemo(
    () =>
      Boolean(hidden) ? (
        <Typography variant="srOnly" component="h1">
          {hidden}
        </Typography>
      ) : null,
    [hidden]
  );

  const displayElement = useMemo(
    () =>
      Boolean(display) ? (
        <Typography
          variant="h1"
          component={Boolean(hidden) ? 'span' : 'h1'}
          sx={(theme) => ({ display: 'block', color: theme.colors.text.brand })}
        >
          {display}
        </Typography>
      ) : null,
    [display, hidden]
  );

  return (
    <>
      {hiddenElement}
      {displayElement}
    </>
  );
};

export { PageTitle };
