import Box from '@mui/material/Box';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';

type JobListTotalProps = {
  total: number;
  sx?: StackProps['sx'];
};

const JobListTotal = ({ total, sx }: JobListTotalProps) => {
  return (
    <Stack flexDirection="row" alignItems="center" sx={sx}>
      <Typography
        variant="body2"
        sx={(theme) => ({
          fontWeight: 'bold',
          color: theme.colors.text.tertiary,
        })}
      >
        {textUtils.replaceTranslationAliasesWithElements(
          total === 1 ? translations.jobsTotalOne : translations.jobsTotalMany,
          {
            count: (
              <Box
                component="span"
                sx={(theme) => ({
                  display: 'inline-flex',
                  color: theme.colors.text.inversePrimary,
                  backgroundColor: theme.colors.surface.actionPrimaryDefault,
                  borderRadius: '100px',
                  padding: '0.1rem 0.375rem',
                  fontSize: theme.typography.caption.fontSize,
                })}
              >
                {total}
              </Box>
            ),
          },
          true
        )}
      </Typography>
    </Stack>
  );
};

export { JobListTotal };
