import * as cmsLibraryApi from 'api/cms/library';
import {
  LibraryMenuItem,
  LibraryRootNode,
  LibraryRootType,
} from 'api/cms/library/types';
import { useLibraryRoot, useSnackbar } from 'context';
import { AvenueRouteEnum } from 'enums';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { translations } from 'translations';

import * as helpers from './helpers';

type LibraryTreeItem = {
  id: string;
  title: string;
  path: string;
  linkTo: string;
  depth: number;
  isOpen: boolean;
  isContainer: boolean;
  children: LibraryTreeItem[];
  isSelected: boolean;
  isInSelectedPath: boolean;
};

export const LibraryMenuContext = React.createContext<{
  menuTree: LibraryTreeItem[] | null;
  selectedDepth: number;
  selectedPath: string | null;
  selectedRoot: LibraryRootNode | null;
  isGroupLibrary: boolean;
  onAfterCreate: (path: string) => void;
  onAfterEdit: (path: string, oldPath: string) => void;
  onAfterDelete: (path: string) => void;
  onTreeItemToggle: (item: LibraryTreeItem) => void;
} | null>(null);

type LibraryMenuProviderProps = {
  children: React.ReactNode;
};

const LibraryMenuProvider = ({ children }: LibraryMenuProviderProps) => {
  const [openPaths, setOpenPaths] = useState<string[]>([]);

  const [itemsByPath, setItemsByPath] = useState<
    Record<string, LibraryMenuItem[]>
  >({});

  const location = useLocation();
  const libraryRoot = useLibraryRoot();
  const { showSnackbar } = useSnackbar();

  const selectedPath = useMemo(
    () => helpers.getLibraryPathFromUrlPath(location.pathname),
    [location.pathname]
  );

  const selectedRoot = useMemo(() => {
    if (!selectedPath) {
      return null;
    }
    let root: LibraryRootNode | null = null;
    const pathParts = helpers.getPathParts(selectedPath);
    for (const pathPart of pathParts) {
      const matchingRoot = libraryRoot.nodes.find(
        (node) => node.path === pathPart
      );
      root = matchingRoot || root;
    }
    return root;
  }, [libraryRoot.nodes, selectedPath]);

  const selectedDepth = useMemo(() => {
    if (!selectedRoot || !selectedPath) {
      return -1;
    }
    const pathParts = helpers.getPathParts(selectedPath);
    if (
      selectedRoot.folderType === LibraryRootType.Global ||
      selectedRoot.folderType === LibraryRootType.Division
    ) {
      return pathParts.length;
    }
    return pathParts.length - 1;
  }, [selectedPath, selectedRoot]);

  const isGroupLibrary = useMemo(
    () => selectedRoot?.path === AvenueRouteEnum.GroupLibrary,
    [selectedRoot]
  );

  const menuTree = useMemo(() => {
    if (
      !selectedRoot ||
      !selectedPath ||
      !openPaths.length ||
      !itemsByPath ||
      !itemsByPath[selectedRoot.path]
    ) {
      return null;
    }

    const appendChildren = (items: LibraryMenuItem[], depth: number) => {
      return items.map((item) => {
        const isOpen = openPaths.some((path) => path === item.url);
        const isSelected = item.url === selectedPath;
        const isInSelectedPath = (selectedPath || '')?.startsWith(item.url);
        const childItems = itemsByPath[item.url] || [];

        let linkTo = item.url;
        linkTo =
          isOpen && item.container && isSelected
            ? helpers.getParentPath(item.url) || ''
            : linkTo;
        linkTo = helpers.addLibraryRoutePrefix(linkTo);

        const menuItem: LibraryTreeItem = {
          id: item.id,
          title: item.title,
          path: item.url,
          linkTo: linkTo,
          depth: depth,
          isOpen: isOpen,
          isSelected: isSelected,
          isInSelectedPath: isInSelectedPath,
          isContainer: item.container,
          children: appendChildren(childItems, depth + 1),
        };
        return menuItem;
      });
    };
    return appendChildren(itemsByPath[selectedRoot.path], 1);
  }, [itemsByPath, openPaths, selectedPath, selectedRoot]);

  const fetchItems = useCallback(
    async (paths: string[]) => {
      const newItemsByPath: Record<string, LibraryMenuItem[]> = {};
      await Promise.all(
        paths.map(async (path) => {
          try {
            const response = await cmsLibraryApi.getTree(path);
            const items = (response?.data?.data || []) as LibraryMenuItem[];
            newItemsByPath[path] = items;
          } catch {
            newItemsByPath[path] = [];
            showSnackbar({
              type: 'error',
              text: translations.libraryMenuFetchError,
            });
          }
        })
      );
      setItemsByPath((prevItemsByPath) => ({
        ...(prevItemsByPath || {}),
        ...newItemsByPath,
      }));
    },
    [showSnackbar]
  );

  const refreshItemsTwoLevelsUp = useCallback(
    (path: string) => {
      const pathOneUp = helpers.getParentPath(path);
      const pathTwoUp = pathOneUp && helpers.getParentPath(pathOneUp);
      const pathsToRefresh = [pathOneUp, pathTwoUp].filter(Boolean) as string[];
      if (pathsToRefresh.length) {
        fetchItems(pathsToRefresh);
      }
    },
    [fetchItems]
  );

  const onAfterCreate = useCallback(
    (path: string) => {
      refreshItemsTwoLevelsUp(path);
    },
    [refreshItemsTwoLevelsUp]
  );

  const onAfterEdit = useCallback(
    (path: string, oldPath: string) => {
      refreshItemsTwoLevelsUp(path);
      if (path !== oldPath) {
        setItemsByPath((prevItemsByPath) => {
          const newItemsByPath = { ...(prevItemsByPath || {}) };
          delete newItemsByPath[oldPath];
          return newItemsByPath;
        });
      }
    },
    [refreshItemsTwoLevelsUp]
  );

  const onAfterDelete = useCallback(
    (path: string) => {
      refreshItemsTwoLevelsUp(path);
      setOpenPaths((openPaths) => {
        return openPaths.filter((openPath) => openPath !== path);
      });
      setItemsByPath((prevItemsByPath) => {
        const newItemsByPath = { ...(prevItemsByPath || {}) };
        delete newItemsByPath[path];
        return newItemsByPath;
      });
    },
    [refreshItemsTwoLevelsUp]
  );

  const onTreeItemToggle = useCallback(
    (item: LibraryTreeItem) => {
      const newOpenPaths = item.isOpen
        ? openPaths.filter((path) => !path.startsWith(item.path))
        : [...openPaths, item.path];
      setOpenPaths(newOpenPaths);
    },
    [openPaths]
  );

  useEffect(() => {
    const newOpenPaths = selectedPath ? helpers.getPathParts(selectedPath) : [];
    setOpenPaths(newOpenPaths);
  }, [selectedPath]);

  useEffect(() => {
    const unloadedOpenPaths = openPaths.filter((path) => !itemsByPath[path]);
    if (unloadedOpenPaths.length) {
      fetchItems(unloadedOpenPaths);
    }
  }, [fetchItems, itemsByPath, openPaths]);

  const contextValue = useMemo(
    () => ({
      menuTree,
      selectedDepth,
      selectedPath,
      selectedRoot,
      isGroupLibrary,
      onAfterCreate,
      onAfterEdit,
      onAfterDelete,
      onTreeItemToggle,
    }),
    [
      menuTree,
      selectedDepth,
      selectedPath,
      selectedRoot,
      isGroupLibrary,
      onAfterCreate,
      onAfterEdit,
      onAfterDelete,
      onTreeItemToggle,
    ]
  );

  return (
    <LibraryMenuContext.Provider value={contextValue}>
      {children}
    </LibraryMenuContext.Provider>
  );
};

const useLibraryMenu = () => {
  const context = useContext(LibraryMenuContext);
  if (!context) {
    throw new Error('LibraryMenuContext is not defined');
  }
  return context;
};

const LibraryMenuConsumer = LibraryMenuContext.Consumer;
export { LibraryMenuConsumer, LibraryMenuProvider, useLibraryMenu };
