export * from './article';
export * from './routes';

export enum KeyboardKeyEnum {
  Escape = 'Escape',
  Enter = 'Enter',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
}

export enum RequestStatusEnum {
  Idle,
  Loading,
  Success,
  Failure,
}
