import './Terms.scss';

import { updateProfileSettings } from 'api/organization';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Flex from 'components/Flex';
import Modal from 'components/Modal';
import { Component } from 'react';

import { TERMS_GUID } from '..';

class TermsModal extends Component {
  state = {
    hasConfirmedTerms: false,
  };

  onSubmit = async () => {
    const { hasConfirmedTerms } = this.state;
    const { userId, updateUser } = this.props;
    if (hasConfirmedTerms) {
      try {
        await updateProfileSettings(userId, {
          consents: {
            data: [
              {
                type: 'consents',
                id: TERMS_GUID,
              },
            ],
          },
          profileUpdated: false,
        });
        updateUser(({ consents }) => ({
          consents: consents
            ? consents.concat({ id: TERMS_GUID })
            : [{ id: TERMS_GUID }],
          showSurvey: true,
        }));
      } catch (e) {
        return this.setState({
          error: true,
          errorMessage: e.message,
        });
      }
    }
  };
  render() {
    const { hasConfirmedTerms } = this.state;
    return (
      <Modal size="md" blur={true} focus={false} animation="slow">
        <Modal.Header />
        <Flex type="space-between" direction="column" className="p-t-8 center">
          <div className="terms">
            <h1>Oh, and one last thing...</h1>
            <p className="terms__preamble  m-b-6">
              Please take a moment to read through the terms of use so you can
              see that your integrity is important to us.
            </p>

            <div className="terms__text">
              <h2>Avenue - Terms of Use</h2>

              <p>
                <strong>Effective:</strong> January 15, 2019
              </p>

              <p>
                These are your rights and responsibilities when using Avenue.
                Please read them carefully before using Avenue so you see how
                your integrity and the Group's integrity are important.
              </p>

              <p>
                <strong>Avenue is the digital workplace for ASSA ABLOY</strong>
              </p>

              <p>
                Keep in mind that Avenue is a meeting place for people from
                different cultures, beliefs and lifestyles. Communicate with
                respect and consideration.
              </p>

              <p>
                Only share content that you have the necessary legal rights to
                share and that represents non-confidential business information.
                Content that does not meet the guidelines or that violates
                copyrights or trademarks may be removed.
              </p>

              <p>
                <strong>Content and copyright</strong>
              </p>

              <p>
                The content on this site is for internal ASSA ABLOY Group use
                only. You are authorized to view, download and print copies of
                the content, but only for internal business use. Unauthorized
                use of the content may violate secrecy, confidentiality and
                other regulations.
              </p>

              <p>
                <strong>Avenue editors</strong>
              </p>

              <p>
                If you have access to edit the content on Avenue, you agree to
                follow the guidelines for publishing content. Only publish
                non-confidential business information on Avenue.
              </p>

              <p>
                Before publishing content, make sure you understand and apply
                personal data protection rules both when writing and when
                publishing photos and videos.
              </p>

              <p>
                To become an editor, please use{' '}
                <a
                  href="https://assaabloy.service-now.com/ess?id=sc_cat_item&sys_id=1018946fdba56f0070e6e2a94b9619a0"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  this form
                </a>{' '}
                to apply through the Service Portal.
              </p>

              <p>
                <strong>Privacy</strong>
              </p>

              <p>
                The processing of your personal data on Avenue, where the ASSA
                ABLOY entity where you are employed or contracted is acting as
                controller, is described in the employee or consultant privacy
                notice provided by your relevant ASSA ABLOY Company. Such
                privacy notice also describes your rights in relation to the
                processing of your personal data.
              </p>

              <p>
                For global processes where ASSA ABLOY AB is controller your
                personal data will be processed in accordance with the{' '}
                <a
                  href="/public/privacy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Avenue Privacy Notice
                </a>
                . If you have questions regarding the processing of your
                personal data on Avenue please contact your divisional Data
                Protection Manager or send an email to{' '}
                <a href="mailto:gc.privacy@assaabloy.com">
                  gc.privacy@assaabloy.com
                </a>
                .
              </p>

              <p>
                As a consultant or other third party, you are bound by privacy
                and data protection clauses in your agreement with ASSA ABLOY.
                At the very minimum you have signed a non-disclosure agreement
                covering all information on Avenue and you must have valid legal
                ground when publishing personal data here.
              </p>

              <p>
                <strong>Details about cookies</strong>
              </p>

              <p>
                Cookies are small text files on your computer that are used to
                make Avenue work correctly and to facilitate your login
                procedure. See which cookies that Avenue is using{' '}
                <a
                  href="/public/about-cookies"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  here
                </a>
                .
              </p>

              <p>
                If you have questions related to cookies or privacy, please
                visit our Privacy Center or email{' '}
                <a href="mailto:cookies@assaabloy.com">cookies@assaabloy.com</a>
                .
              </p>

              <p>
                <strong>Service and support</strong>
              </p>

              <p>
                Contact{' '}
                <a
                  href="https://assaabloy.service-now.com/ess"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  ASSA ABLOY IT Support
                </a>{' '}
                with questions or problems with Avenue.
              </p>
            </div>
          </div>
          <Modal.Footer>
            <Flex type="flex-end" className="size-small">
              <label htmlFor="agreeCheckbox" className="m-r-3 text--left">
                I acknowledge the Terms of Use
              </label>{' '}
              <Checkbox
                color="gray"
                id="agreeCheckbox"
                onChange={() =>
                  this.setState(({ hasConfirmedTerms }) => ({
                    hasConfirmedTerms: !hasConfirmedTerms,
                  }))
                }
              />
            </Flex>
            <Button
              disabled={!hasConfirmedTerms}
              size="large"
              width="block"
              className="m-t-4 m-b-4"
              color={hasConfirmedTerms ? 'blue' : 'black'}
              onClick={this.onSubmit}
            >
              Go explore!
            </Button>
          </Modal.Footer>
        </Flex>
      </Modal>
    );
  }
}

export default TermsModal;
