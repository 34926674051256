import {
  Department,
  DepartmentsResponse,
  Division,
  DivisionsResponse,
  Profile,
  ProfileResponse,
} from './types';

export const GLOBAL_DIVISION_NAME = 'Global';
export const GLOBAL_DIVISION_ID = 'division-34';

export const ENTRANCE_SYSTEMS_DIVISION_ID = 'division-26';
export const GLOBAL_SOLUTIONS_DIVISION_ID = 'division-32';

const divisionsResponseToDivisions = (
  response: DivisionsResponse,
  excludeGlobal?: boolean
): Division[] => {
  const items = response.data.data;

  const globalItem = items.find(
    (item) => item.relationships.parentDivision.data === null
  );

  const divisions = items
    .filter((item) => {
      const parent = item.relationships.parentDivision.data;
      return parent && parent.id === globalItem?.id;
    })
    .map((item) => {
      const division: Division = {
        id: item.id,
        name: item.attributes.name,
      };
      return division;
    })
    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

  if (globalItem && !excludeGlobal) {
    const globalDivision: Division = {
      id: globalItem.id,
      name: globalItem.attributes.name,
    };
    return [globalDivision, ...divisions];
  }
  return divisions;
};

const departmentsResponseToDepartments = (
  response: DepartmentsResponse
): Department[] => {
  const items = response.data.data;
  return items.map((item) => ({ id: item.id, name: item.attributes.name }));
};

const profileResponseToProfile = (response: ProfileResponse): Profile => {
  return {
    id: response.data.data.id,
    ...response.data.data.attributes,
  };
};

export {
  departmentsResponseToDepartments,
  divisionsResponseToDivisions,
  profileResponseToProfile,
};
