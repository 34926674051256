import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { FeedbackRankingValue } from 'api/feedback/transformers';
import React, { useMemo } from 'react';
import { translations } from 'translations';

import { ReactComponent as EmojiAngryIcon } from './icons/EmojiAngry.svg';
import { ReactComponent as EmojiHappyIcon } from './icons/EmojiHappy.svg';
import { ReactComponent as EmojiNeutralIcon } from './icons/EmojiNeutral.svg';

type FeedbackRankingButtonProps = {
  value: FeedbackRankingValue;
  label?: string;
  active?: boolean;
  onClick: (value: FeedbackRankingValue) => void;
};

const FeedbackRankingButton = ({
  value,
  label,
  active,
  onClick,
}: FeedbackRankingButtonProps) => {
  const theme = useTheme();

  const uiSettings: {
    ariaLabel: string;
    color: string;
    icon: React.ReactElement;
  } | null = useMemo(() => {
    switch (value) {
      case FeedbackRankingValue.NotHelpful:
        return {
          ariaLabel: translations.articleFeedbackRating0,
          color: theme.colors.border.surfaceCritical,
          icon: <EmojiAngryIcon />,
        };
      case FeedbackRankingValue.Neutral:
        return {
          ariaLabel: translations.articleFeedbackRating1,
          color: theme.colors.border.surfaceWarning,
          icon: <EmojiNeutralIcon />,
        };
      case FeedbackRankingValue.Helpful:
        return {
          ariaLabel: translations.articleFeedbackRating2,
          color: theme.colors.border.surfaceSuccess,
          icon: <EmojiHappyIcon />,
        };
      default:
        return null;
    }
  }, [theme, value]);

  if (!uiSettings) {
    return null;
  }

  return (
    <Stack alignItems="center">
      <IconButton
        size="large"
        aria-label={uiSettings.ariaLabel}
        onClick={() => onClick(value)}
        sx={{
          padding: 0,
          '&:hover': {
            'path:first-of-type': {
              fill: uiSettings.color,
            },
          },
          ...(active && {
            'path:first-of-type': {
              fill: uiSettings.color,
            },
          }),
        }}
      >
        {uiSettings.icon}
      </IconButton>
      <Typography
        variant="caption"
        sx={(theme) => ({
          color: theme.colors.text.tertiary,
          marginTop: theme.spacing('xxxs'),
        })}
      >
        {label}
      </Typography>
    </Stack>
  );
};
export { FeedbackRankingButton };
