import { PopperProps } from '@mui/material/Popper';
import { TextFieldProps } from '@mui/material/TextField';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { useMemo, useState } from 'react';

import {
  transformLocalDayToUTCDate,
  transformUTCDayToLocalDate,
} from './helpers';

export type DatePickerProps = {
  id?: string;
  size?: TextFieldProps['size'];
  error?: TextFieldProps['error'];
  fullWidth?: TextFieldProps['fullWidth'];
  placeholder?: TextFieldProps['placeholder'];
  inputProps?: TextFieldProps['inputProps'];
  placement?: PopperProps['placement'];
  time?: 'startOfDay' | 'endOfDay';
} & Omit<MuiDatePickerProps<Date>, 'slots' | 'slotProps'>;

const DatePicker = ({
  id,
  size = 'small',
  error = false,
  fullWidth = false,
  inputProps = {},
  placement = 'bottom-start',
  placeholder,
  time = 'startOfDay',
  value = null,
  disabled = false,
  onChange,
  ...rest
}: DatePickerProps) => {
  const [open, setOpen] = useState<boolean>(false);

  // "popperProps" needs to be defined here as an object because of this issue
  // https://github.com/mui/mui-x/issues/8432
  const popperProps = useMemo(
    () => ({
      placement: placement,
      modifiers: [
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ],
    }),
    [placement]
  );

  return (
    <MuiDatePicker
      open={open}
      value={value ? transformUTCDayToLocalDate(value, time) : null}
      disabled={disabled}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(value, context) => {
        onChange &&
          onChange(
            value ? transformLocalDayToUTCDate(value, time) : null,
            context
          );
      }}
      {...rest}
      slots={{ toolbar: undefined }}
      slotProps={{
        popper: popperProps,
        textField: {
          size: size,
          error: error,
          fullWidth: fullWidth,
          ...(placeholder && { placeholder }),
          inputProps: {
            id: id,
            readOnly: true,
            sx: { cursor: disabled ? 'default' : 'pointer' },
            ...inputProps,
            onClick: (e) => {
              setOpen(!open);
              if (inputProps.onClick) {
                inputProps.onClick(e);
              }
            },
          },
        },
      }}
    />
  );
};

export { DatePicker };
