import './LanguageSelector.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import classNames from 'classnames';
import Button from 'components/Button';
import { Icon } from 'componentsNew';
import { useEffect, useMemo, useState } from 'react';
import {
  GAonClickLanguageDropdownMenu,
  GAonClickLanguageItem,
} from 'utils/analytics';

import LanguageSelectItem from './LanguageSelectItem';

export interface Language {
  locale: string;
  text: string;
  isAvailable?: boolean;
}

export interface Translation {
  locale: string;
  id: string;
}

const supportedLanguages: Language[] = [
  { locale: 'en', text: 'Original', isAvailable: true },
  { locale: 'zh', text: 'Simplified Chinese', isAvailable: true },
  { locale: 'es', text: 'Spanish', isAvailable: true },
  { locale: 'fr', text: 'French', isAvailable: true },
  { locale: 'nb', text: 'Norwegian', isAvailable: true },
  { locale: 'it', text: 'Italian', isAvailable: true },
  { locale: 'nl', text: 'Dutch', isAvailable: true },
  { locale: 'de', text: 'German', isAvailable: true },
  { locale: 'sv', text: 'Swedish', isAvailable: true },
  { locale: 'ko', text: 'Korean', isAvailable: true },
  { locale: 'pt', text: 'Portuguese', isAvailable: true },
  { locale: 'cs', text: 'Czech', isAvailable: true },
  { locale: 'ja', text: 'Japanese', isAvailable: true },
  { locale: 'pl', text: 'Polish', isAvailable: true },
  { locale: 'fi', text: 'Finnish', isAvailable: true },
  { locale: 'da', text: 'Danish', isAvailable: true },
  { locale: 'en_GB', text: 'English (International)', isAvailable: true },
];

interface LanguageSelectorProps {
  id?: string;
  className?: string;
  disabled?: boolean;
  color?: string;
  label?: string;
  currentLocale?: string;
  availableTranslations?: Translation[];
  onLanguageChange: (language: Language) => boolean;
}

const LanguageSelector = ({
  id,
  className,
  disabled = false,
  color = 'black',
  label = 'Translate',
  currentLocale = 'en',
  availableTranslations,
  onLanguageChange,
}: LanguageSelectorProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    supportedLanguages[0]
  );

  useEffect(() => {
    const selectedLanguage = supportedLanguages.find(
      (language) => language.locale === currentLocale
    );
    if (selectedLanguage) {
      setSelectedLanguage(selectedLanguage);
    }
  }, [currentLocale]);

  useEffect(() => {
    if (!availableTranslations?.length) {
      setLanguages(supportedLanguages);
      return;
    }

    const languages = supportedLanguages.map((supportedLanguage) => {
      const isAvailable = availableTranslations.some(
        (translation) => translation.locale === supportedLanguage.locale
      );
      const language: Language = {
        ...supportedLanguage,
        isAvailable,
      };
      return language;
    });
    setLanguages(languages);
  }, [availableTranslations]);

  const languageSelectorClassNames = useMemo(() => {
    return {
      select: classNames(className, 'language-select', {
        'language-select--disabled': disabled,
        'language-select--open': open,
      }),
      options: classNames('language-select__options', {
        'language-select__options--original':
          selectedLanguage && selectedLanguage.locale === 'en',
      }),
      button: classNames('language-select__label'),
      icon: classNames('language-select__icon-wrapper', {
        [`icon--${color}`]: color,
      }),
    };
  }, [className, color, disabled, open, selectedLanguage]);

  const handleChange = (language: Language) => {
    if (selectedLanguage?.locale !== language.locale) {
      GAonClickLanguageItem(language.text);
      const isSuccess = onLanguageChange(language);
      if (!isSuccess) return;
      setSelectedLanguage(language);
      setOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div id={id} className={languageSelectorClassNames.select}>
        <div className="language-select__inner">
          <Button
            size="small"
            color={color === 'white' ? 'transparent-white' : 'transparent'}
            className={languageSelectorClassNames.button}
            onClick={() => setOpen(!open)}
          >
            {label}
            {selectedLanguage && selectedLanguage.locale !== 'en'
              ? `: ${selectedLanguage.text}`
              : ''}
            <span
              className={languageSelectorClassNames.icon}
              onClick={GAonClickLanguageDropdownMenu}
            >
              {open ? (
                <Icon type="chevronUp" sx={{ marginRight: '0.25rem' }} />
              ) : (
                <Icon type="chevronDown" sx={{ marginRight: '0.25rem' }} />
              )}
            </span>
          </Button>
          {open && !disabled && (
            <div className={languageSelectorClassNames.options}>
              <form>
                <header className="language-select__header">
                  Original language
                </header>
                <ul className="language-select__list">
                  <LanguageSelectItem
                    item={languages[0]}
                    selectedLanguage={selectedLanguage}
                    onChange={handleChange}
                  />
                </ul>
                <hr />
                <header className="language-select__header">
                  Auto translate page?
                </header>
                <ul className="language-select__list">
                  {languages.map(
                    (item, index) =>
                      index > 0 && (
                        <LanguageSelectItem
                          key={item.locale}
                          item={item}
                          selectedLanguage={selectedLanguage}
                          onChange={handleChange}
                        />
                      )
                  )}
                </ul>
              </form>
            </div>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default LanguageSelector;
