export enum ArticleContentTypeEnum {
  Story = 'StoryArticle',
  News = 'NewsArticle',
  Blog = 'BlogPost',
  Press = 'PressRelease',
}

export enum PortalArticleContentTypeEnum {
  PortalSearchHit = 'portalarticlesearchhits',
}
