import './SuggestionsList.scss';

import Suggestion from 'api/models/Suggestion';
import { History } from 'history';
import SuggestionItem from 'pages/Portals/Search/Suggestions/SuggestionItem/SuggestionItem';

interface InnovationSearchSuggestionsListProps {
  suggestions: Suggestion[];
  searchQuery: string;
  justSelectedSuggestionIndex: number | null;
  history: History;
  hoverSearchResult: (index: number) => void;
}

const SuggestionsList = ({
  suggestions,
  searchQuery,
  justSelectedSuggestionIndex,
  history,
  hoverSearchResult,
}: InnovationSearchSuggestionsListProps) => {
  return (
    <ul className="innovation-search-bar__suggestion-list">
      {Boolean(suggestions.length) &&
        suggestions.map((suggestion, index) => (
          <SuggestionItem
            key={index}
            suggestion={suggestion}
            searchQuery={searchQuery}
            justSelectedSuggestionIndex={justSelectedSuggestionIndex}
            index={index}
            history={history}
            hoverSearchResult={hoverSearchResult}
          />
        ))}
    </ul>
  );
};

export default SuggestionsList;
