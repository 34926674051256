import { ArticleSortOption } from 'pages/Portals/Search/Result/SearchResults';

const sortOptions: ArticleSortOption[] = [
  {
    label: 'Relevance',
    sortType: '_score',
    sortOrder: 'desc',
  },
  {
    label: 'A-Z',
    sortType: 'sortingName',
    sortOrder: 'asc',
  },
  {
    label: 'Z-A',
    sortType: 'sortingName',
    sortOrder: 'desc',
  },
  {
    label: 'Newest',
    sortType: 'published',
    sortOrder: 'desc',
  },
  {
    label: 'Oldest',
    sortType: 'published',
    sortOrder: 'asc',
  },
];

export default sortOptions;
