import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  JobFilterName,
  JobFilterOption,
  JobFilterOptionState,
  JobListingFilter,
} from 'api/jobs/types';
import { Chip, Icon, Select } from 'componentsNew';
import { SelectItem } from 'componentsNew/Select/Select';
import { useSearchParams } from 'hooks/useSearchParams';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { translations } from 'translations';
import {
  GAonAddJobFilterClick,
  GAonResetJobFilterClick,
} from 'utils/analytics';

import { JobListTotal } from '../JobList/JobListTotal';

const elementId = 'job-filter';

export const ALL_FILTER_NAMES = Object.values(JobFilterName) as JobFilterName[];

export type FilterParam = Partial<Record<JobFilterName, number[]>>;

type FilterChip = {
  id: number;
  name: string;
  filterName: JobFilterName;
};

type JobFilterProps = {
  jobListTotal: number;
  availableFilter: JobListingFilter;
  onChange: (filter: FilterParam) => void;
};

const JobFilter = ({
  jobListTotal,
  availableFilter,
  onChange,
}: JobFilterProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [chips, setChips] = useState<FilterChip[] | null>(null);

  const menuRef = useRef<HTMLDivElement | null>(null);
  const menuButtonRef = useRef<HTMLButtonElement | null>(null);
  const urlSearchParams = useSearchParams();

  const filterParam = useMemo(() => {
    const value = urlSearchParams.get('filter');
    return value ? (JSON.parse(value) as FilterParam) : null;
  }, [urlSearchParams]);

  const selectedFilter = useMemo(() => {
    const selected: Partial<JobListingFilter> = {};
    ALL_FILTER_NAMES.forEach((name) => {
      const ids = filterParam ? filterParam[name] : [];
      const options = (ids || [])
        .map((id) => availableFilter[name].find((option) => option.id === id))
        .filter(Boolean);

      if (name === JobFilterName.States) {
        selected[name] = options as JobFilterOptionState[];
      } else {
        selected[name] = options as JobFilterOption[];
      }
    });
    return selected as JobListingFilter;
  }, [availableFilter, filterParam]);

  const locationSelectItems = useMemo(() => {
    const items: SelectItem[] = availableFilter.locations.map((option) => ({
      name: option.name,
      value: `${option.id}`,
    }));
    return items;
  }, [availableFilter.locations]);

  const stateSelectItems = useMemo(() => {
    const selectedLocationIds = filterParam?.locations || [];
    const items: SelectItem[] = availableFilter.states
      .filter((option) =>
        selectedLocationIds.some((id) => id === option.locationId)
      )
      .map((option) => ({
        name: option.name,
        value: `${option.id}`,
      }));
    return items;
  }, [availableFilter.states, filterParam?.locations]);

  const categorySelectItems = useMemo(() => {
    const items: SelectItem[] = availableFilter.categories.map((option) => ({
      name: option.name,
      value: `${option.id}`,
    }));
    return items;
  }, [availableFilter.categories]);

  const seniorityLevelSelectItems = useMemo(() => {
    const items: SelectItem[] = availableFilter.seniorityLevels.map(
      (option) => ({
        name: option.name,
        value: `${option.id}`,
      })
    );
    return items;
  }, [availableFilter.seniorityLevels]);

  const updateUrlSearchParamsFilter = useCallback(
    (filter: JobListingFilter) => {
      const newFilterParam: FilterParam = {};
      ALL_FILTER_NAMES.forEach((name) => {
        const ids = filter[name].map((option) => option.id);
        if (ids.length) {
          newFilterParam[name] = ids;
        }
      });
      const isFilterEmpty = !Object.keys(newFilterParam).length;
      if (isFilterEmpty) {
        urlSearchParams.remove('filter');
      } else {
        urlSearchParams.addOrUpdate('filter', JSON.stringify(newFilterParam));
      }
      return newFilterParam;
    },
    [urlSearchParams]
  );

  const addToSelectedFilter = useCallback(
    (name: JobFilterName, option: JobFilterOption | JobFilterOptionState) => {
      const newOptions = [...selectedFilter[name], option];
      const newFilter = { ...selectedFilter, [name]: newOptions };
      const newChips = [
        ...(chips || []),
        { id: option.id, name: option.name, filterName: name },
      ];
      const newFilterParam = updateUrlSearchParamsFilter(newFilter);
      onChange(newFilterParam);
      setChips(newChips);

      GAonAddJobFilterClick(
        `${name.toUpperCase()}: ${newOptions
          .map((option) => option.name)
          .join(', ')}`
      );
    },
    [chips, onChange, selectedFilter, updateUrlSearchParamsFilter]
  );

  const removeFromSelectedFilter = useCallback(
    (name: JobFilterName, option: JobFilterOption | JobFilterOptionState) => {
      const newOptions = selectedFilter[name].filter((o) => o.id !== option.id);
      const newFilter = { ...selectedFilter, [name]: newOptions };
      let newChips = (chips || []).filter((chip) => {
        const shouldRemove = chip.filterName === name && chip.id === option.id;
        return !shouldRemove;
      });
      if (
        name === JobFilterName.Locations &&
        selectedFilter[JobFilterName.States].length
      ) {
        const newStates = selectedFilter[JobFilterName.States].filter(
          (state) => state.locationId !== option.id
        );
        newFilter[JobFilterName.States] = newStates;
        newChips = newChips.filter((chip) => {
          const shouldRemove =
            chip.filterName === JobFilterName.States &&
            !newStates.some((state) => state.id === chip.id);
          return !shouldRemove;
        });
      }
      const newFilterParam = updateUrlSearchParamsFilter(newFilter);
      onChange(newFilterParam);
      setChips(newChips);
    },
    [chips, onChange, selectedFilter, updateUrlSearchParamsFilter]
  );

  const handleSelectChange = useCallback(
    (name: JobFilterName, options: { id: number; name: string }[]) => {
      const prevOptions = selectedFilter[name];

      if (options.length > prevOptions.length) {
        const addedOption = options.find(
          (option) =>
            !prevOptions.some((prevOption) => prevOption.id === option.id)
        );
        if (addedOption) {
          addToSelectedFilter(name, addedOption);
        }
        return;
      }
      const removedOption = prevOptions.find(
        (prevOption) => !options.some((option) => option.id === prevOption.id)
      );
      if (removedOption) {
        removeFromSelectedFilter(name, removedOption);
      }
    },
    [addToSelectedFilter, removeFromSelectedFilter, selectedFilter]
  );

  const handleChipDelete = useCallback(
    (chip: FilterChip) => {
      const removedOption = selectedFilter[chip.filterName].find(
        (option) => option.id === chip.id
      );
      if (removedOption) {
        removeFromSelectedFilter(chip.filterName, removedOption);
      }
    },
    [removeFromSelectedFilter, selectedFilter]
  );

  const handleClearFilter = useCallback(() => {
    const newFilter: Partial<JobListingFilter> = {};
    ALL_FILTER_NAMES.forEach((name) => {
      newFilter[name] = [];
    });
    const newFilterParam = updateUrlSearchParamsFilter(
      newFilter as JobListingFilter
    );
    onChange(newFilterParam);
    setChips([]);
    GAonResetJobFilterClick();
  }, [onChange, updateUrlSearchParamsFilter]);

  // Add initial chips
  useEffect(() => {
    if (chips) {
      return;
    }
    const isSelectedFilterEmpty =
      ALL_FILTER_NAMES.map((name) => selectedFilter[name]).flat().length === 0;

    if (isSelectedFilterEmpty) {
      return;
    }
    const newChips: FilterChip[] = ALL_FILTER_NAMES.map((name) =>
      selectedFilter[name].map((option) => ({
        id: option.id,
        name: option.name,
        filterName: name,
      }))
    ).flat();

    setChips(newChips);
  }, [chips, selectedFilter]);

  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        flexDirection: { xs: 'column', md: 'row' },
        gap: theme.spacing('xs'),
      })}
    >
      <Stack id={elementId} sx={{ position: 'relative' }}>
        <Button
          variant="text"
          id={`${elementId}-expand`}
          ref={menuButtonRef}
          startIcon={<Icon type="adjustmentsHorizontal" color="brandBase" />}
          onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
          sx={(theme) => ({
            alignSelf: 'flex-start',
            ...(isOpen && {
              '&.MuiButton-text': {
                color: theme.colors.text.actionHover,
                backgroundColor: theme.colors.surface.actionTertiaryHover,
                border: `1px solid ${theme.colors.border.brandBase}`,
              },
            }),
          })}
        >
          {translations.jobsFilter}
        </Button>
        {isOpen && (
          <ClickAwayListener
            onClickAway={(e) => {
              if (
                e.target === menuRef.current ||
                e.target === menuButtonRef.current
              ) {
                return;
              }
              setIsOpen(false);
            }}
          >
            <Stack
              id={`${elementId}-menu`}
              ref={menuRef}
              sx={(theme) => ({
                position: 'absolute',
                top: '3.25rem',
                backgroundColor: theme.colors.surface.primary,
                borderRadius: theme.border.radius.md,
                boxShadow: theme.elevation.sm,
                padding: theme.spacing('sm'),
                zIndex: theme.zIndex.tooltip,
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing('xs'),
                width: '20rem',
              })}
            >
              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography variant="h4" component="h2">
                  {translations.jobsFilterTitle}
                </Typography>
                <IconButton
                  size="small"
                  aria-label={translations.close}
                  onClick={() => setIsOpen(false)}
                >
                  <Icon type="xMark" color="secondary" />
                </IconButton>
              </Stack>
              <Select
                multiple
                closeOnSelect
                id={`${JobFilterName.Locations}-select`}
                placeholder={translations.jobsFilterLocation}
                sx={(theme) => ({
                  backgroundColor: theme.colors.surface.secondary,
                  '.MuiPaper-root': { maxHeight: '30rem' },
                })}
                items={locationSelectItems}
                value={(selectedFilter?.locations || []).map((option) => ({
                  name: option.name,
                  value: `${option.id}`,
                }))}
                onChange={(items) =>
                  handleSelectChange(
                    JobFilterName.Locations,
                    items.map((item) => ({
                      id: Number(item.value),
                      name: item.name,
                    }))
                  )
                }
              />
              <Select
                multiple
                closeOnSelect
                id={`${JobFilterName.States}-select`}
                placeholder={translations.jobsFilterState}
                disabled={!stateSelectItems.length}
                sx={(theme) => ({
                  backgroundColor: theme.colors.surface.secondary,
                  '.MuiPaper-root': { maxHeight: '30rem' },
                })}
                items={stateSelectItems}
                value={(selectedFilter?.states || []).map((option) => ({
                  name: option.name,
                  value: `${option.id}`,
                }))}
                onChange={(items) =>
                  handleSelectChange(
                    JobFilterName.States,
                    items.map((item) => ({
                      id: Number(item.value),
                      name: item.name,
                    }))
                  )
                }
              />
              <Select
                multiple
                closeOnSelect
                id={`${JobFilterName.Categories}-select`}
                placeholder={translations.jobsFilterCategory}
                sx={(theme) => ({
                  backgroundColor: theme.colors.surface.secondary,
                  '.MuiPaper-root': { maxHeight: '30rem' },
                })}
                items={categorySelectItems}
                value={(selectedFilter?.categories || []).map((option) => ({
                  name: option.name,
                  value: `${option.id}`,
                }))}
                onChange={(items) =>
                  handleSelectChange(
                    JobFilterName.Categories,
                    items.map((item) => ({
                      id: Number(item.value),
                      name: item.name,
                    }))
                  )
                }
              />
              <Select
                multiple
                closeOnSelect
                id={`${JobFilterName.SeniorityLevels}-select`}
                placeholder={translations.jobsFilterSeniorityLevel}
                sx={(theme) => ({
                  backgroundColor: theme.colors.surface.secondary,
                  '.MuiPaper-root': { maxHeight: '30rem' },
                })}
                items={seniorityLevelSelectItems}
                value={(selectedFilter?.seniorityLevels || []).map(
                  (option) => ({
                    name: option.name,
                    value: `${option.id}`,
                  })
                )}
                onChange={(items) =>
                  handleSelectChange(
                    JobFilterName.SeniorityLevels,
                    items.map((item) => ({
                      id: Number(item.value),
                      name: item.name,
                    }))
                  )
                }
              />
              <Divider sx={{ margin: '0.25rem 0 0.125rem 0' }} />
              <JobListTotal
                total={jobListTotal}
                sx={{ alignSelf: 'flex-end' }}
              />
            </Stack>
          </ClickAwayListener>
        )}
      </Stack>
      {chips && chips.length > 0 && (
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            columnGap: theme.spacing('xs'),
            rowGap: theme.spacing('xxs'),
            padding: {
              xs: 0,
              md: `0 ${theme.spacing('xs')}`,
            },
            borderLeft: {
              xs: 'none',
              md: `1px solid ${theme.colors.border.surfacePrimary}`,
            },
            flexWrap: 'wrap',
          })}
        >
          {chips.map((chip, index) => (
            <Chip
              id={`${elementId}-chip-${index}`}
              key={`${elementId}-chip-${index}`}
              size="small"
              color="primary"
              variant="outlined"
              label={chip.name}
              sx={{ alignSelf: 'center' }}
              onDelete={() => handleChipDelete(chip)}
            />
          ))}
          <Link
            id={`${elementId}-clear`}
            variant="caption"
            onClick={handleClearFilter}
            sx={(theme) => ({
              alignSelf: 'center',
              marginLeft: theme.spacing('xs'),
            })}
          >
            {translations.jobsFilterClear}
          </Link>
        </Stack>
      )}
    </Stack>
  );
};

export { JobFilter };
