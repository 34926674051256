import { PortalArticleContentTypeEnum } from 'enums';

export type PortalSearchResponse = {
  data: {
    data: Array<{
      id: string;
      type: string;
      attributes: {
        title: string;
        summary?: string;
        imageUrl?: string;
        imageAltText?: string;
        linkUrl?: string;
        portalType: string;
        score?: string;
        published?: string;
      };
      relationships?: {
        types?: {
          data: {
            id: string;
            type: string;
          }[];
        };
      };
    }>;
    included?: Array<{
      id: string;
      type: string;
      attributes: { title: string };
    }>;
  };
};

export type PortalSearchArticle = {
  id: string;
  type: PortalArticleContentTypeEnum;
  title: string;
  summary?: string;
  imageUrl?: string;
  imageAltText?: string;
  linkUrl?: string;
  published?: string;
  categories: {
    id: string;
    title: string;
  }[];
};

const portalSearchResponseToPortalSearchArticles = (
  response: PortalSearchResponse
) => {
  const includedRelationshipData = response.data.included;

  const articles = response.data.data
    .map((item) => {
      if (!item.attributes.published) {
        return null;
      }
      const article: PortalSearchArticle = {
        id: item.id,
        type: item.type as PortalArticleContentTypeEnum,
        title: item.attributes.title,
        summary: item.attributes.summary,
        imageUrl: item.attributes.imageUrl,
        imageAltText: item.attributes.imageAltText,
        linkUrl: item.attributes.linkUrl,
        published: item.attributes.published,
        categories: [],
      };

      const categoryRelationships = item.relationships?.types?.data.filter(
        (relationshipType) => relationshipType.type === 'categoryvalue'
      );
      if (!categoryRelationships) {
        return article;
      }
      categoryRelationships.forEach((categoryRelationship) => {
        const categoryId = categoryRelationship.id;
        const categoryTitle = includedRelationshipData?.find(
          (includedData) => includedData.id === categoryId
        )?.attributes.title;
        if (categoryTitle) {
          article.categories.push({ id: categoryId, title: categoryTitle });
        }
      });
      return article;
    })
    .filter(Boolean) as PortalSearchArticle[];
  return articles;
};

export { portalSearchResponseToPortalSearchArticles };
