import { SearchField } from 'componentsNew';
import { useSearchParams } from 'hooks';
import { useCallback, useMemo, useState } from 'react';
import { translations } from 'translations';

const elementId = 'job-search';

type JobSearchProps = {
  onSubmit: (value: string) => void;
};

const JobSearch = ({ onSubmit }: JobSearchProps) => {
  const [input, setInput] = useState<string | null>(null);

  const urlSearchParams = useSearchParams();

  const value = useMemo(() => {
    if (input !== null) {
      return input;
    }
    const searchParam = urlSearchParams.get('search');
    return searchParam ? JSON.parse(searchParam) : '';
  }, [input, urlSearchParams]);

  const handleSubmit = useCallback(
    (value: string) => {
      if (value) {
        urlSearchParams.addOrUpdate('search', JSON.stringify(value));
      } else {
        urlSearchParams.remove('search');
      }
      onSubmit(value);
    },
    [onSubmit, urlSearchParams]
  );

  const handleClear = useCallback(() => {
    if (urlSearchParams.get('search')) {
      urlSearchParams.remove('search');
      onSubmit('');
    }
    setInput('');
  }, [onSubmit, urlSearchParams]);

  return (
    <SearchField
      id={elementId}
      value={value}
      placeholder={translations.jobsSearchPlaceholder}
      sx={{ width: { xs: '100%', md: '20rem' } }}
      onChange={setInput}
      onSubmit={handleSubmit}
      onClear={handleClear}
    />
  );
};

export { JobSearch };
