import { Division } from 'api/organization/types';
import Select from 'components/Select';
import { useCallback, useMemo, useState } from 'react';

export const GLOBAL_DIVISION_NAME = 'Global (all employees)';
export const GLOBAL_DIVISION_ID = 'division-34';

export interface SelectOption {
  name: string;
  value: string;
  checked: boolean;
  disabled: boolean;
}

interface DivisionSelectProps {
  title?: string;
  divisions: Division[];
  placeholder?: string;
  multiSelect?: boolean;
  showError?: boolean;
  deSelectEnabled?: boolean;
  defaultSelected?: { divisions: Division[]; global: boolean };
  onChange: (selected: { divisions: Division[]; global: boolean }) => void;
}

const DivisionSelect = ({
  title,
  divisions,
  placeholder,
  multiSelect,
  showError,
  deSelectEnabled,
  defaultSelected,
  onChange,
}: DivisionSelectProps) => {
  const [isGlobalSelected, setIsGlobalSelected] = useState<boolean | null>(
    null
  );
  const [selectedDivisions, setSelectedDivisions] = useState<Division[] | null>(
    null
  );

  const isDefaultSelected = useCallback(
    (divisionId: string) => {
      const isGlobalDefaultSelected = defaultSelected?.global || false;
      const defaultSelectedDivisions = defaultSelected?.divisions || [];
      return divisionId === GLOBAL_DIVISION_ID
        ? isGlobalDefaultSelected
        : defaultSelectedDivisions.some(
            (selectedDivision) => selectedDivision.id === divisionId
          );
    },
    [defaultSelected]
  );

  const allOptions = useMemo(() => {
    const isGlobalChecked =
      isGlobalSelected !== null
        ? isGlobalSelected
        : isDefaultSelected(GLOBAL_DIVISION_ID);

    const isOtherChecked =
      selectedDivisions !== null
        ? selectedDivisions.length > 0
        : (defaultSelected?.divisions || []).length > 0;

    return divisions.map((division) => {
      const option: SelectOption =
        division.id === GLOBAL_DIVISION_ID
          ? {
              name: GLOBAL_DIVISION_NAME,
              value: division.id,
              checked: isGlobalChecked,
              disabled: isOtherChecked,
            }
          : {
              name: division.name,
              value: division.id,
              checked: selectedDivisions
                ? selectedDivisions.some(
                    (selectedDivision) => selectedDivision.id === division.id
                  )
                : isDefaultSelected(division.id),
              disabled: isGlobalChecked,
            };
      return option;
    });
  }, [
    divisions,
    selectedDivisions,
    defaultSelected,
    isGlobalSelected,
    isDefaultSelected,
  ]);

  const selectedOptions = useMemo(() => {
    return allOptions.filter((option) => option.checked);
  }, [allOptions]);

  return (
    <div className="m-b-4">
      {title && <p>{title}</p>}
      <Select
        data={allOptions}
        placeholder={placeholder}
        showError={showError}
        selected={multiSelect ? selectedOptions : selectedOptions[0] || {}}
        multiple={multiSelect}
        deSelectEnabled={deSelectEnabled}
        onChange={(selected: SelectOption | SelectOption[] | null) => {
          const selectedArray = !selected
            ? []
            : Array.isArray(selected)
            ? selected
            : [selected];

          const isGlobalSelected = selectedArray.some(
            (selected) => selected.value === GLOBAL_DIVISION_ID
          );
          const selectedDivisions: Division[] = selectedArray
            .filter((selected) => selected.value !== GLOBAL_DIVISION_ID)
            .map((selected) => ({ id: selected.value, name: selected.name }));

          onChange({
            global: isGlobalSelected,
            divisions: selectedDivisions,
          });
          setIsGlobalSelected(isGlobalSelected);
          setSelectedDivisions(selectedDivisions);
        }}
      />
    </div>
  );
};

export default DivisionSelect;
