import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Icon, WhitelistValidationLink } from 'componentsNew';
import { translations } from 'translations';
import { GAonAISourceClick, GAonAISourceListClick } from 'utils/analytics';

type AIChatSourceListProps = {
  id: string;
  sources: { title: string; linkUrl: string }[];
};

const AIChatSourceList = ({ id, sources }: AIChatSourceListProps) => {
  if (!sources.length) {
    return null;
  }

  return (
    <Accordion
      id={id}
      sx={(theme) => ({
        boxShadow: 'unset',
        backgroundColor: theme.colors.surface.informative,
        border: `1px solid ${theme.colors.border.surfaceInformative}`,
        borderRadius: theme.border.radius.sm,
        padding: `${theme.spacing('xxxs')} ${theme.spacing('xxs')}`,
        marginBottom: theme.spacing('xs'),
      })}
    >
      <AccordionSummary
        id={`${id}-header`}
        aria-controls={`${id}-content`}
        expandIcon={<Icon type="chevronDown" color="brandBase" size={20} />}
        onClick={GAonAISourceListClick}
        sx={(theme) => ({
          padding: 0,
          typography: theme.typography.caption,
          fontWeight: theme.typography.fontWeightBold,
          color: theme.colors.text.actionDefault,
          minHeight: 'unset',
          '&.Mui-expanded': {
            minHeight: 'unset',
            margin: 0,
            marginBottom: theme.spacing('xxxxs'),
          },
          '.MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded':
            {
              margin: 0,
            },
        })}
      >
        {translations.aiChatSourceList}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <List>
          {sources.map((source, index) => (
            <ListItem
              key={`${index}-${source}`}
              divider={index !== sources.length - 1}
              sx={{ padding: 0 }}
            >
              <WhitelistValidationLink
                href={source.linkUrl}
                target="_blank"
                onClick={() => GAonAISourceClick(source.title)}
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  typography: theme.typography.caption,
                  color: theme.colors.text.tertiary,
                  padding: `${theme.spacing('xxxxs')} ${theme.spacing('xxxs')}`,
                  width: '100%',
                  ':hover svg': {
                    color: theme.colors.text.actionHover,
                  },
                })}
              >
                <Icon
                  type="link"
                  color="secondary"
                  sx={(theme) => ({ marginRight: theme.spacing('xxs') })}
                />
                {source.title}
              </WhitelistValidationLink>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export { AIChatSourceList };
