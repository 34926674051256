import Button from '@mui/material/Button';
import { ButtonWithMenu, Icon } from 'componentsNew';
import { DropdownMenuItem } from 'componentsNew/DropdownMenu';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';

const elementId = 'profile-create-button';

type CreateButtonProps = {
  items: DropdownMenuItem[];
};

const CreateButton = ({ items }: CreateButtonProps) => {
  if (!items.length) {
    return null;
  }

  if (items.length === 1 && items[0].to) {
    return (
      <Button
        id={elementId}
        variant="outlined"
        endIcon={<Icon type="plus" color="brandBase" />}
        component={ReactRouterLink}
        to={items[0].to}
        sx={(theme) => ({
          margin: `${theme.spacing('sm')} ${theme.spacing('md')}`,
          marginLeft: 'auto',
        })}
      >
        {translations.create}
      </Button>
    );
  }

  return (
    <ButtonWithMenu
      id={elementId}
      variant="outlined"
      endIcon={<Icon type="plus" color="brandBase" />}
      items={items}
      sx={(theme) => ({
        margin: `${theme.spacing('sm')} ${theme.spacing('md')}`,
        marginLeft: 'auto',
      })}
    >
      {translations.create}
    </ButtonWithMenu>
  );
};

export { CreateButton };
