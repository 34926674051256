import './Tree.scss';

import withConsumer from 'components/Context/withConsumer';
import TreeItem from 'components/Navigation/TreeItem';
import { LibraryMenuConsumer } from 'context';
import { Component } from 'react';
import React from 'react';

export class Tree extends Component {
  renderTree(items) {
    const { onTreeItemToggle } = this.props.libraryMenuContext;
    return (
      <ul className="tree__list tree__list--children">
        {items.map((item) => (
          <React.Fragment key={item.title}>
            <TreeItem
              depth={item.depth}
              isOpen={item.isOpen}
              isActive={item.isSelected}
              inActivePath={item.isInSelectedPath}
              hasChildren={item.isContainer}
              setOpen={() => onTreeItemToggle(item)}
              to={item.linkTo}
            >
              {item.title}
            </TreeItem>
            {item.isOpen && item.isContainer && !item.children.length && (
              <TreeItem depth={item.depth + 1}>Loading...</TreeItem>
            )}
            {item.isOpen &&
              item.children &&
              item.children.length > 0 &&
              this.renderTree(item.children)}
          </React.Fragment>
        ))}
      </ul>
    );
  }

  render() {
    const { menuTree } = this.props.libraryMenuContext;

    if (!menuTree) {
      return (
        <ul className="tree__list tree__list--children">
          <TreeItem depth={1}>Loading...</TreeItem>
        </ul>
      );
    }

    if (!menuTree.length) {
      return (
        <ul className="tree__list tree__list--children">
          <TreeItem isActive={true} inActivePath={true} depth={1}>
            No articles created yet...
          </TreeItem>
        </ul>
      );
    }

    return <div className="tree">{this.renderTree(menuTree)}</div>;
  }
}

export default withConsumer(LibraryMenuConsumer, Tree, 'libraryMenuContext');
