import { DivisionsTree } from 'api/organization/types';

const getValidCountriesForDivisions = (
  divisions: { id: string; name: string }[],
  divisionsTree: DivisionsTree
) => {
  const countryArrays = divisions
    .map((division) => {
      return divisionsTree.divisions.find(
        (treeDivision) => treeDivision.id === division.id
      )?.countries;
    })
    .filter((countryArray) => countryArray !== undefined);

  if (countryArrays.length === 0) {
    return [];
  }

  const validCountries = (countryArrays[0] || []).filter(
    (countryInFirstArray) =>
      countryArrays.every(
        (countryArray) =>
          countryArray &&
          countryArray.some((country) => country.id === countryInFirstArray.id)
      )
  );
  return validCountries;
};

export { getValidCountriesForDivisions };
