import Box from '@mui/material/Box';
import Parser from 'components/Parser';

type JobContentDescriptionProps = {
  description?: string;
};

const JobContentDescription = ({ description }: JobContentDescriptionProps) => {
  if (!description) {
    return null;
  }
  return (
    <Box
      sx={(theme) => ({
        wordBreak: 'break-word',
        a: {
          color: theme.colors.text.actionDefault,
          textDecorationColor: theme.colors.text.actionDefault,
          '&:hover': {
            color: theme.colors.text.actionHover,
            textDecorationColor: theme.colors.text.actionHover,
          },
        },
        p: {
          marginBotton: '1rem',
          lineHeight: '1.75rem',
        },
        ul: {
          padding: '0 3rem',
          li: {
            marginBottom: '0.5rem',
          },
        },
        ol: {
          padding: '0 3rem',
          li: {
            marginBottom: '0.5rem',
          },
        },
        dl: {
          dt: {
            marginBottom: '0.125rem',
            fontWeight: 'bold',
          },
          dd: {
            marginBottom: '1rem',
          },
        },
        table: {
          display: 'block',
          width: '100%',
          padding: '0.5rem 0',
          overflowX: 'auto',
        },
        td: {
          padding: '0.5rem',
          textAlign: 'left',
          verticalAlign: 'top',
          wordBreak: 'normal',
        },
        th: {
          padding: '0.5rem',
          paddingLeft: 0,
          textAlign: 'left',
          verticalAlign: 'top',
          wordBreak: 'normal',
          borderBottom: `1px solid ${theme.colors.border.surfaceInformative}`,
          p: {
            fontWeight: 'bold',
          },
        },
        strong: {
          display: 'inline',
        },
        blockquote: {
          padding: '0.5rem 0 0.5rem 1rem',
          margin: '0 0 0 1rem',
          borderLeft: `1px solid ${theme.colors.border.surfaceInformative}`,
          backgroundColor: theme.colors.surface.informative,
          color: theme.colors.text.tertiary,
        },
        'p:has(img)': {
          display: 'none',
        },
      })}
    >
      <Parser html={description} />
    </Box>
  );
};

export { JobContentDescription };
