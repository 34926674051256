import Button, { ButtonProps } from '@mui/material/Button';
import { DropdownMenu } from 'componentsNew';
import { useState } from 'react';

import { DropdownMenuItem } from './DropdownMenu';

type ButtonWithMenuProps = {
  items: DropdownMenuItem[];
} & ButtonProps;

const ButtonWithMenu = ({ items, ...buttonProps }: ButtonWithMenuProps) => {
  const [menuAnchorElement, setMenuAnchorElement] =
    useState<null | HTMLElement>(null);

  return (
    <>
      <Button
        {...buttonProps}
        onClick={(e) => {
          setMenuAnchorElement(e.currentTarget);
          buttonProps.onClick && buttonProps.onClick(e);
        }}
      >
        {buttonProps.children}
      </Button>
      <DropdownMenu
        id={`${buttonProps.id}-menu`}
        placement="top"
        open={Boolean(menuAnchorElement)}
        anchorEl={menuAnchorElement}
        onClose={() => setMenuAnchorElement(null)}
        items={items}
      />
    </>
  );
};
export { ButtonWithMenu };
