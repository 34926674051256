import Stack from '@mui/material/Stack';
import { DIVISION_DISPLAY_NAME_BY_ID } from 'api/news/transformers';
import {
  ENTRANCE_SYSTEMS_DIVISION_ID,
  GLOBAL_SOLUTIONS_DIVISION_ID,
} from 'api/organization/transformers';
import { FeedConsumer } from 'components/Context/Feed';
import { UserConsumer } from 'components/Context/User';
import Toggle from 'components/Toggle';
import { DatePicker } from 'componentsNew';
import { FormFieldWrapper } from 'layout';
import { getCleanSegmentName } from 'utils/misc/transformSegmentName';

const MIN_START_DATE = new Date('2000-01-01');

const FeedFilter = () => (
  <UserConsumer>
    {(userContext) => (
      <FeedConsumer>
        {({
          onFilterUpdated,
          active,
          divisionId,
          divisionName,
          segmentId,
          segmentName,
          countryId,
          countryName,
          departmentId,
          departmentName,
          siteId,
          siteName,
          companyId,
          companyName,
          startDate,
          endDate,
          onChangeStartDate,
          onChangeEndDate,
        }) => {
          const hideDivisionToggles =
            userContext.roles &&
            userContext.roles.includes('AvenueGlobalOnlyUser');

          const todayAsDate = new Date();

          const startDateAsDate = Boolean(startDate)
            ? new Date(startDate)
            : null;

          const endDateAsDate = Boolean(endDate) ? new Date(endDate) : null;

          return (
            <Stack sx={{ flexDirection: 'row', gap: 0.5 }}>
              <Stack
                sx={{
                  flexDirection: 'row',
                  alignContent: 'baseline',
                  flexWrap: 'wrap',
                  gap: 0.5,
                  paddingTop: '6px',
                  '> .toggle-button': { margin: 0 },
                }}
              >
                <Toggle
                  selected={active.includes('global')}
                  value={true}
                  type="global"
                  onClick={onFilterUpdated}
                >
                  Global
                </Toggle>
                {divisionId && !hideDivisionToggles && (
                  <>
                    <Toggle
                      type="division"
                      value={divisionId}
                      selected={active.includes('division')}
                      disabled={active.includes('press')}
                      onClick={onFilterUpdated}
                    >
                      {DIVISION_DISPLAY_NAME_BY_ID[divisionId] || divisionName}
                    </Toggle>
                    {(divisionId === ENTRANCE_SYSTEMS_DIVISION_ID ||
                      divisionId === GLOBAL_SOLUTIONS_DIVISION_ID) &&
                      Boolean(segmentId) && (
                        <Toggle
                          type="segment"
                          value={segmentId}
                          selected={active.includes('segment')}
                          disabled={active.includes('press')}
                          onClick={onFilterUpdated}
                        >
                          {getCleanSegmentName(segmentName)}
                        </Toggle>
                      )}
                    {countryId && (
                      <Toggle
                        type="country"
                        value={countryId}
                        selected={active.includes('country')}
                        disabled={active.includes('press')}
                        onClick={onFilterUpdated}
                      >
                        {countryName}
                      </Toggle>
                    )}
                  </>
                )}
                <Toggle
                  selected={active.includes('department')}
                  value={departmentId}
                  type="department"
                  disabled={active.includes('press')}
                  onClick={onFilterUpdated}
                >
                  {departmentName}
                </Toggle>
                <Toggle
                  selected={active.includes('site')}
                  value={siteId}
                  type="site"
                  disabled={active.includes('press')}
                  onClick={onFilterUpdated}
                >
                  {siteName}
                </Toggle>
                <Toggle
                  selected={active.includes('company')}
                  value={companyId}
                  type="company"
                  disabled={active.includes('press')}
                  onClick={onFilterUpdated}
                >
                  {companyName}
                </Toggle>
              </Stack>
              <Stack sx={{ flexDirection: 'row', gap: 0.5, maxWidth: '400px' }}>
                <FormFieldWrapper
                  id="feed-filter-start-date"
                  label="From"
                  labelSize="small"
                >
                  <DatePicker
                    value={startDateAsDate}
                    minDate={MIN_START_DATE}
                    maxDate={endDateAsDate || todayAsDate}
                    onChange={(value) => {
                      onChangeStartDate(value ? value.toString() : null);
                    }}
                  />
                </FormFieldWrapper>
                <FormFieldWrapper
                  id="feed-filter-end-date"
                  label="To"
                  labelSize="small"
                >
                  <DatePicker
                    value={endDateAsDate}
                    minDate={startDateAsDate}
                    maxDate={todayAsDate}
                    onChange={(value) => {
                      onChangeEndDate(value ? value.toString() : null);
                    }}
                  />
                </FormFieldWrapper>
              </Stack>
            </Stack>
          );
        }}
      </FeedConsumer>
    )}
  </UserConsumer>
);

export default FeedFilter;
