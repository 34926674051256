import './CommentItem.scss';

import Button from '@mui/material/Button';
import {
  AdditionalFeedbackReceiversProps,
  CommentDataTypeEnum,
} from 'api/feedback/types';
import Comment from 'api/models/Comment';
import Error from 'components/Error';
import { Icon } from 'componentsNew';
import { useCallback, useEffect, useState } from 'react';
import { translations } from 'translations';
import { GAonSubmitCommentOrReply } from 'utils/analytics';
import animateScroll from 'utils/misc/animateScroll';

import { CommentContent } from '../CommentContent/CommentContent';
import { CommentInput } from '../CommentInput/CommentInput';

interface CommentItemProps {
  comment: Comment;
  userContext: React.Context<any>;
  onDelete: (comment: Comment) => boolean;
  onUnpublish: (comment: Comment) => void;
  onRepublish: (comment: Comment) => void;
  articleTitle: string;
  increaseCommentCount: () => void;
  decreaseCommentCount: (decreaseValue?: number) => void;
  articleId: string;
  type: string;
  itemSubType: string;
  additionalFeedbackReceivers: AdditionalFeedbackReceiversProps;
}

type RepliesMetaData = {
  limit: number;
  offset: number;
  total: number;
  totalPublished: number;
};

const CommentItem = ({
  comment,
  userContext,
  onDelete,
  onUnpublish,
  onRepublish,
  articleTitle,
  increaseCommentCount,
  decreaseCommentCount,
  articleId,
  type,
  itemSubType,
  additionalFeedbackReceivers,
}: CommentItemProps) => {
  const [replies, setReplies] = useState<Comment[]>([]);
  const [repliesMetaData, setRepliesMetaData] = useState<RepliesMetaData>({
    limit: 0,
    offset: 0,
    total: 0,
    totalPublished: 0,
  });
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isShowingReplies, setIsShowingReplies] = useState<boolean>(true);
  const [isReplyActive, setIsReplyActive] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { published, personId } = comment.getAttributes();

  const fetchReplies = useCallback(async () => {
    const replies = await Comment.getComments(comment.id, '');
    setReplies(replies.data);
    setRepliesMetaData(replies.meta.page);
  }, [comment.id]);

  const fetchMoreReplies = useCallback(
    async (offset: number) => {
      const moreReplies = await Comment.getComments(
        comment.id,
        `?page[limit]=10&page[offset]=${offset}`
      );

      setReplies([...replies, ...moreReplies.data]);
      setRepliesMetaData(moreReplies.meta.page);
    },
    [comment.id, replies]
  );

  useEffect(() => {
    fetchReplies();
  }, [fetchReplies]);

  useEffect(() => {
    if (!published) {
      setIsReplyActive(false);
    }
  }, [published]);

  const onSubmitComment = useCallback(
    async (text: string) => {
      try {
        setIsCreating(true);
        setErrorMessage(null);

        const createComment = await Comment.create(
          text,
          comment.id,
          userContext,
          personId,
          null,
          type,
          itemSubType,
          articleId,
          CommentDataTypeEnum.Reply,
          additionalFeedbackReceivers
        );
        if (createComment) {
          const newReplies = [createComment, ...replies];
          setReplies(newReplies);
          setIsShowingReplies(true);
          GAonSubmitCommentOrReply(articleTitle, true);
          increaseCommentCount();
        }
      } catch (error: any) {
        setErrorMessage(error.message);
      } finally {
        setIsCreating(false);
        return true;
      }
    },
    [
      articleTitle,
      personId,
      comment.id,
      replies,
      userContext,
      increaseCommentCount,
      articleId,
      type,
      itemSubType,
      additionalFeedbackReceivers,
    ]
  );

  const onUpdateReply = useCallback(
    async (comment: Comment, fields: { published: boolean }) => {
      try {
        const updatedReply = await comment.update(
          CommentDataTypeEnum.Reply,
          fields
        );
        if (!fields.published) {
          decreaseCommentCount();
        } else {
          increaseCommentCount();
        }
        const updatedReplies = replies.map((reply) => {
          if (reply.id !== comment.id) {
            return reply;
          }
          return updatedReply;
        });
        setReplies(updatedReplies);
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    },
    [replies, decreaseCommentCount, increaseCommentCount]
  );

  const onDeleteReply = useCallback(
    async (comment: Comment) => {
      try {
        await comment.delete(CommentDataTypeEnum.Reply, articleId);
        const updatedReplies = replies.filter(
          (reply) => reply.id !== comment.id
        );
        setReplies(updatedReplies);
        if (comment.attributes.published) {
          decreaseCommentCount();
        }
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    },
    [replies, decreaseCommentCount, articleId]
  );

  const onDeleteComment = useCallback(
    async (comment: Comment) => {
      const success = await onDelete(comment);
      if (!success) {
        return;
      }
      for (const reply of replies) {
        await reply.delete(CommentDataTypeEnum.Reply, articleId);
      }
    },
    [onDelete, replies, articleId]
  );

  return (
    <div className="comment-item">
      <CommentContent
        comment={comment}
        userContext={userContext}
        onDelete={onDeleteComment}
        onUnpublish={onUnpublish}
        onRepublish={onRepublish}
        articleTitle={articleTitle}
        onReply={() => setIsReplyActive(!isReplyActive)}
        isReplyActive={isReplyActive}
        isReplyEnabled={true}
      />
      <div className="comment-item__list">
        {replies.length > 0 && (
          <button
            className="comment-item__view-replies"
            onClick={() => setIsShowingReplies(!isShowingReplies)}
          >
            {isShowingReplies ? (
              <>
                <Icon
                  type="chevronDown"
                  color="secondary"
                  size={20}
                  sx={{ marginRight: '0.25rem' }}
                />
                {`Hide ${replies.length} Replies`}
              </>
            ) : (
              <>
                <Icon
                  type="chevronRight"
                  color="secondary"
                  size={20}
                  sx={{ marginRight: '0.25rem' }}
                />
                {`View ${replies.length} Replies`}
              </>
            )}
          </button>
        )}
        {isReplyActive && (
          <CommentInput
            onSubmit={onSubmitComment}
            userContext={userContext}
            isCreating={isCreating}
            maxLength={500}
            isReply={true}
          />
        )}
        {errorMessage && <Error message={errorMessage} />}
        {isShowingReplies && (
          <>
            {replies.map((reply) => (
              <CommentContent
                key={reply.id}
                comment={reply}
                userContext={userContext}
                onDelete={() => onDeleteReply(reply)}
                onUnpublish={() => onUpdateReply(reply, { published: false })}
                onRepublish={() => onUpdateReply(reply, { published: true })}
                articleTitle={articleTitle}
                onReply={() => setIsReplyActive(!isReplyActive)}
                isReplyActive={isReplyActive}
                isReplyEnabled={false}
              />
            ))}
            {repliesMetaData.total > replies.length && (
              <Button
                variant="outlined"
                size="small"
                sx={(theme) => ({
                  marginLeft: '3.5rem',
                  marginTop: theme.spacing('md'),
                  marginBottom: theme.spacing('sm'),
                })}
                onClick={() => {
                  fetchMoreReplies(repliesMetaData.offset + 1);
                  animateScroll(220, 50);
                }}
                endIcon={
                  <Icon type="ellipsisHorizontal" color="brandBase" size={16} />
                }
              >
                {translations.loadMoreReplies}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { CommentItem };
