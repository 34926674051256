import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { translations } from 'translations';

const elementId = 'innovation-learning-article-list';

type LearningArticleListProps = {
  sx?: StackProps['sx'];
};

const LearningArticleList = ({ sx }: LearningArticleListProps) => {
  return (
    <Stack
      id={elementId}
      sx={[
        (theme) => ({
          width: '100%',
          backgroundColor: theme.colors.surface.primary,
          borderRadius: theme.border.radius.md,
          padding: theme.spacing('sm'),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography variant="srOnly" component="h2">
        {translations.articles}
      </Typography>
      TODO: Learning article list
    </Stack>
  );
};
export { LearningArticleList };
