import './Navigation.scss';

import { useUser } from 'components/Context/User';
import UserImage from 'components/UserImage';
import { AvenueRouteEnum } from 'enums';
import { Link } from 'react-router-dom';
import { logout } from 'utils/auth';

import NavigationItem from './NavigationItem';

export const UserLink = () => {
  const user = useUser();

  return (
    <Link className="navigation__user" to={AvenueRouteEnum.Profile}>
      <UserImage
        src={user.imageUrl}
        name={user.displayName}
        initials={user.initials}
      />
      <div className="navigation__details">
        <span className="navigation__name">{user.displayName}</span>
        {user.divisionName && user.countryName ? (
          <span className="navigation__location">{`${user.divisionName} | ${user.countryName}`}</span>
        ) : user.divisionName ? (
          <span className="navigation__location">{user.divisionName}</span>
        ) : null}
      </div>
    </Link>
  );
};

export const UserLinkList = () => (
  <ul className="navigation__items navigation__items--bottom-group">
    <NavigationItem to={AvenueRouteEnum.Profile} color="white">
      Profile
    </NavigationItem>
    <hr className="navigation__item-divider" />
    <NavigationItem to="/ " color="white" onClick={logout}>
      Log Out
    </NavigationItem>
  </ul>
);
