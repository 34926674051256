import './Notifications.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import * as feedbackApi from 'api/feedback';
import { NotificationDTO } from 'api/feedback/types';
import { UserContext } from 'components/Context/User';
import { AvenueRouteEnum } from 'enums';
import {
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Link } from 'react-router-dom';

import NotificationItem from './NotificationItem';

const EmptyNotificationsList = () => (
  <div className="notifications__empty">
    You currently have no new notifications.
  </div>
);

interface NotificationsViewProps {
  notifications: NotificationDTO[];
  markAllAsRead: () => void;
  markAsRead: (notificationId: string) => void;
  setShowNotifications: (state: SetStateAction<boolean>) => void;
  deleteNotification: (notificationId: string) => void;
}

const NotificationsView: FC<NotificationsViewProps> = ({
  notifications,
  markAllAsRead,
  markAsRead,
  setShowNotifications,
  deleteNotification,
}) => (
  <div className="notifications__view">
    <div className="notifications__view--title">
      <span>Notification Center</span>
      {notifications.length > 0 ? (
        <span className="mark-as-read" onClick={markAllAsRead}>
          Mark all as read
        </span>
      ) : null}
    </div>
    <ul className="notifications__view--list">
      {notifications.length < 1 ? (
        <EmptyNotificationsList />
      ) : (
        notifications.map((notification, index) => (
          <NotificationItem
            key={index}
            notification={notification}
            markAsRead={markAsRead}
            deleteNotification={deleteNotification}
          />
        ))
      )}
    </ul>
    <Link
      to={AvenueRouteEnum.ProfileManageLibrary}
      className="notifications-manage-my-content"
      onClick={() => setShowNotifications(false)}
    >
      Manage my content
    </Link>
  </div>
);

const Notifications = ({ show, setShow, setNewNotificationState }: any) => {
  const context = useContext(UserContext);
  const [notifications, setNotifications] = useState<NotificationDTO[]>([]);

  const updateNotifications = useCallback(
    (newNotifications: NotificationDTO[]) => {
      const notificationsHasUnread = newNotifications.some(
        (notification: NotificationDTO) => !notification.read
      );
      setNotifications(newNotifications);
      setNewNotificationState(notificationsHasUnread);
    },
    [setNewNotificationState]
  );

  const fetchNotificationsForUser = useCallback(() => {
    if (!context?.userId) return;
    feedbackApi
      .getNotifications(context.userId)
      .then((response) => {
        updateNotifications(response);
      })
      .catch(() => {
        console.error('Retrieving notifications failed.');
      });
  }, [context.userId, updateNotifications]);

  const handleMarkAsRead = (notificationId: string) => {
    feedbackApi.markNotificationAsRead(notificationId, true);
    const newNotifications = notifications.map((notification) => {
      if (notification.id === notificationId) {
        notification.read = true;
      }
      return notification;
    });
    updateNotifications(newNotifications);
  };

  const handleMarkAllAsRead = () => {
    feedbackApi.markAllNotificationAsRead();
    const newNotifications = notifications.map((notification) => {
      notification.read = true;
      return notification;
    });
    updateNotifications(newNotifications);
  };

  const handleDeleteNotification = (notificationId: string) => {
    feedbackApi.deleteNotification(notificationId);
    const newNotifications = notifications.filter(
      (notification) => notification.id !== notificationId
    );
    updateNotifications(newNotifications);
  };

  useEffect(() => {
    fetchNotificationsForUser();
  }, [fetchNotificationsForUser]);

  return show ? (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <div className="notifications new-design-page-spacing">
        <NotificationsView
          notifications={notifications}
          markAllAsRead={handleMarkAllAsRead}
          markAsRead={handleMarkAsRead}
          setShowNotifications={setShow}
          deleteNotification={handleDeleteNotification}
        />
      </div>
    </ClickAwayListener>
  ) : null;
};

export default Notifications;
