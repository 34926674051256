export enum CommentDataTypeEnum {
  Comment = 'comments',
  Reply = 'replies',
}

export interface AdditionalFeedbackReceiversProps {
  additionalFeedbackReceivers: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  }[];
}

export type LikesAndCommentsAndViewsCount = {
  articleId: string;
  likesCount: number;
  commentsCount: number;
  newCommentsCount: number;
  viewsCount: number;
};

export type LikesAndCommentsAndViewsCountResponse = {
  data: {
    data: LikesAndCommentsAndViewsCount[];
  };
};

export type FeedbackCount = {
  articleId: string;
  helpfulCount: number;
  notHelpfulCount: number;
  neutralCount: number;
};

export type FeedbackCountResponse = {
  data: {
    data: FeedbackCount[];
  };
};

export enum NotificationTypeEnum {
  Comment,
  PositiveFeedback,
  NegativeFeedback,
  Warning,
  Information,
  Reply,
}

export interface NotificationDTO {
  id: string;
  type: NotificationTypeEnum;
  title: string;
  body: string;
  linkUrl: string;
  timestamp: string;
  read: boolean;
  personId: string;
}
