import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import * as organizationApi from 'api/organization';
import { useUser } from 'components/Context/User';
import { useSnackbar } from 'context';
import { FormFieldWrapper } from 'layout';
import { useCallback, useEffect, useState } from 'react';
import { translations } from 'translations';
import { GAonClickProfileSubscriptionsSave } from 'utils/analytics';

import * as helpers from './helpers';
import { SubscriptionsForm } from './helpers';
import { SubscriptionsSkeleton } from './SubscriptionsSkeleton';

const elementId = 'profile-subscriptions';

const Subscriptions = () => {
  const [formInput, setFormInput] = useState<SubscriptionsForm>(
    helpers.getInitialFormInput()
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = useUser();
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();

  const onSubscribeToInsightsDatabaseChange = useCallback(
    async (value: boolean) => {
      setIsLoading(true);
      setFormInput({ ...formInput, subscribesToInsightsDatabase: value });
      GAonClickProfileSubscriptionsSave('subscribesToInsightsDatabase', value);
      try {
        await organizationApi.updateProfileSettings(user.userId, {}, value);
      } catch {
        setFormInput({ ...formInput, subscribesToInsightsDatabase: !value });
        setIsLoading(false);
        showSnackbar({ type: 'error', text: translations.profileUpdateError });
        return;
      }
      try {
        await user.fetchUser();
      } catch {
      } finally {
        setIsLoading(false);
        showSnackbar({
          type: 'success',
          text: translations.profileUpdateSuccess,
        });
      }
    },
    [formInput, showSnackbar, user]
  );

  useEffect(() => {
    if (user.isLoading) return;
    setFormInput(helpers.getInitialFormInput(user));
  }, [user]);

  if (user.isLoading) {
    return <SubscriptionsSkeleton />;
  }

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing('md'),
        backgroundColor: theme.colors.surface.primary,
      })}
    >
      <Grid
        container
        id={elementId}
        spacing={theme.spacing('md')}
        flexWrap="wrap-reverse"
      >
        <Grid xs={12} md={7}>
          <Stack sx={(theme) => ({ rowGap: theme.spacing('sm') })}>
            <Stack
              sx={(theme) => ({
                flexDirection: 'row',
                alignItems: 'center',
                columnGap: theme.spacing('md'),
              })}
            >
              <Typography
                sx={(theme) => ({
                  color: theme.colors.text.tertiary,
                  fontWeight: 'bold',
                })}
              >
                {translations.profileSubscriptionsInsightsDatabase}
              </Typography>
              <FormFieldWrapper
                id={`${elementId}-subscribesToInsightsDatabase`}
                label={translations.profileSubscriptionsInsightsDatabase}
                hideLabel={true}
                fullWidth={false}
              >
                <Switch
                  checked={formInput.subscribesToInsightsDatabase}
                  disabled={isLoading}
                  onChange={(_e, checked) =>
                    onSubscribeToInsightsDatabaseChange(checked)
                  }
                />
              </FormFieldWrapper>
            </Stack>
            <Divider />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export { Subscriptions };
