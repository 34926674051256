import './Navigation.scss';

import { getPinnedChannels } from 'api/cms/topics';
import { Column } from 'components/Grid';
import { ReactComponent as ChevronLeft } from 'components/Icon/ChevronLeft.svg';
import { ReactComponent as ChevronRight } from 'components/Icon/ChevronRight.svg';
import { Mobile } from 'components/Responsive';
import { useLibraryRoot } from 'context';
import { useCallback, useEffect, useRef, useState } from 'react';
import Overflow from 'react-overflow-indicator';
import { GAonExternalMenuItemClick } from 'utils/analytics';
import { loadData } from 'utils/api/handleAPIData';
import { getChannelUrl } from 'utils/api/urls';
import { scrollByHelper } from 'utils/misc/scrollByPonyfillHelper';
import useWindowDimensions from 'utils/misc/useWindowDimensions';

import NavigationItem from './NavigationItem';
import { UserLink, UserLinkList } from './PageHeaderItems';

const MAX_WIDTH = 1280;

const getLearningLink = () => {
  // AADW-3253	GPS as learning link for all users
  return 'https://performancemanager.successfactors.eu/sf/learning?Treat-As=WEB&bplte_company=assaabloya';
};

const MenuContent = () => {
  const [response, setResponse] = useState([]);
  const [viewport, setViewport] = useState(null);
  const [channelsUlWidth, setChannelsUlWidth] = useState(null);

  const libraryRoot = useLibraryRoot();
  const { width } = useWindowDimensions();

  // Creates references for first level menu items and Overflow indikator
  const itemWrapperRef = useRef(null);

  useEffect(() => {
    const getPinnedResponse = async () => {
      const pinnedResponse = await loadData({
        apiCall: getPinnedChannels,
      }).promise;

      setResponse(pinnedResponse.response);
    };

    getPinnedResponse();
  }, []);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setChannelsUlWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const handleFirstLevelScrollRef = (refs, canScroll) => {
    if (viewport && itemWrapperRef && itemWrapperRef.current && !canScroll) {
      scrollByHelper(viewport, {
        left: -itemWrapperRef.current.clientWidth,
        behavior: 'smooth',
      });
      return;
    }

    if (
      !refs ||
      !refs.viewport ||
      !refs.viewport.current ||
      !itemWrapperRef ||
      !itemWrapperRef.current
    ) {
      return;
    }

    scrollByHelper(refs.viewport.current, {
      left: refs.viewport.current.clientWidth,
      behavior: 'smooth',
    });
    setViewport(refs.viewport.current);
  };

  return (
    <div className="nav--border">
      <div className="new-design-page-spacing">
        <div className="navigation__inner navigation--desktop">
          <Column noPaddingLeft={true} noPaddingRight={true}>
            <Overflow className="navigation__overflow">
              <Overflow.Content>
                <UserLink />
                <div className="navigation__items-wrapper" ref={itemWrapperRef}>
                  <ul className="navigation__items">
                    <NavigationItem to="/" exact>
                      Home
                    </NavigationItem>
                    <NavigationItem to="/feed">Feed</NavigationItem>
                    <NavigationItem to="/jobs">Jobs</NavigationItem>
                    <NavigationItem
                      to={`/library${libraryRoot.myLibraryPath}`}
                      disabled={!libraryRoot.myLibraryPath}
                      activePath="/library"
                    >
                      My Division
                    </NavigationItem>
                    <NavigationItem to="/group-library">
                      Group Library
                    </NavigationItem>
                    <NavigationItem
                      type="outbound"
                      to={getLearningLink()}
                      onClick={GAonExternalMenuItemClick('Learning')}
                    >
                      Learning
                    </NavigationItem>
                  </ul>
                  {response.length > 0 && (
                    <>
                      <hr className="navigation__item-divider" />
                      <ul
                        className="navigation__items navigation__items--channels"
                        ref={measuredRef}
                      >
                        {response.map((item) => (
                          <NavigationItem
                            to={getChannelUrl(item)}
                            key={item._doc}
                          >
                            #{item.title}
                          </NavigationItem>
                        ))}
                      </ul>
                    </>
                  )}
                  <UserLinkList />
                </div>
              </Overflow.Content>
              {channelsUlWidth && (
                <>
                  {itemWrapperRef.current.clientWidth > width ||
                  itemWrapperRef.current.clientWidth > MAX_WIDTH ? (
                    <>
                      <Overflow.Indicator direction="right">
                        {(canScroll, refs) => (
                          <button
                            type="button"
                            onClick={() => {
                              handleFirstLevelScrollRef(refs, canScroll);
                            }}
                            className="navigation__scroll-button"
                          >
                            {canScroll ? <ChevronRight /> : <ChevronLeft />}
                          </button>
                        )}
                      </Overflow.Indicator>
                    </>
                  ) : (
                    <>{handleFirstLevelScrollRef(null, null)}</>
                  )}
                </>
              )}
            </Overflow>
          </Column>
        </div>
        <Mobile>
          <div className="navigation__inner">
            <Column>
              <UserLink />
              <div className="navigation__items-wrapper">
                <ul className="navigation__items">
                  <NavigationItem to="/" exact>
                    Home
                  </NavigationItem>
                  <NavigationItem to="/feed">Feed</NavigationItem>
                  <NavigationItem to="/jobs">Jobs</NavigationItem>
                  <NavigationItem
                    to={`/library${libraryRoot.myLibraryPath}`}
                    disabled={!libraryRoot.myLibraryPath}
                    activePath="/library"
                  >
                    My Division
                  </NavigationItem>
                  <NavigationItem to="/group-library">
                    Group Library
                  </NavigationItem>
                  <NavigationItem
                    type="outbound"
                    to={getLearningLink()}
                    onClick={GAonExternalMenuItemClick('Learning')}
                  >
                    Learning
                  </NavigationItem>
                </ul>
                {response.length > 0 && (
                  <hr className="navigation__item-divider" />
                )}
                <ul className="navigation__items">
                  {response.map((item) => (
                    <NavigationItem to={getChannelUrl(item)} key={item._doc}>
                      #{item.title}
                    </NavigationItem>
                  ))}
                </ul>
                {response.length > 0 && (
                  <hr className="navigation__item-divider" />
                )}
                <UserLinkList />
              </div>
            </Column>
          </div>
        </Mobile>
      </div>
    </div>
  );
};

export default MenuContent;
