import Checkbox from 'components/Checkbox';
import Flex from 'components/Flex';

import { Consumer } from '..';

const Archived = ({ label, helperText }) => (
  <Consumer>
    {({ onToggleArticleValue, article: { archived } }) => (
      <Flex align="center">
        <Checkbox
          defaultChecked={archived}
          id="archived"
          onChange={() => onToggleArticleValue('archived')}
        />
        <div className="m-l-3">
          <label htmlFor="archived">{label || 'Archive'}</label>
          {helperText && (
            <p style={{ margin: 0, fontSize: '0.875rem' }}>{helperText}</p>
          )}
        </div>
      </Flex>
    )}
  </Consumer>
);

export default Archived;
