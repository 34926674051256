import './SuggestionItem.scss';

import Suggestion from 'api/models/Suggestion';
import classnames from 'classnames';
import Parser from 'components/Parser';
import { History } from 'history';
import {
  GAonClickPortalSearchResultSuggestion,
  getPortalType,
} from 'utils/analytics';
import { splitSuggestion } from 'utils/misc/autoSuggest';

export interface InnovationSearchSuggestionsItemProps {
  suggestion: Suggestion;
  searchQuery: string;
  justSelectedSuggestionIndex: number | null;
  history: History;
  index: number;
  hoverSearchResult: (index: number) => void;
}

const SuggestionItem = ({
  suggestion,
  searchQuery,
  justSelectedSuggestionIndex,
  index,
  history,
  hoverSearchResult,
}: InnovationSearchSuggestionsItemProps) => {
  const redirectToLink = (suggestion: Suggestion) => {
    const portalType = getPortalType(suggestion.portalType);
    const title = suggestion.attributes.title;
    GAonClickPortalSearchResultSuggestion(title, portalType);

    const suggestionUrl = suggestion.get('linkUrl');
    const type = suggestion.type;

    if (type === 'tool') {
      window.open(suggestionUrl, '_blank');
    } else if (
      suggestionUrl &&
      Boolean(~suggestionUrl.indexOf(window.location.origin))
    ) {
      history.push(suggestionUrl.replace(window.location.origin, ''));
    } else {
      window.location.href = suggestionUrl;
    }
  };

  const searchSuggestionIsHovered = () => {
    hoverSearchResult(index);
  };

  const parts: { text: string; highlight: boolean }[] = splitSuggestion(
    suggestion.get('title'),
    searchQuery
  );
  const body = suggestion.get('body');
  const suggestionClasses = classnames('innovation-search-bar__suggestion', {
    'innovation-search-bar__suggestion--selected':
      justSelectedSuggestionIndex === index,
  });

  return (
    <li
      onMouseEnter={() => searchSuggestionIsHovered()}
      className={suggestionClasses}
      key={suggestion.id}
      onClick={() => redirectToLink(suggestion)}
    >
      <p className="innovation-search-bar__suggestion-title">
        {parts.map((part, i) => {
          const highlightClass = classnames({
            highlight: part.highlight,
          });
          return (
            <span className={highlightClass} key={i}>
              {part.text}
            </span>
          );
        })}
      </p>

      {!body && (
        <p className="innovation-search-bar__suggestion-category">
          {suggestion.getTypeLabel()}
        </p>
      )}
      {body && (
        <p className="innovation-search-bar__suggestion-preamble">
          <Parser html={body.substring(0, 255)} type="Feed" />
        </p>
      )}
    </li>
  );
};

export default SuggestionItem;
