import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getJobs, headers } from 'api/jobs';
import { JobListingItem } from 'api/jobs/types';
import { useUser } from 'components/Context/User';
import { LinkList } from 'componentsNew';
import type { LinkListItem } from 'componentsNew/LinkList';
import { PageContentHeader } from 'layout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import {
  GAonClickHomeJobsItem,
  GAonClickHomeJobsReadMore,
} from 'utils/analytics';
import { loadData } from 'utils/api/handleAPIData';
import makeQueryString from 'utils/misc/makeQueryString';

import { JobOpeningsNoJobs } from './JobOpeningsNojobs';
import { JobOpeningsSkeleton } from './JobOpeningsSkeleton';

const elementId = 'home-job-openings';

const injectDepartmentCountry = (
  departmentName: string,
  countryName: string,
  limit: number
) =>
  makeQueryString({
    user: {
      departmentname: departmentName,
      countryname: countryName,
    },
    page: {
      limit,
    },
  });

const JobOpenings = () => {
  const [jobOpeningsData, setjobOpeningsData] = useState<JobListingItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = useUser();

  const fetchJobOpenings = useCallback(async () => {
    if (user.isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await loadData({
        apiCall: getJobs,
        reducer: undefined,
        headers: headers,
        param: injectDepartmentCountry(
          user.departmentName,
          user.countryName,
          3
        ),
        createLoading: undefined,
        dataState: undefined,
        useModelFactory: undefined,
      }).promise;

      const jobOpenings = response.response as JobListingItem[];
      setjobOpeningsData(jobOpenings);
    } finally {
      setIsLoading(false);
    }
  }, [user.countryName, user.departmentName, user.isLoading]);

  useEffect(() => {
    fetchJobOpenings();
  }, [fetchJobOpenings]);

  const items = useMemo(() => {
    return jobOpeningsData
      .map((jobOpening) => {
        if (!jobOpening.id || !jobOpening.title) return null;

        const categoryAndDepartment =
          jobOpening.category?.name && jobOpening.department?.name
            ? `${jobOpening.category.name} ${jobOpening.department.name}`
            : '';

        const item: LinkListItem = {
          id: `${jobOpening.id}`,
          title: jobOpening.title,
          descriptions: [categoryAndDepartment],
          subDescriptions: [
            `${translations.hiringManager} ${
              jobOpening.hiringManager || translations.notAvailable
            }`,
          ],
          to: `jobs/${jobOpening.id}`,
          divider: true,
          element: (
            <Box>
              {jobOpening.places &&
                jobOpening.places.length &&
                jobOpening.places.map(
                  (place, index) =>
                    index < 3 && (
                      <Typography
                        key={index}
                        variant="body2"
                        sx={(theme) => ({
                          color: theme.colors.text.tertiary,
                          textAlign: 'right',
                        })}
                      >
                        {place}
                      </Typography>
                    )
                )}
            </Box>
          ),
        };
        return item;
      })
      .filter(Boolean) as LinkListItem[];
  }, [jobOpeningsData]);

  if (isLoading) {
    return (
      <>
        <PageContentHeader text={translations.jobOpeningsTitle} />
        <JobOpeningsSkeleton />
      </>
    );
  }

  return (
    <>
      <PageContentHeader text={translations.jobOpeningsTitle} />

      {jobOpeningsData.length < 1 ? (
        <JobOpeningsNoJobs />
      ) : (
        <LinkList
          id={elementId}
          items={items}
          onClick={(item) => GAonClickHomeJobsItem(item.title)}
        />
      )}
      <Button
        id={`${elementId}-read-more`}
        to="jobs"
        variant="linkButton"
        component={ReactRouterLink}
        onClick={GAonClickHomeJobsReadMore}
        sx={(theme) => ({
          marginTop: theme.spacing('xs'),
          float: 'right',
          lineHeight: 0.875,
        })}
      >
        {translations.allJobs}
      </Button>
    </>
  );
};

export { JobOpenings };
