import {
  deleteComment,
  getComments,
  sendComment,
  updateComment,
} from 'api/feedback';
import {
  AdditionalFeedbackReceiversProps,
  CommentDataTypeEnum,
} from 'api/feedback/types';

import { convertResponse } from './convertResponse';
import DataModel from './DataModel';
import User from './User';

export enum Action {
  Delete = 'delete',
  Unpublish = 'unpublish',
  Republish = 'republish',
}

class Comment extends DataModel {
  user: User | null = null;

  constructor(id: any, attributes: any, includes: any) {
    super('Comment', id, attributes, includes);
  }

  getUser() {
    return this.user;
  }

  async update(dataType: CommentDataTypeEnum, changes: any) {
    const response = await updateComment(dataType, this.id, changes);
    const { data: comment } = convertResponse(Comment, response);

    const { personId } = comment.getAttributes();

    if (personId) {
      const user = await User.getUser(personId);

      comment.user = user;
    }

    return comment;
  }

  delete(dataType: CommentDataTypeEnum, articleId: string) {
    return deleteComment(dataType, this.id, articleId);
  }

  setUserFromContext(userContext: any) {
    const user = User.createFromContext(userContext);
    this.user = user;
  }

  getUserActions(userContext: any) {
    const user = this.user;

    let userActions: Record<Action, boolean> | null = null;

    if (userContext.roles && userContext.roles.includes('AvenueBlogEditor')) {
      const { published } = this.attributes;
      userActions = {
        [Action.Delete]: published === false,
        [Action.Unpublish]: published !== false,
        [Action.Republish]: published === false,
      };
    } else if (user && user.id === userContext.userId) {
      userActions = {
        [Action.Delete]: true,
        [Action.Unpublish]: false,
        [Action.Republish]: false,
      };
    }

    return userActions;
  }

  static async create(
    text: any,
    targetEntityId: any,
    userContext: any,
    authorId: any,
    editorId: any,
    type: any,
    itemSubType: any,
    articleId: string,
    dataType: CommentDataTypeEnum,
    additionalFeedbackReceivers: AdditionalFeedbackReceiversProps
  ) {
    const attributes = {
      text,
      targetEntityId,
      articleId,
      personId: userContext.userId,
      authorId: authorId,
      editorId: editorId,
      type: type,
      published: true,
      itemSubType,
      additionalFeedbackReceivers,
    };

    const response = await sendComment(dataType, attributes);

    const { data: comment } = convertResponse(Comment, response);
    comment.setUserFromContext(userContext);

    return comment;
  }

  static async getComments(articleId: any, page: any) {
    const response = await getComments(articleId, page);

    const { data, meta, links } = convertResponse(Comment, response);

    // create unique list of user ids and fetch the users
    const personIds = new Set();

    data.forEach((comment: any) => {
      if (comment.attributes.personId) {
        personIds.add(comment.attributes.personId);
      }
    });

    const users = await User.getUsersFromList(Array.from(personIds));

    const userMap = users.reduce((acc: any, user: any) => {
      acc[user.id] = user;

      return acc;
    }, {});

    data.forEach((comment: any) => {
      const { personId } = comment.attributes;
      comment.user = userMap[personId];
    });

    return {
      data,
      meta,
      links,
    };
  }
}

export default Comment;
