import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import * as aiSearchApi from 'api/aiSearch';
import { useUser } from 'components/Context/User';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';

import { ReactComponent as RobotIcon } from '../icons/Robot.svg';
import { AIChatActionCopyResult } from './AIChatActionCopyResult';
import { AIChatActionDislikeDisclaimer } from './AIChatActionDislikeDisclaimer';
import { AIChatActionDislikeResult } from './AIChatActionDislikeResult';
import { AIChatActionLikeResult } from './AIChatActionLikeResult';
import { AIChatLoadingDots } from './AIChatLoadingDots';
import { ChatItemResult } from './AIChatSheet';
import { AIChatSourceList } from './AIChatSourceList';

type StreamSources = {
  linkUrl: string;
  title: string;
};

type StreamMessage = {
  text?: string;
  sources?: StreamSources[];
};

type AIChatBubbleResultProps = {
  id: string;
  chatItem: ChatItemResult;
  isAutoScrollDisabled: boolean;
  onResultStreamStart: () => void;
  onResultStreamDone: (chatItem: ChatItemResult, error?: boolean) => void;
  onDisliked?: () => void;
  onLiked?: () => void;
};

const TYPING_INCREMENT = 3;
const TYPING_DELAY = 50;

const AIChatBubbleResult = ({
  id,
  chatItem,
  isAutoScrollDisabled,
  onResultStreamStart,
  onResultStreamDone,
  onDisliked,
  onLiked,
}: AIChatBubbleResultProps) => {
  const [isStreamLoading, setIsStreamLoading] = useState<boolean>(false);
  const [isStreamConnected, setIsStreamConnected] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [streamText, setStreamText] = useState<string>('');
  const [streamSources, setStreamSources] = useState<StreamSources[]>([]);
  const [currentTypingIndex, setCurrentTypingIndex] = useState<number>(0);

  const bottomRef = useRef<HTMLDivElement | null>(null);
  const typingIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const user = useUser();

  const streamPromptResult = useCallback(
    async (
      chatItem: ChatItemResult,
      params: {
        objectId: string;
        countryId: string;
        divisionId: string;
        divisionName: string;
        departmentId: string;
        siteId: string;
        companyId: string;
        regionId: string;
      }
    ) => {
      try {
        setIsStreamLoading(true);
        let _streamText = '';

        const response = await aiSearchApi.sendPrompt({
          objectId: params.objectId || '',
          prompt: chatItem.prompt,
          dateTime: new Date().toString(),
          countryId: params.countryId || '',
          divisionId: params.divisionId || '',
          divisionName: params.divisionName || '',
          departmentId: params.departmentId || '',
          siteId: params.siteId || '',
          companyId: params.companyId || '',
          regionId: params.regionId || '',
        });

        const responseStream = response.data.data as ReadableStream;

        const reader = responseStream
          .pipeThrough(new TextDecoderStream())
          .getReader();

        const readStreamChunk = async () => {
          const { value, done } = await reader.read();

          if (done) {
            setIsStreamConnected(false);
            return;
          }

          if (!value) return;

          const chunkRegExp = new RegExp(/data: (.*)\n\n/g);
          const chunks = value.match(chunkRegExp);
          if (!chunks) return;

          chunks.forEach((chunk) => {
            const startIndex = chunk.indexOf('{');
            const endIndex = chunk.lastIndexOf('}') + 1;
            const content = chunk.substring(startIndex, endIndex);

            const message = JSON.parse(content) as StreamMessage;

            if (message.text) {
              setStreamText((stream) => {
                _streamText = `${stream}${message.text}`;
                return _streamText;
              });
            }

            if (message.sources) {
              setStreamSources(message.sources);
            }
          });

          await readStreamChunk();
        };

        if (typingIntervalRef.current) {
          return;
        }

        typingIntervalRef.current = setInterval(() => {
          setCurrentTypingIndex((prevCurrentTypingIndex) => {
            if (prevCurrentTypingIndex >= _streamText.length - 1) {
              return prevCurrentTypingIndex;
            }

            return prevCurrentTypingIndex + TYPING_INCREMENT;
          });
        }, TYPING_DELAY);

        setIsStreamLoading(false);
        setIsStreamConnected(true);
        setIsTyping(true);

        await readStreamChunk();
      } catch (e) {
        setIsStreamLoading(false);
        onResultStreamDone(chatItem, true);
      }
    },
    [onResultStreamDone]
  );

  // Starts streaming request
  useEffect(() => {
    if (user.isLoading || chatItem.result.text !== '') {
      return;
    }

    onResultStreamStart();
    streamPromptResult(chatItem, {
      objectId: user.userId,
      countryId: user.countryId,
      divisionId: user.divisionId,
      divisionName: user.divisionName,
      departmentId: user.departmentId,
      siteId: user.siteId,
      companyId: user.companyId,
      regionId: user.regionId,
    });
  }, [
    user.isLoading,
    user.userId,
    user.countryId,
    user.divisionId,
    user.divisionName,
    user.departmentId,
    user.siteId,
    user.companyId,
    user.regionId,
    chatItem,
    streamPromptResult,
    onResultStreamStart,
    onResultStreamDone,
  ]);

  // Handles auto scrolling
  useEffect(() => {
    const scroll =
      !isAutoScrollDisabled &&
      currentTypingIndex > 0 &&
      currentTypingIndex % 30 === 0;

    if (!scroll || !bottomRef.current) {
      return;
    }

    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [currentTypingIndex, isAutoScrollDisabled]);

  // Detects when stream connection and typing is done
  useEffect(() => {
    if (chatItem.result.text.length === streamText.length) {
      return;
    }

    const isStreamingAndTypingDone =
      currentTypingIndex !== 0 &&
      currentTypingIndex >= streamText.length - 1 &&
      !isStreamConnected;

    if (isStreamingAndTypingDone) {
      const newChatItem: ChatItemResult = {
        ...chatItem,
        result: {
          text: streamText,
          sources: streamSources,
        },
      };

      setIsTyping(false);
      onResultStreamDone(newChatItem);

      if (typingIntervalRef.current) {
        clearInterval(typingIntervalRef.current);
      }
    }
  }, [
    currentTypingIndex,
    streamText,
    streamSources,
    isStreamConnected,
    onResultStreamDone,
    chatItem,
  ]);

  const bubbleContent = useMemo(() => {
    if (isStreamLoading) {
      return <AIChatLoadingDots />;
    }
    return (
      <>
        <Stack sx={(theme) => ({ gap: theme.spacing('xs') })}>
          <Box
            dangerouslySetInnerHTML={{
              __html: `${streamText.slice(0, currentTypingIndex)}`,
            }}
          />
          <TransitionGroup>
            {!isStreamConnected && !isTyping && (
              <Collapse
                onEntered={() => {
                  if (bottomRef.current && !isAutoScrollDisabled)
                    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                {streamSources.length > 0 && (
                  <AIChatSourceList
                    id={`${id}-source-list`}
                    sources={streamSources}
                  />
                )}

                <Stack
                  sx={(theme) => ({
                    flexDirection: 'row',
                    borderRadius: theme.border.radius.md,
                    backgroundColor: `${theme.colors.surface.secondary}B3`,
                    padding: theme.spacing('xxxxs'),
                    gap: theme.spacing('xxxxs'),
                  })}
                >
                  <AIChatActionCopyResult
                    id={`${id}-copy`}
                    result={chatItem.result}
                  />
                  <AIChatActionDislikeResult
                    id={`${id}-dislike`}
                    chatItem={chatItem}
                    onDisliked={() => {
                      setTimeout(() => {
                        if (bottomRef.current) {
                          bottomRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                          });
                        }
                      }, 500);
                      onDisliked && onDisliked();
                    }}
                  />
                  <AIChatActionLikeResult
                    id={`${id}-like`}
                    chatItem={chatItem}
                    onLiked={onLiked}
                  />
                </Stack>
              </Collapse>
            )}
          </TransitionGroup>
        </Stack>
        <Collapse in={chatItem?.isDisliked}>
          <AIChatActionDislikeDisclaimer
            sx={(theme) => ({ marginTop: theme.spacing('xs') })}
          />
        </Collapse>
      </>
    );
  }, [
    id,
    isStreamLoading,
    isStreamConnected,
    isTyping,
    streamText,
    streamSources,
    currentTypingIndex,
    isAutoScrollDisabled,
    chatItem,
    onDisliked,
    onLiked,
  ]);

  return (
    <Stack>
      <Stack
        id={id}
        sx={(theme) => ({
          flexDirection: 'row',
          gap: theme.spacing('xs'),
          '> svg': { flexShrink: 0 },
        })}
      >
        <RobotIcon height={32} width={32} />
        <Stack
          sx={(theme) => ({
            padding: isStreamLoading
              ? theme.spacing('xxs')
              : theme.spacing('sm'),
            boxShadow: theme.elevation.sm,
            borderRadius: theme.border.radius.lg,
            border: `1px solid ${theme.colors.border.surfaceInformative}`,
            backgroundColor: theme.colors.surface.informative,
            typography: theme.typography.body1,
            wordBreak: 'break-word',
          })}
        >
          {bubbleContent}
        </Stack>
      </Stack>
      <Box ref={bottomRef} />
    </Stack>
  );
};

export { AIChatBubbleResult };
