import { UserConsumer } from 'components/Context/User';
import { memo } from 'react';

import Onboarding from './Onboarding';
import Profile from './Profile';
import Terms from './Terms';

export const ONBOARDING_GUID = 'dc8e0d4b-e435-4b4e-b978-469ef9fc741c';
export const PROFILE_GUID = '69e32cf1-d23e-41df-b886-d9b8be8a93ad';
export const TERMS_GUID = 'd5d04941-3262-4c76-ae5b-2679264c9d5f';

const Modals = () => (
  <UserConsumer>
    {(ctx) => {
      const { consents = [], profileUpdated } = ctx;
      const checkConsent = (guid) =>
        consents.some((c) => c.id === guid) === false;

      // Show the Onboarding and Profile modals only during load
      // Not again after Profile data is updated
      if (!profileUpdated && checkConsent(ONBOARDING_GUID)) {
        return <Onboarding {...ctx} />;
      } else if (!profileUpdated && checkConsent(PROFILE_GUID)) {
        return <Profile {...ctx} />;
      } else if (checkConsent(TERMS_GUID)) {
        return <Terms {...ctx} />;
      } else {
        return null;
      }
    }}
  </UserConsumer>
);

export default memo(Modals);
