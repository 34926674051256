import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { translations } from 'translations';

const elementId = 'innovation-learning-filter';

type LearningFilterProps = {
  sx?: StackProps['sx'];
};

const LearningFilter = ({ sx }: LearningFilterProps) => {
  return (
    <Stack
      id={elementId}
      sx={[
        (theme) => ({
          width: '100%',
          backgroundColor: theme.colors.surface.primary,
          borderRadius: theme.border.radius.md,
          padding: theme.spacing('sm'),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography variant="srOnly" component="h2">
        {translations.filter}
      </Typography>
      TODO: Learning filter
    </Stack>
  );
};
export { LearningFilter };
