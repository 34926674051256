import './View.scss';

import Stack from '@mui/material/Stack';
import { getBlogPost } from 'api/cms/blog/';
import { ArticleFooterAndComments } from 'components/ArticleFooterAndComments';
import Author from 'components/Author';
import BlogNewsHeader from 'components/BlogNewsHeader';
import Box from 'components/Box';
import Breadcrumbs from 'components/Breadcrumbs';
import { UserConsumer } from 'components/Context/User';
import Error from 'components/Error';
import NextArticle from 'components/Feed/NextArticle';
import Fetcher from 'components/Fetcher';
import Flex from 'components/Flex';
import { Column, Container } from 'components/Grid';
import Iframe from 'components/Iframe';
import Img from 'components/Image';
import LanguageMessage from 'components/LanguageMessage';
import LanguageSelector from 'components/LanguageSelector';
import PageTitle from 'components/PageTitle';
import Parser from 'components/Parser';
import { Desktop, Mobile } from 'components/Responsive';
import SameTopic from 'components/sameTopic/SameTopic';
import Title from 'components/Title';
import EditLink from 'components/ViewArticle/EditLink';
import { ImageGallery } from 'componentsNew/ImageGallery';
import { isArticlePublishDateBeforeFaultyGADataDate } from 'componentsNew/PageViews';
import { ArticleMetaLabels } from 'pagesAvenue/common/ArticleList/ArticleMetaLabels';
import { ArticleTags } from 'pagesAvenue/common/ArticleList/ArticleTags';
import { Component } from 'react';
import { GAitemNotFound } from 'utils/analytics';
import { getIsPortraitHeroImage } from 'utils/cms';
import { createEmbedUrl } from 'utils/cms/parseVideoUrls';
import { getMyDivisionName } from 'utils/misc/getMyDivision';
import { getMySegmentName } from 'utils/misc/getMySegment';

import ContentLoader from '../ContentLoader';

const onError = (status, message) => {
  GAitemNotFound(status, message, 'blog');

  return <Error message={message} status={status} redirectOn404={true} />;
};

class Blog extends Component {
  state = {
    isAutoTranslateMessgeVisible: true,
  };

  resetLanguage = (response) => {
    const updatedLanguage = { locale: 'en', text: 'English' };
    this.onLanguageChange(updatedLanguage, response);
  };

  closeAutoTranslateMessage = () =>
    this.setState({ isAutoTranslateMessgeVisible: false });

  onLanguageChange = (updatedLanguage, response) => {
    const { translations } = response;
    const { history } = this.props;

    if (updatedLanguage && translations && translations.length > 0) {
      const selectedLanguage = translations.find(
        (item) => item.locale === updatedLanguage.locale
      );
      if (selectedLanguage) {
        history.push(`/feed/blog/${selectedLanguage.id}`);
        this.setState({ isAutoTranslateMessgeVisible: true });
        return true;
      }
    }
    return false;
  };

  getArticleIdForComments = ({ translations }, originalArticleId) => {
    let articleId = originalArticleId;
    const englishArticle = translations.find((item) => item.locale === 'en');
    return englishArticle ? englishArticle.id : articleId;
  };

  renderCommonArticleHeroImageAndVideo(response, isPortraitHeroImage) {
    const heroImageType = isPortraitHeroImage ? 'heroPortrait' : 'hero';
    return response.heroImage ? (
      <Img
        src={response.heroImage}
        alt={response.heroAltText}
        maxWidth={Number(response.heroImageWidth)}
        type={heroImageType}
        className={heroImageType === 'hero' ? 'hero__image' : null}
      />
    ) : response.heroVideo ? (
      <div className="m-b-6">
        <Iframe src={createEmbedUrl(response.heroVideo)} title="blog" />
      </div>
    ) : null;
  }

  renderCommonArticleHeroImageAndVideoMobilePortrait(
    response,
    isPortraitHeroImage
  ) {
    const heroImageType = isPortraitHeroImage ? 'heroPortrait' : 'hero';
    return response.heroImage ? (
      <div className="blog-post__hero-image-wrapper--portrait m-b-4">
        <Img
          src={response.heroImage}
          alt={response.heroAltText}
          maxWidth={Number(response.heroImageWidth)}
          type={heroImageType}
        />
      </div>
    ) : response.heroVideo ? (
      <div className="m-b-6">
        <Iframe src={createEmbedUrl(response.heroVideo)} title="blog" />
      </div>
    ) : null;
  }

  renderCommonArticleLabelsAndLanguage(
    response,
    languageSelectorClassNames,
    isDesktop = true
  ) {
    return (
      <>
        <UserConsumer>
          {(user) => {
            const {
              publishedInDivisions = [],
              publishedInSegments = [],
              publishedInCountries = [],
              publishedInDepartments = [],
              publishedInSites = [],
              publishedInCompanies = [],
              publishedDate,
              channels,
              type,
            } = response;

            return (
              <Stack direction="row" flexWrap="wrap">
                <ArticleMetaLabels
                  type={type}
                  publishedDate={publishedDate}
                  countries={
                    publishedInCountries.some(
                      (country) => country.id === user.countryId
                    )
                      ? [{ id: user.countryId, name: user.countryName }]
                      : []
                  }
                  divisions={
                    publishedInDivisions.some(
                      (division) => division.id === user.divisionId
                    )
                      ? [{ id: user.divisionId, name: user.divisionName }]
                      : []
                  }
                  departments={
                    publishedInDepartments.some(
                      (department) => department.id === user.departmentId
                    )
                      ? [{ id: user.departmentId, name: user.departmentName }]
                      : []
                  }
                  segments={
                    publishedInSegments.some(
                      (segment) => segment.id === user.segmentId
                    )
                      ? [{ id: user.segmentId, name: user.segmentName }]
                      : []
                  }
                  sites={
                    publishedInSites.some((site) => site.id === user.siteId)
                      ? [{ id: user.siteId, name: user.siteName }]
                      : []
                  }
                  companies={
                    publishedInCompanies.some(
                      (company) => company.id === user.companyId
                    )
                      ? [{ id: user.companyId, name: user.companyName }]
                      : []
                  }
                  sx={{ marginRight: '2rem', marginBottom: '0.5rem' }}
                />
                {channels && <ArticleTags tags={channels} />}
              </Stack>
            );
          }}
        </UserConsumer>
        {isDesktop && (
          <LanguageSelector
            className={languageSelectorClassNames}
            availableTranslations={response.translations}
            currentLocale={response.currentLocale}
            onLanguageChange={(updatedLanguage) =>
              this.onLanguageChange(updatedLanguage, response)
            }
          />
        )}
      </>
    );
  }

  renderCommonArticleContent(response, isPortraitHeroImage = false) {
    return (
      <div className="blog-post__post">
        <div className="content">
          <p className="preamble">{response.preamble}</p>
          {response.imageGalleryItems &&
            response.imageGalleryItems.length > 0 &&
            !isPortraitHeroImage && (
              <ImageGallery items={response.imageGalleryItems} />
            )}
          <div className="body">
            <Parser
              html={response.body}
              type="Feed"
              country={response.country}
              division={response.division}
            />
          </div>
        </div>
      </div>
    );
  }

  renderImageGalleryOnPortraitLayout(response) {
    return (
      <>
        {response.imageGalleryItems &&
          response.imageGalleryItems.length > 0 && (
            <ImageGallery items={response.imageGalleryItems} />
          )}
      </>
    );
  }

  renderPortraitLayout(response) {
    const isPortraitHeroImage = true;
    return (
      <>
        <Desktop>
          <Flex
            type="space-between"
            className="m-b-4 blog-post__labels-container--portrait"
          >
            {this.renderCommonArticleLabelsAndLanguage(response, 'm-b-2')}
          </Flex>
          <div className="blog-post__post-container--portrait">
            {this.renderCommonArticleHeroImageAndVideo(
              response,
              isPortraitHeroImage
            )}
            {this.renderCommonArticleContent(response, isPortraitHeroImage)}
          </div>
          {this.renderImageGalleryOnPortraitLayout(response)}
        </Desktop>
        <Mobile>
          {this.renderCommonArticleHeroImageAndVideoMobilePortrait(
            response,
            isPortraitHeroImage
          )}
          <Flex direction="row" type="space-between" className="m-b-3">
            {this.renderCommonArticleLabelsAndLanguage(
              response,
              'sm-m-t-0 sm-m-b-0',
              false
            )}
          </Flex>
          {this.renderCommonArticleContent(response, isPortraitHeroImage)}
          {this.renderImageGalleryOnPortraitLayout(response)}
        </Mobile>
      </>
    );
  }

  renderLandscapeLayout(response) {
    const isPortraitHeroImage = false;
    return (
      <>
        {this.renderCommonArticleHeroImageAndVideo(
          response,
          isPortraitHeroImage
        )}
        <Desktop>
          <Flex type="space-between" className="m-b-4">
            {this.renderCommonArticleLabelsAndLanguage(response, '')}
          </Flex>
        </Desktop>
        <Mobile>
          <Flex direction="column" align="start" className="m-b-3">
            {this.renderCommonArticleLabelsAndLanguage(
              response,
              'm-t-4',
              false
            )}
          </Flex>
        </Mobile>
        {this.renderCommonArticleContent(response, isPortraitHeroImage)}
      </>
    );
  }

  render() {
    const { match } = this.props;
    const { isAutoTranslateMessgeVisible } = this.state;
    return (
      <Fetcher
        source={getBlogPost}
        param={match.params.id}
        onLoading={ContentLoader}
        onError={onError}
      >
        {({ response }) => {
          const channelsExists =
            response.channels && response.channels.length > 0;

          return (
            <>
              {response.currentLocale !== 'en' &&
                isAutoTranslateMessgeVisible && (
                  <LanguageMessage
                    currentLocale={response.currentLocale}
                    onLanguageReset={() => this.resetLanguage(response)}
                    onClose={this.closeAutoTranslateMessage}
                  />
                )}
              <Container>
                <Column>
                  <Breadcrumbs
                    path="/feed"
                    includeHome={true}
                    items={[{ title: 'Feed' }, { title: response.title }]}
                  />
                </Column>
              </Container>
              <Container mobileColumnSpacing={true}>
                <Column span={2}>
                  <Box className="cms__article m-t-6 lg-m-t-0 p-t-4">
                    <BlogNewsHeader
                      url={match.url}
                      viewableByRole="AvenueBlogEditor"
                      response={response}
                      onLanguageChange={this.onLanguageChange}
                    />
                    <UserConsumer>
                      {({ divisionId, segmentId }) => (
                        <PageTitle
                          titles={[
                            'Blog',
                            response.publishedInDivisions &&
                            !response.publishedInGlobal
                              ? getMyDivisionName(
                                  response.publishedInDivisions,
                                  divisionId
                                )
                              : 'Global',
                            response.publishedInSegments &&
                              getMySegmentName(
                                response.publishedInSegments,
                                segmentId
                              ),
                            response.title,
                          ]}
                        />
                      )}
                    </UserConsumer>
                    <Title>{response.title}</Title>
                    <Mobile>
                      <EditLink
                        viewableByRole="AvenueBlogEditor"
                        url={match.url}
                      />
                    </Mobile>
                    {getIsPortraitHeroImage(response)
                      ? this.renderPortraitLayout(response)
                      : this.renderLandscapeLayout(response)}
                    <ArticleFooterAndComments
                      disableComments={response.disableComments}
                      created={response.publishedDate || response.createdOn}
                      informationOwner={response.informationOwner}
                      author={response.author}
                      editor={response.lastModifiedBy}
                      updated={response.modifiedOn}
                      articleId={response._doc}
                      articleIdForLikesCommentsAndViews={this.getArticleIdForComments(
                        response,
                        match.params.id
                      )}
                      title={response.title}
                      type={response.type}
                      additionalFeedbackReceivers={
                        response.additionalFeedbackReceivers
                      }
                      disablePageViews={
                        !isArticlePublishDateBeforeFaultyGADataDate(
                          response.publishedDate || response.createdOn
                        )
                      }
                    />
                  </Box>
                </Column>
                <Column>
                  <Box>
                    <Author
                      defaultAuthor={
                        response.guestAuthor || response.blogAuthor
                      }
                    />
                  </Box>
                </Column>
              </Container>
              <NextArticle />
              {channelsExists && (
                <Container>
                  <SameTopic
                    className="blog-post__same-topic-container"
                    locale={response.currentLocale}
                    channel={response.channels[0]}
                  />
                </Container>
              )}
            </>
          );
        }}
      </Fetcher>
    );
  }
}

export default Blog;
