import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import { KeyboardKeyEnum } from 'enums';
import { translations } from 'translations';

import { Icon } from './Icon/Icon';

type SearchFieldProps = {
  id: string;
  sx?: OutlinedInputProps['sx'];
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
  onClear: () => void;
};

const SearchField = ({
  id,
  sx,
  value,
  placeholder,
  onChange,
  onSubmit,
  onClear,
}: SearchFieldProps) => {
  return (
    <OutlinedInput
      id={id}
      size="small"
      value={value}
      placeholder={placeholder || `${translations.search}...`}
      onChange={(e) => onChange(e.currentTarget.value)}
      onKeyDown={(e) => {
        if (e.key === KeyboardKeyEnum.Enter) {
          onSubmit(value);
        }
      }}
      endAdornment={
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            paddingLeft: theme.spacing('xxs'),
            paddingRight: theme.spacing('xxs'),
          })}
        >
          {Boolean(value) && (
            <Link variant="caption" component="button" onClick={onClear}>
              {translations.clear}
            </Link>
          )}
          <IconButton
            size="small"
            aria-label={translations.search}
            sx={{ padding: 0, aspectRatio: 1 }}
            onClick={() => onSubmit(value)}
          >
            <Icon type="magnifyingGlass" color="secondary" size={20} />
          </IconButton>
        </Stack>
      }
      sx={[
        () => ({
          borderRadius: '20px',
          alignItems: 'stretch',
          '.MuiOutlinedInput-notchedOutline': {
            borderRadius: '20px',
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

export { SearchField };
