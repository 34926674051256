import { HeroBannerSize } from 'api/cms/HeroBanner/types';
import { useHomeBanner } from 'context';

import { BannerLarge } from './BannerLarge';
import { BannerMedium } from './BannerMedium';
import { BannerThirtyYearCelebration } from './BannerThirtyYearCelebration';

const elementId = 'home-banner';

const Banner = () => {
  const { bannerData, isCelebrationBannerActive } = useHomeBanner();

  if (isCelebrationBannerActive) {
    return <BannerThirtyYearCelebration />;
  }

  if (!bannerData) {
    return null;
  }

  const {
    size,
    title,
    description,
    linkUrl,
    linkButtonText,
    heroImage,
    heroAltText,
  } = bannerData;

  if (size === HeroBannerSize.Medium || !heroImage) {
    return (
      <BannerMedium
        id={elementId}
        title={title}
        description={description}
        linkUrl={linkUrl}
        linkText={linkButtonText}
      />
    );
  }

  return (
    <BannerLarge
      id={elementId}
      title={title}
      description={description}
      linkUrl={linkUrl}
      linkText={linkButtonText}
      imageUrl={heroImage}
      imageAltText={heroAltText}
    />
  );
};

export { Banner };
