export const getCleanSegmentName = (originalSegmentName: string) => {
  switch (originalSegmentName) {
    // Entrance systems
    case 'Business Segment Perimeter Security':
      return 'BSPS';
    case 'Business Segment Residential':
      return 'BSR';
    case 'Business Segment Pedestrian':
      return 'BSP';
    case 'Business Segment Industrial':
      return 'BSI';
    case 'ESD Central Units':
      return 'Central Units';
    // Global solutions
    case 'Production Group':
      return 'Production';
    case 'Marine Gr':
      return 'Marine';
    case 'Global Functions Group':
      return 'Global Functions';
    case 'Traka Gr':
      return 'Key and asset management';
    case 'Critical Infrastructure Gr':
      return 'Critical Infrastructure';
    case 'Hospitality Gr':
      return 'Hospitality';
    case 'Senior Care Gr':
      return 'Senior Care';
    case 'Construction Gr':
      return 'Construction';
    case 'Self Storage Gr':
      return 'Self Storage';
    default:
      return originalSegmentName;
  }
};

export const getOrginalSegmentName = (cleanSegmentName: string) => {
  switch (cleanSegmentName) {
    // Entrance systems
    case 'BSPS':
      return 'Business Segment Perimeter Security';
    case 'BSR':
      return 'Business Segment Residential';
    case 'BSP':
      return 'Business Segment Pedestrian';
    case 'BSI':
      return 'Business Segment Industrial';
    case 'Central Units':
      return 'ESD Central Units';
    // Global solutions
    case 'Production':
      return 'Production Group';
    case 'Marine':
      return 'Marine Gr';
    case 'Global Functions':
      return 'Global Functions Group';
    case 'Key and asset management':
      return 'Traka Gr';
    case 'Critical Infrastructure':
      return 'Critical Infrastructure Gr';
    case 'Hospitality':
      return 'Hospitality Gr';
    case 'Senior Care':
      return 'Senior Care Gr';
    case 'Construction':
      return 'Construction Gr';
    case 'Self Storage':
      return 'Self Storage Gr';
    default:
      return cleanSegmentName;
  }
};
