import List from '@mui/material/List';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { JobListingItem } from 'api/jobs/types';
import { AlertBar } from 'componentsNew';
import { useMemo } from 'react';
import { translations } from 'translations';

import { JobListError } from './JobListError';
import { JobListItem } from './JobListItem';
import { JobListSkeleton } from './JobListSkeleton';
import { JobListTotal } from './JobListTotal';

const elementId = 'job-list';

type JobListProps = {
  sx?: SxProps<Theme>;
  isLoading?: boolean;
  isError?: boolean;
  jobList: {
    items: JobListingItem[];
    total: number;
  } | null;
  selectedItemId?: number;
  pagination: {
    page: number;
    rowsPerPage: number;
  };
  onItemClick: (item: JobListingItem) => void;
  onPaginationChange: (newPagination: {
    page: number;
    rowsPerPage: number;
  }) => void;
};

const JobList = ({
  sx,
  isLoading,
  isError,
  jobList,
  selectedItemId,
  pagination,
  onItemClick,
  onPaginationChange,
}: JobListProps) => {
  const paginationPageCount = useMemo(() => {
    if (!jobList) {
      return;
    }
    return jobList.total % pagination.rowsPerPage > 0
      ? Math.trunc(jobList.total / pagination.rowsPerPage) + 1
      : jobList.total / pagination.rowsPerPage;
  }, [jobList, pagination.rowsPerPage]);

  const elements = useMemo(() => {
    if (isError) {
      return <JobListError />;
    }
    if (isLoading || !jobList) {
      return <JobListSkeleton />;
    }
    return (
      <>
        {jobList.total > 0 ? (
          <JobListTotal total={jobList.total} sx={{ height: '2rem' }} />
        ) : (
          <AlertBar
            open
            fullWidth
            type="information"
            title={translations.jobsTotalZeroTitle}
            text={translations.jobsTotalZeroText}
            sx={{ marginTop: '2rem' }}
          />
        )}
        <Typography variant="srOnly" component="h2">
          {translations.jobOpeningsTitle}
        </Typography>
        <List
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing('xs'),
          })}
        >
          {jobList.items.map((item, index) => (
            <JobListItem
              key={`${elementId}-item-${index}`}
              elementId={`${elementId}-item-${index}`}
              isSelected={item.id === selectedItemId}
              item={item}
              onClick={() => onItemClick(item)}
            />
          ))}
        </List>
      </>
    );
  }, [isError, isLoading, jobList, onItemClick, selectedItemId]);

  return (
    <Stack
      id={elementId}
      sx={[
        (theme) => ({ width: '100%', gap: theme.spacing('xxs') }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {elements}
      {jobList && jobList.total > pagination.rowsPerPage && (
        <Pagination
          variant="outlined"
          sx={(theme) => ({ margin: `${theme.spacing('md')} auto` })}
          count={paginationPageCount}
          page={pagination.page}
          disabled={isLoading}
          siblingCount={0}
          hidePrevButton={pagination.page === 1}
          hideNextButton={pagination.page === paginationPageCount}
          onChange={(_e, page) =>
            onPaginationChange({ page, rowsPerPage: pagination.rowsPerPage })
          }
        />
      )}
    </Stack>
  );
};

export { JobList };
