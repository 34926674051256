import './sameTopicItem.scss';

import { getChannel } from 'api/cms/topics';
import classNames from 'classnames';
import Button from 'components/Button';
import Error from 'components/Error';
import Fetcher from 'components/Fetcher';
import { ReactComponent as ArrowRight } from 'components/Icon/ArrowRight.svg';
import Img from 'components/Image';
import itemFallbackkImage from 'pagesAvenue/common/ArticleList/article-fallback-image-blog.jpg';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
// TODO: Should not use untyped packages in typescript files
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TextClamp from 'react-string-clamp';
import { GAitemNotFound } from 'utils/analytics';
import { getImageSrc, getIsPortraitHeroImage } from 'utils/cms';
import { getUrlLink } from 'utils/misc/getUrlLink';
import { getDateOrDaysAgo } from 'utils/misc/time';

import portraitBackground from './portrait-image-background.jpg';
import topicFallbackImage from './topic-fallback.png';

interface SameTopicItemProps {
  response: [
    {
      imageUrl: string;
      imageAltText: string;
      imageWidth: number;
      imageHeight: number;
      title: string;
      description: string;
      type: string;
      id: string;
      publishedUtc: string;
    }
  ];
  channel: string;
}

const onError = (status: number, message: string): ReactNode => {
  GAitemNotFound(status, message, 'channel');

  return <Error message={message} status={status} redirectOn404={true} />;
};

const portraitHero = (item: {
  imageUrl: string;
  imageWidth: number;
  imageHeight: number;
}) => {
  const isPortraitHeroImage: boolean = getIsPortraitHeroImage({
    heroImage: item.imageUrl,
    heroImageWidth: item.imageWidth,
    heroImageHeight: item.imageHeight,
  });
  return isPortraitHeroImage;
};

const SameTopicItem: FC<SameTopicItemProps> = ({ response, channel }) => {
  return (
    <div className="same-topic__container">
      {response.map((item, index) => {
        const classes = classNames(
          'same-topic__item-container',
          index > 0 ? 'same-topic__item-container--margin' : ''
        );
        const isPortraitImage = portraitHero(item);

        return (
          <div key={item.id} className={classes}>
            <div className="same-topic__image-wrapper">
              <div className="same-topic__gradient"></div>

              {item.imageUrl ? (
                isPortraitImage ? (
                  <div>
                    <div className="same-topic__original-image-wrapper">
                      <img
                        className="same-topic__original-image"
                        src={getImageSrc(item.imageUrl)}
                        alt={item.imageAltText || ''}
                        title={item.imageAltText || ''}
                      />
                    </div>
                    <div className="same-topic__portrait-wrapper">
                      <img
                        className="same-topic__portrait-image"
                        src={portraitBackground}
                        alt=""
                      />
                    </div>
                  </div>
                ) : (
                  <Img
                    className="same-topic__image"
                    src={item.imageUrl}
                    alt={item.imageAltText}
                  />
                )
              ) : (
                <Img src={itemFallbackkImage} alt="Earth from space" />
              )}
            </div>
            <div className="same-topic__text-container">
              <div className="same-topic__created-on">
                {getDateOrDaysAgo(item.publishedUtc)}
              </div>
              <div className="same-topic__topic-title">
                <TextClamp lines={3} text={item.title ? item.title : ''} />
              </div>
              <div className="same-topic__topic-preamble">
                <TextClamp
                  lines={2}
                  text={item.description ? item.description : ''}
                />
              </div>
            </div>
            <div className="same-topic__button-wrapper">
              <Button
                color="transparent-white"
                width="narrow"
                to={getUrlLink(item.type, item.id)}
              >
                Read more
              </Button>
            </div>
          </div>
        );
      })}

      <Fetcher source={getChannel} param={channel} onError={onError}>
        {({ response }: any) => {
          return (
            <div className="same-topic__last-item-container">
              <div className="same-topic__image-wrapper">
                <div className="same-topic__gradient"></div>
                {response.heroImage ? (
                  <Img
                    className="same-topic__image"
                    src={response.heroImage}
                    alt={response.heroAltText || ''}
                  />
                ) : (
                  <Img src={topicFallbackImage} alt="Earth from space" />
                )}
              </div>
              <div className="same-topic__text-container">
                <div className="same-topic__topic-header">
                  #
                  {channel.length > 15
                    ? `${channel.substring(0, 15)}...`
                    : channel}
                </div>
                <div className="same-topic__topic-description">
                  Find more {channel} related content{' '}
                  <Link className="same-topic__link" to={`/topics/${channel}`}>
                    here
                  </Link>
                  .
                </div>
              </div>

              <div>
                <Link className="same-topic__link" to={`/topics/${channel}`}>
                  <div className="same-topic__explore-topic-wrapper">
                    <span>Explore this topic</span>
                    <div className="same-topic__arrow-wrapper">
                      <ArrowRight />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          );
        }}
      </Fetcher>
    </div>
  );
};

export default SameTopicItem;
