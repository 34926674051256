import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as feedbackApi from 'api/feedback';
import { FeedbackRankingValue } from 'api/feedback/transformers';
import * as feedbackTransformers from 'api/feedback/transformers';
import { ProfileMini } from 'componentsNew';
import { useSnackbar } from 'context';
import { useCallback, useState } from 'react';
import { translations } from 'translations';
import { GAonFeedbackSent } from 'utils/analytics';
import makeQueryString from 'utils/misc/makeQueryString';

import { FeedbackRankingButton } from './FeedbackRankingButton';

type ArticleFeedbackProps = {
  articleId: string;
  articleTitle: string;
  createdDate?: string;
  modifiedDate?: string;
  informationOwnerId: string;
  informationOwnerName?: string;
  editorId?: string;
};

const ArticleFeedback = ({
  articleId,
  articleTitle,
  modifiedDate,
  createdDate,
  informationOwnerId,
  informationOwnerName,
  editorId,
}: ArticleFeedbackProps) => {
  const [rankingValue, setRankingValue] = useState<FeedbackRankingValue | null>(
    null
  );
  const [commentValue, setCommentValue] = useState<string>('');

  const [percentageByRankingValue, setPercentageByRankingValue] =
    useState<Record<FeedbackRankingValue, number> | null>(null);

  const [isCommentInputOpen, setIsCommentInputOpen] = useState<boolean>(false);

  const [isCommentInputLoading, setIsCommentInputLoading] =
    useState<boolean>(false);

  const { showSnackbar } = useSnackbar();

  const fetchArticleFeedback = useCallback(async () => {
    let result = null;
    try {
      const response = await feedbackApi.getFeedback(
        `article-${articleId}`,
        makeQueryString({
          filter: {
            ...((modifiedDate || createdDate) && {
              addedAfter: modifiedDate || createdDate,
            }),
          },
        })
      );
      result =
        feedbackTransformers.articleFeedbackResponseToArticleFeedbackSummary(
          response
        );
    } catch {}
    return result;
  }, [articleId, createdDate, modifiedDate]);

  const postFeedbackRanking = useCallback(
    async (value: FeedbackRankingValue) => {
      GAonFeedbackSent(value, articleTitle);

      try {
        await feedbackApi.sendFeedback(`article-${articleId}`, {
          helpful: value,
        });
      } catch {}
    },
    [articleId, articleTitle]
  );

  const postFeedbackComment = useCallback(
    async (value: string) => {
      let result = false;

      try {
        setIsCommentInputLoading(true);
        await feedbackApi.sendFeedback(`article-${articleId}`, {
          feedback: value,
          title: articleTitle,
          infoOwnerOid: informationOwnerId,
          editorId: editorId,
          lastEdited: modifiedDate,
          linkUrl: window.location.href,
        });
        result = true;
        showSnackbar({
          type: 'success',
          text: translations.articleFeedbackCommentSuccess,
        });
      } catch {
        showSnackbar({
          type: 'error',
          text: translations.articleFeedbackCommentError,
        });
      } finally {
        setIsCommentInputLoading(false);
      }
      return result;
    },
    [
      articleId,
      articleTitle,
      editorId,
      informationOwnerId,
      modifiedDate,
      showSnackbar,
    ]
  );

  const handleRankingClick = useCallback(
    async (ranking: FeedbackRankingValue) => {
      setRankingValue(ranking);
      setIsCommentInputOpen(true);
      await postFeedbackRanking(ranking);
      const articleFeedback = await fetchArticleFeedback();
      if (!articleFeedback) return;
      setPercentageByRankingValue(articleFeedback.rankingPercentage);
    },
    [fetchArticleFeedback, postFeedbackRanking]
  );

  const handleCommentSendClick = useCallback(async () => {
    const result = await postFeedbackComment(commentValue);
    if (result) setCommentValue('');
  }, [postFeedbackComment, commentValue]);

  return (
    <Stack rowGap={1}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        flexWrap="wrap"
        marginTop={-1}
      >
        <Stack flexGrow={1} marginTop={1} marginRight={1}>
          <Typography
            variant="body2"
            sx={(theme) => ({
              lineHeight: 1.5,
              fontWeight: 'bold',
              color: theme.colors.text.tertiary,
              marginBottom: theme.spacing('xs'),
            })}
          >
            {translations.informationOwner}
          </Typography>
          <ProfileMini
            profileId={informationOwnerId}
            profileName={informationOwnerName}
          />
        </Stack>
        <Stack marginTop={1}>
          <Typography
            variant="body2"
            sx={(theme) => ({
              lineHeight: 1.5,
              fontWeight: 'bold',
              color: theme.colors.text.tertiary,
              marginBottom: theme.spacing('xs'),
            })}
          >
            {translations.articleFeedbackRatingTitle}
          </Typography>
          <Stack
            sx={(theme) => ({
              flexDirection: 'row',
              columnGap: theme.spacing('xs'),
            })}
          >
            <FeedbackRankingButton
              value={FeedbackRankingValue.NotHelpful}
              active={rankingValue === FeedbackRankingValue.NotHelpful}
              onClick={handleRankingClick}
              {...(percentageByRankingValue && {
                label: `${
                  percentageByRankingValue[FeedbackRankingValue.NotHelpful]
                }%`,
              })}
            />
            <FeedbackRankingButton
              value={FeedbackRankingValue.Neutral}
              active={rankingValue === FeedbackRankingValue.Neutral}
              onClick={handleRankingClick}
              {...(percentageByRankingValue && {
                label: `${
                  percentageByRankingValue[FeedbackRankingValue.Neutral]
                }%`,
              })}
            />
            <FeedbackRankingButton
              value={FeedbackRankingValue.Helpful}
              active={rankingValue === FeedbackRankingValue.Helpful}
              onClick={handleRankingClick}
              {...(percentageByRankingValue && {
                label: `${
                  percentageByRankingValue[FeedbackRankingValue.Helpful]
                }%`,
              })}
            />
          </Stack>
        </Stack>
      </Stack>

      {isCommentInputOpen && (
        <>
          <Stack>
            <TextField
              id="article-feedback"
              size="small"
              multiline
              minRows={3}
              value={commentValue}
              disabled={isCommentInputLoading}
              placeholder={translations.articleFeedbackPlaceholder}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCommentValue(e.target.value)
              }
            />
          </Stack>
          <Button
            variant="contained"
            disabled={commentValue.trim() === ''}
            onClick={handleCommentSendClick}
            sx={{ alignSelf: 'end' }}
          >
            {isCommentInputLoading ? '...' : translations.send}
          </Button>
        </>
      )}
    </Stack>
  );
};

export { ArticleFeedback };
