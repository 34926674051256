import Stack from '@mui/material/Stack';
import { DatePicker } from 'componentsNew';
import * as dateHelpers from 'componentsNew/DatePickers/helpers';
import { FormFieldWrapper } from 'layout';

import { Consumer } from '..';

const DateRange = ({
  startDateLabel,
  endDateLabel,
  startDateFieldName = 'startDate',
  endDateFieldName = 'endDate',
  rangeLength = 0,
}) => {
  return (
    <Consumer>
      {({
        updatedArticle: {
          [startDateFieldName]: startDate,
          [endDateFieldName]: endDate,
        },
        onChangeArticle,
      }) => {
        const startDateAsDate = startDate ? new Date(startDate) : null;
        const endDateAsDate = endDate ? new Date(endDate) : null;

        return (
          <Stack
            sx={(theme) => ({
              gap: theme.spacing('sm'),
              flexDirection: { xs: 'column', md: 'row' },
              marginBottom: theme.spacing('md'),
            })}
          >
            <FormFieldWrapper
              id="date-range-start"
              label={startDateLabel}
              labelSize="small"
            >
              <DatePicker
                time="startOfDay"
                value={startDateAsDate}
                onChange={(value) => {
                  const newStartDate = value;

                  if (!newStartDate) {
                    if (rangeLength > 0) {
                      onChangeArticle({
                        [startDateFieldName]: null,
                        [endDateFieldName]: null,
                      });
                    } else {
                      onChangeArticle({
                        [startDateFieldName]: null,
                      });
                    }
                    return;
                  }

                  if (rangeLength > 0) {
                    let newEndDate = dateHelpers.addDays(
                      newStartDate,
                      rangeLength
                    );
                    newEndDate = dateHelpers.endOfUTCDay(newEndDate);
                    onChangeArticle({
                      [startDateFieldName]: newStartDate.toISOString(),
                      [endDateFieldName]: newEndDate.toISOString(),
                    });
                    return;
                  }

                  if (
                    endDate &&
                    new Date(endDate).getTime() < newStartDate.getTime()
                  ) {
                    onChangeArticle({
                      [startDateFieldName]: newStartDate.toISOString(),
                      [endDateFieldName]: null,
                    });
                    return;
                  }
                  onChangeArticle({
                    [startDateFieldName]: newStartDate.toISOString(),
                  });
                }}
              />
            </FormFieldWrapper>
            <FormFieldWrapper
              id="date-range-end"
              label={endDateLabel}
              labelSize="small"
            >
              <DatePicker
                time="endOfDay"
                value={endDateAsDate}
                disabled={rangeLength > 0}
                minDate={startDateAsDate}
                onChange={(value) => {
                  onChangeArticle({
                    [endDateFieldName]: value ? value.toISOString() : null,
                  });
                }}
              />
            </FormFieldWrapper>
          </Stack>
        );
      }}
    </Consumer>
  );
};

export default DateRange;
