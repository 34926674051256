import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import {
  CommentButton,
  LikeButton,
  TypographyWithMaxLines,
} from 'componentsNew';
import { ArticleContentTypeEnum } from 'enums';
import {
  ArticleImage,
  ArticleMetaLabels,
  ArticleTags,
  ArticleTitle,
  ArticleType,
} from 'pagesAvenue/common';
import type { ArticlePreview } from 'pagesAvenue/common/ArticleList/ArticleList';
import { getUrlLink } from 'utils/misc/getUrlLink';

const elementId = 'home-featured-articles';

type FeaturedArticlesProps = {
  articles: ArticlePreview[];
  onLikeClick: (article: ArticlePreview) => void;
  onCommentClick: (article: ArticlePreview) => void;
  onArticleClick: (article: ArticlePreview) => void;
};

const FeaturedArticles = ({
  articles,
  onLikeClick,
  onCommentClick,
  onArticleClick,
}: FeaturedArticlesProps) => {
  const theme = useTheme();

  if (!articles.length) {
    return null;
  }
  return (
    <Stack
      id={elementId}
      direction={{ xs: 'column', lg: 'row' }}
      spacing={theme.spacing('xxxs')}
      alignItems="stretch"
    >
      {articles.map((article, index) => {
        const articleLink = getUrlLink(article.type, article.id) || '/';

        const isPortraitImage =
          Boolean(article.imageUrl) &&
          (article.imageHeight || 0) > (article.imageWidth || 0);

        const showLikes = article.type !== ArticleContentTypeEnum.Press;
        const showComments =
          article.type !== ArticleContentTypeEnum.Press &&
          !article.disableComments;

        return (
          <Stack
            key={article.id}
            id={`${elementId}-item-${index}`}
            sx={(theme) => ({
              flex: {
                xs: '1 1 1',
                lg: '1 1 0',
              },
              height: {
                xs: 'auto',
                sm: '25rem',
              },
              position: 'relative',
              overflow: 'hidden',
              backgroundColor: theme.colors.surface.inversePrimary,
              borderRadius: theme.border.radius.md,
            })}
          >
            <ArticleImage
              to={articleLink}
              type={article.type}
              src={article.imageUrl}
              altText={article.imageAltText}
              isPortrait={isPortraitImage}
              videoPlaySymbolSize={6}
              onClick={() => onArticleClick(article)}
              sx={{
                width: '100%',
                position: 'absolute',
                aspectRatio: 16 / 9,
                height: {
                  lg:
                    isPortraitImage && articles.length === 1 ? '100%' : 'unset',
                },
              }}
            />
            <Box
              sx={{
                width: '100%',
                aspectRatio: 16 / 9,
                minHeight: '0',
              }}
            />
            <Stack
              sx={(theme) => ({
                width: '100%',
                alignItems: 'baseline',
                flexGrow: 1,
                backdropFilter: 'blur(2px)',
                background:
                  'linear-gradient(190deg,rgba(92,92,92,.1) 0%,rgba(26,26,26,.85) 60%)',
                WebkitBackdropFilter: 'blur(2px)',
                padding: `${theme.spacing('sm')} ${theme.spacing('xs')}`,
              })}
            >
              <ArticleType
                type={article.type}
                color={theme.colors.text.inversePrimary}
                sx={(theme) => ({
                  position: 'absolute',
                  padding: `0.125rem  0.375rem`,
                  borderRadius: theme.border.radius.xs,
                  top: '-1.2rem',
                  backgroundColor: theme.colors.surface.inverseSecondary,
                })}
              />
              <ArticleTitle
                to={articleLink}
                title={article.title}
                color={theme.colors.text.inversePrimary}
                onClick={() => onArticleClick(article)}
              />
              <ArticleMetaLabels
                type={article.type}
                author={article.author}
                countries={article.country ? [article.country] : []}
                divisions={article.division ? [article.division] : []}
                departments={article.department ? [article.department] : []}
                segments={article.segment ? [article.segment] : []}
                sites={article.site ? [article.site] : []}
                companies={article.company ? [article.company] : []}
                publishedDate={article.publishedUtc}
                color="inversePrimary"
                containsImageGallery={article.containsImageGallery}
                sx={(theme) => ({ marginBottom: theme.spacing('xxs') })}
              />
              {article.description && (
                <TypographyWithMaxLines
                  maxLines={1}
                  variant="caption"
                  sx={(theme) => ({
                    color: theme.colors.text.inversePrimary,
                    marginBottom: theme.spacing('xxs'),
                  })}
                >
                  {article.description}
                </TypographyWithMaxLines>
              )}
              <Stack
                sx={() => ({
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'end',
                  marginTop: 'auto',
                  width: '100%',
                })}
              >
                <ArticleTags
                  tags={article.tags}
                  color={theme.colors.text.inversePrimary}
                />
                {(showLikes || showComments) &&
                  article.likesAndCommentsMeta && (
                    <Stack
                      sx={() => ({
                        flexDirection: 'row',
                        marginLeft: theme.spacing('xs'),
                      })}
                    >
                      {showLikes && (
                        <LikeButton
                          id={`${elementId}-item-${index}-like`}
                          isLikedByMe={article.likesAndCommentsMeta.isLikedByMe}
                          likesCount={article.likesAndCommentsMeta.likesCount}
                          color="inversePrimary"
                          sx={(theme) => ({
                            marginRight: showComments ? theme.spacing('xs') : 0,
                          })}
                          onClick={() => onLikeClick(article)}
                        />
                      )}
                      {showComments && (
                        <CommentButton
                          id={`${elementId}-item-${index}-comment`}
                          isCommentedByMe={
                            article.likesAndCommentsMeta.isCommentedByMe
                          }
                          commentsCount={
                            article.likesAndCommentsMeta.commentsCount
                          }
                          color="inversePrimary"
                          to={articleLink}
                          onClick={() => onCommentClick(article)}
                        />
                      )}
                    </Stack>
                  )}
              </Stack>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export { FeaturedArticles };
