import './Footer.scss';

import Typography from '@mui/material/Typography';
import Flex from 'components/Flex';
import { Column, Container } from 'components/Grid';
import { useEffect, useState } from 'react';
import { GAonOutboundButtonClick } from 'utils/analytics';

import { ReactComponent as AssaabloyText } from './assets/assaabloy.svg';
import { ReactComponent as FacebookIcon } from './assets/facebook.svg';
import { ReactComponent as InstagramIcon } from './assets/instagram.svg';
import { ReactComponent as LinkedinIcon } from './assets/linkedin.svg';
import { ReactComponent as YoutubeIcon } from './assets/youtube.svg';
import FooterDesktopLinksContainer from './ContentContainer';
import LinkItem from './LinkItem';
import SocialItem from './SocialItem';

const Footer = () => {
  const [isPublicPage, setIsPublicPage] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes('/public')) {
      setIsPublicPage(true);
    }
  }, []);

  return (
    <footer className="footer" id="avenue-footer">
      <Typography variant="srOnly" component="h2">
        More about Avenue and ASSA ABLOY
      </Typography>
      {!isPublicPage && <FooterDesktopLinksContainer />}
      <Container
        className={`mobile-center ${isPublicPage ? 'public-page' : ''}`}
        verticalAlign={true}
      >
        <Column>
          <Flex responsive={true} type="space-between">
            <Flex type="space-between" tag="ul" className="footer__icons">
              <SocialItem
                href="http://www.youtube.com/assaabloy/"
                name="YouTube"
              >
                <YoutubeIcon />
              </SocialItem>
              <SocialItem
                href="http://www.facebook.com/assaabloy/"
                name="Facebook"
              >
                <FacebookIcon />
              </SocialItem>
              <SocialItem
                href="http://www.linkedin.com/company/assaabloy/"
                name="LinkedIn"
              >
                <LinkedinIcon />
              </SocialItem>
              <SocialItem
                href="https://www.instagram.com/assaabloygroup/"
                name="Instagram"
              >
                <InstagramIcon />
              </SocialItem>
            </Flex>
            <ul
              className={`footer__links ${isPublicPage ? 'public-page' : ''}`}
            >
              <LinkItem
                href="https://assaabloy.service-now.com/sp"
                text="IT support"
                onClick={() => GAonOutboundButtonClick('Click', 'IT Support')}
                outbound={true}
                className={` ${isPublicPage ? 'public-page' : ''} `}
              />
              <LinkItem
                className={` ${isPublicPage ? 'public-page' : ''} `}
                href="/public/faq"
                text="FAQ"
              />
              <div className="footer__links-bottom-row">
                <LinkItem
                  className={`footer__item--no-border-mobile ${
                    isPublicPage ? 'public-page' : ''
                  } `}
                  href="/public/about-cookies"
                  text="About cookies"
                />
                <LinkItem
                  className={` ${isPublicPage ? 'public-page' : ''} `}
                  href="/public/terms-of-use"
                  text="Terms of use"
                />
              </div>
            </ul>
            <AssaabloyText className="footer__assa-text" />
            <span className="footer__copyright">&copy; ASSA ABLOY</span>
          </Flex>
        </Column>
      </Container>
    </footer>
  );
};

export default Footer;
