import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import OnlyWithRole from 'components/OnlyWithRole';
import { translations } from 'translations';

const elementId = 'product-of-the-week-gallery-header';

type ProductOfTheWeekGalleryHeaderProps = {
  isLoading: boolean;
  filter: {
    isAdmin: boolean;
  };
  onFilterChange: (filter: { isAdmin: boolean }) => void;
};

const ProductOfTheWeekGalleryHeader = ({
  isLoading,
  filter,
  onFilterChange,
}: ProductOfTheWeekGalleryHeaderProps) => {
  return (
    <Stack
      sx={(theme) => ({
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: theme.colors.surface.secondary,
        padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
        borderBottom: `1px solid ${theme.colors.border.surfacePrimary}`,
        borderTopLeftRadius: theme.border.radius.md,
        borderTopRightRadius: theme.border.radius.md,
      })}
    >
      <Typography
        variant="h1"
        sx={(theme) => ({
          color: theme.colors.text.brand,
        })}
      >
        {translations.productOfTheWeekGalleryTitle}
      </Typography>
      <OnlyWithRole
        viewableByRole={['AvenueAdmin', 'AvenueSuperuser']}
        isAllRolesRequired={false}
      >
        <FormControlLabel
          label={translations.admin}
          labelPlacement="start"
          sx={{ alignSelf: 'flex-end' }}
          control={
            <Switch
              id={`${elementId}-admin`}
              checked={filter.isAdmin}
              disabled={isLoading}
              onChange={(e) => onFilterChange({ isAdmin: e.target.checked })}
            />
          }
        />
      </OnlyWithRole>
    </Stack>
  );
};

export { ProductOfTheWeekGalleryHeader };
