import * as organizationApi from 'api/organization/index';
import { DivisionsTree } from 'api/organization/types';
import React, { useCallback, useContext, useMemo, useState } from 'react';

const OrganizationDataContext = React.createContext<{
  getDivisionsTree: () => Promise<DivisionsTree | null>;
  divisionsTree: DivisionsTree | null;
  isError: boolean;
  isLoading: boolean;
} | null>(null);

const { Consumer: OrganizationDataConsumer } = OrganizationDataContext;

type OrganizationDataProviderProps = {
  children: React.ReactNode;
};

export function OrganizationDataProvider({
  children,
}: OrganizationDataProviderProps) {
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [divisionsTree, setDivisionsTree] = useState<DivisionsTree | null>(
    null
  );

  const getDivisionsTree = useCallback(async () => {
    if (divisionsTree) return divisionsTree;
    let result: DivisionsTree | null = null;

    try {
      const response = await organizationApi.getDivisionsTree();

      if (!response.data.data) throw Error();
      result = response.data.data as DivisionsTree;
      setDivisionsTree(result);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
      return result;
    }
  }, [divisionsTree]);

  const contextValue = useMemo(
    () => ({
      getDivisionsTree,
      divisionsTree,
      isError,
      isLoading,
    }),
    [getDivisionsTree, divisionsTree, isError, isLoading]
  );

  return (
    <OrganizationDataContext.Provider value={contextValue}>
      {children}
    </OrganizationDataContext.Provider>
  );
}

export const useOrganizationData = () => {
  const context = useContext(OrganizationDataContext);
  if (!context) {
    throw new Error('OrganizationDataContext is not defined');
  }
  return context;
};

export { OrganizationDataConsumer };
