import './Edit.scss';

import Typography from '@mui/material/Typography';
import * as API from 'api/cms/news';
import {
  ENTRANCE_SYSTEMS_DIVISION_ID,
  GLOBAL_SOLUTIONS_DIVISION_ID,
} from 'api/organization/transformers';
import classNames from 'classnames';
import { AdditionalFeedbackReceivers } from 'components/AdditionalFeedbackReceivers/AdditionalFeedbackReceivers';
import Box from 'components/Box';
import Checkbox from 'components/Checkbox';
import { UserConsumer } from 'components/Context/User';
import CreateOrEdit, { Consumer } from 'components/CreateOrEditArticle';
import { ArticleInformationOwner } from 'components/CreateOrEditArticle/components/ArticleInformationOwner';
import ArticleMeta from 'components/CreateOrEditArticle/components/ArticleMeta';
import Body from 'components/CreateOrEditArticle/components/Body';
import Breadcrumbs from 'components/CreateOrEditArticle/components/Breadcrumbs';
import Channels from 'components/CreateOrEditArticle/components/Channels';
import ContentLabels from 'components/CreateOrEditArticle/components/ContentLabels';
import DisableComments from 'components/CreateOrEditArticle/components/DisableComments';
import Documents from 'components/CreateOrEditArticle/components/Documents';
import GoodToKnow from 'components/CreateOrEditArticle/components/GoodToKnow';
import GoodToKnowTitle from 'components/CreateOrEditArticle/components/GoodToKnowTitle';
import Hero from 'components/CreateOrEditArticle/components/Hero';
import { ImageGallery } from 'components/CreateOrEditArticle/components/ImageGallery';
import Preamble from 'components/CreateOrEditArticle/components/Preamble';
import PublishOptionsOnly from 'components/CreateOrEditArticle/components/PublishOptionsOnly';
import PublishSelector from 'components/CreateOrEditArticle/components/PublishSelector';
import ScreenSelector from 'components/CreateOrEditArticle/components/ScreenSelector';
import Title from 'components/CreateOrEditArticle/components/Title';
import { PublishDateFifteenMinutesStep } from 'components/CreateOrEditArticle/PublishDateFifteenMinutesStep';
import { maxLengthValidator } from 'components/CreateOrEditArticle/validators';
import { Column, Container } from 'components/Grid';
import { ReactComponent as ArrowLongLeft } from 'components/Icon/ArrowLongLeft.svg';
import { ReactComponent as ChevronRight } from 'components/Icon/ChevronRight.svg';
import OnlyWithRole from 'components/OnlyWithRole';
import Radio from 'components/Radio';
import { useRef, useState } from 'react';
import { gaOnPublishNewsBlogStory } from 'utils/analytics';
import { getIsPortraitHeroImage } from 'utils/cms';

import ContentLoader from '../ContentLoader';

const validateDivisions = ({ publishedInDivisions, publishedInGlobal }) => {
  if (publishedInDivisions || publishedInGlobal) {
    return null;
  }

  return 'Target audience';
};

const afterNewsCreated = (updatedArticle, article, isCreating, history) => {
  if (article.isDraft && !updatedArticle.isDraft) {
    gaOnPublishNewsBlogStory(updatedArticle.id);
  } else if (isCreating && !updatedArticle.isDraft) {
    gaOnPublishNewsBlogStory(updatedArticle.id);
  }

  return history.push(`/feed/news/${updatedArticle.id}`);
};

const fields = [
  { key: 'title', required: true, displayName: 'Title', maxLength: 90 },
  { key: 'preamble', fieldValidator: maxLengthValidator(300), maxLength: 300 },
  { key: 'body' },
  { key: 'goodToKnowTitle', maxLength: 90, defaultValue: 'Good to know' },
  {
    key: 'goodToKnowBody',
    fieldValidator: maxLengthValidator(
      550,
      'You have exceeded the maximum number of characters for ' +
        'the Good to know box.\nMaximum number of characters is 550.',
      true
    ),
    maxLength: 550,
  },
  { key: 'imageGalleryItems' },
  { key: 'sharepointFolderName', defaultValue: null },
  { key: 'publishedDate', defaultValue: new Date().toISOString() },
  {
    key: 'publishedInGlobal',
    intermediate: true,
    defaultValue: false,
    getIntermediateDefault: ({ publishedInDivisions }) => !publishedInDivisions,
  },
  {
    key: 'publishedInDivisions',
    validator: validateDivisions,
  },
  { key: 'publishedInSegments' },
  { key: 'publishedInCountries' },
  { key: 'publishedInDepartments' },
  { key: 'publishedInSites' },
  { key: 'publishedInCompanies' },
  { key: 'heroAltText' },
  { key: 'heroImage' },
  { key: 'heroImageWidth' },
  { key: 'heroImageHeight' },
  { key: 'heroVideo' },
  { key: 'channels', required: true, displayName: 'Topics' },
  { key: 'disableComments', defaultValue: false },
  { key: 'isPriority' },
  { key: 'isDraft', defaultValue: false },
  { key: 'screenEnabled', defaultValue: false },
  { key: 'screenSummary' },
  { key: 'informationOwner' },
  { key: 'additionalFeedbackReceivers' },
];

const NewsEdit = ({ match, history, location }) => {
  const [wizardPage, setWizardPage] = useState(0);
  const [targetingState, setTargetingState] = useState(null);
  const contentViewRef = useRef(null);
  const targetingViewRef = useRef(null);

  const isCreating = match.params.type === 'create';

  const wizardContentViewCircleClassName = classNames('news__wizard--circle', {
    'news__wizard--circle--active': wizardPage === 0,
  });

  const wizardContentViewTextClassName = classNames('news__wizard--text', {
    'news__wizard--text--active': wizardPage === 0,
  });

  const wizardTargetingViewCircleClassName = classNames(
    'news__wizard--circle',
    {
      'news__wizard--circle--active': wizardPage === 1,
    }
  );

  const wizardTargetingViewTextClassName = classNames('news__wizard--text', {
    'news__wizard--text--active': wizardPage === 1,
  });

  const updateWizardPage = (value, currentRef, nextRef) => {
    // Go to wizard page one
    if (value === 0) {
      currentRef.current.classList.remove(
        'article-editor__targetingview--active'
      );
      nextRef.current.classList.add('article-editor__content--active');
      setWizardPage(value);
    }
    // Go to wizard page two and scroll to top
    else {
      currentRef.current.classList.remove('article-editor__content--active');
      nextRef.current.classList.add('article-editor__targetingview--active');
      setWizardPage(value);
      window.scrollTo(0, 0);
    }
  };

  const onSetTargetingState = (radio, userContext, onChangeArticle) => {
    if (radio === null) {
      return;
    }

    // Selecting global
    if (radio.name === 'targetingview-radio-global') {
      onChangeArticle({
        publishedInGlobal: true,
        publishedInDivisions: null,
        publishedInCountries: null,
        publishedInSites: null,
      });
      setTargetingState(0);
    }
    // Selecting divisions
    if (radio.name === 'targetingview-radio-division') {
      onChangeArticle({
        publishedInGlobal: false,
        publishedInDivisions: [
          {
            id: userContext.divisionId,
            name: userContext.divisionName,
          },
        ],
        publishedInSegments: null,
        publishedInCountries: null,
        publishedInSites: null,
      });
      setTargetingState(1);
    }
    // Selecting segment
    if (radio.name === 'targetingview-radio-segment') {
      onChangeArticle({
        publishedInGlobal: false,
        publishedInDivisions: [
          {
            id: userContext.divisionId,
            name: userContext.divisionName,
          },
        ],
        publishedInSegments: [
          {
            id: userContext.segmentId,
            name: userContext.segmentName,
          },
        ],
        publishedInCountries: null,
        publishedInSites: null,
      });
      setTargetingState(5);
    }
    // Selecting division and country
    if (radio.name === 'targetingview-radio-division-country') {
      onChangeArticle({
        publishedInGlobal: false,
        publishedInDivisions: [
          {
            id: userContext.divisionId,
            name: userContext.divisionName,
          },
        ],
        publishedInCountries: [
          {
            id: userContext.countryId,
            name: userContext.countryName,
          },
        ],
        publishedInSites: null,
        publishedInSegments: null,
      });
      setTargetingState(2);
    }
    // Selecting site
    if (radio.name === 'targetingview-radio-site') {
      onChangeArticle({
        publishedInGlobal: true,
        publishedInDivisions: null,
        publishedInCountries: null,
        publishedInSites: [
          {
            id: userContext.siteId,
            name: userContext.siteName,
          },
        ],
        publishedInSegments: null,
      });
      setTargetingState(3);
    }
    // Selecting customize
    if (radio.name === 'targetingview-radio-customize') {
      onChangeArticle({
        publishedInGlobal: false,
        publishedInDivisions: null,
        publishedInCountries: null,
        publishedInSites: null,
      });
      setTargetingState(4);
    }
  };

  const preSelectEditRadioButton = (userContext, updatedArticle) => {
    if (userContext === null || updatedArticle === null) {
      return;
    }

    if (
      updatedArticle.publishedInDepartments ||
      (updatedArticle.publishedInSegments &&
        updatedArticle.publishedInSegments.some(
          (segment) => segment.id !== userContext.segmentId
        )) ||
      updatedArticle.publishedInCompanies ||
      (updatedArticle.publishedInDivisions &&
        updatedArticle.publishedInDivisions.some(
          (division) => division.id !== userContext.divisionId
        ))
    ) {
      setTargetingState(4);
      return;
    }

    // We have sites and global only
    if (
      updatedArticle.publishedInSites &&
      updatedArticle.publishedInSites.length === 1 &&
      updatedArticle.publishedInGlobal
    ) {
      if (userContext.siteId === updatedArticle.publishedInSites[0].id) {
        setTargetingState(3);
        return;
      }
    }
    // Country and division only
    if (
      updatedArticle.publishedInCountries &&
      updatedArticle.publishedInCountries.length === 1 &&
      updatedArticle.publishedInDivisions &&
      updatedArticle.publishedInDivisions.length === 1 &&
      !updatedArticle.publishedInSites
    ) {
      if (
        userContext.countryId === updatedArticle.publishedInCountries[0].id &&
        userContext.divisionId === updatedArticle.publishedInDivisions[0].id
      ) {
        setTargetingState(2);
        return;
      }
    }

    // Division and Segment
    if (
      updatedArticle.publishedInDivisions &&
      updatedArticle.publishedInSegments &&
      !updatedArticle.publishedInSites &&
      !updatedArticle.publishedInCountries
    ) {
      if (
        userContext.divisionId === updatedArticle.publishedInDivisions[0].id &&
        userContext.segmentId === updatedArticle.publishedInSegments[0].id
      ) {
        setTargetingState(5);
        return;
      }
    }

    // Division only
    if (
      updatedArticle.publishedInDivisions &&
      !updatedArticle.publishedInSites &&
      !updatedArticle.publishedInCountries
    ) {
      if (
        userContext.divisionId === updatedArticle.publishedInDivisions[0].id
      ) {
        setTargetingState(1);
        return;
      }
    }

    // Global only
    if (
      updatedArticle.publishedInGlobal &&
      !updatedArticle.publishedInSites &&
      !updatedArticle.publishedInCountries
    ) {
      setTargetingState(0);
      return;
    }

    // Use customize as default for editing
    else {
      setTargetingState(4);
    }
  };

  const formatDivisionName = (divisionName) => {
    if (divisionName.toLowerCase().includes('division')) {
      const regex = new RegExp('division', 'gi');

      return divisionName.replace(regex, '');
    }
    return divisionName;
  };

  return (
    <CreateOrEdit
      isCreating={isCreating}
      viewableByRole="AvenueGlobalEditor"
      pageTitle="News"
      location={location}
      fields={fields}
      ContentLoader={ContentLoader}
      onFetch={() => API.getArticle(match.params.id)}
      onDelete={API.deleteArticle}
      onCreate={API.createArticle}
      onEdit={API.updateArticle}
      onAfterCreate={(updatedArticle, article) =>
        afterNewsCreated(updatedArticle, article, isCreating, history)
      }
      onAfterDelete={() => history.push(`/feed`)}
      onAfterCancel={(news) =>
        isCreating
          ? history.push(`/feed`)
          : history.push(`/feed/news/${news._doc}`)
      }
      deleteLoadingText="Unpublishing article..."
      deleteSuccessText="Article has been unpublished"
      deleteFailureText="Could not unpublish article"
    >
      <Breadcrumbs subject="news" />
      <Container className="ie-flex-fix">
        <Column>
          <div className="news__wizard">
            <div className={wizardContentViewCircleClassName}>
              <div className="news__wizard--circle--number">1</div>
            </div>
            <div className={wizardContentViewTextClassName}>
              Create your content
            </div>
            <div className="news__wizard--chevron">
              <ChevronRight />
            </div>
            <div className={wizardTargetingViewCircleClassName}>
              <div className="news__wizard--circle--number">2</div>
            </div>
            <div className={wizardTargetingViewTextClassName}>Publish</div>
          </div>
        </Column>
      </Container>

      <div
        className="article-editor__content article-editor__content--active"
        ref={contentViewRef}
      >
        <Container className="ie-flex-fix">
          <Column>
            <Box className="m-t-6 m-b-9 lg-m-t-0">
              <Title placeholder="Article title (max 90 characters)" />
              <Consumer>
                {({ updatedArticle, isFirstWizardPageValidated }) => {
                  let articleEditorClassNames = classNames(
                    'article-editor__content-container',
                    {
                      'article-editor__content-container--portrait-mode':
                        getIsPortraitHeroImage(updatedArticle),
                    }
                  );
                  return (
                    <>
                      <div className={articleEditorClassNames}>
                        <div className="article-editor__hero-content">
                          <Hero
                            useVideo={true}
                            allowPortraitMode={true}
                            useAltText={true}
                          />
                          <ContentLabels />
                        </div>
                        <div className="article-editor__content-wrapper">
                          <div className="max-width article-editor__text-content">
                            <Preamble placeholder="Article preamble (max 300 characters)" />
                            <ImageGallery />
                            <Body placeholder="Article body" form="news" />
                          </div>
                          {!getIsPortraitHeroImage(updatedArticle) && (
                            <div className="max-width article-editor__good-to-know">
                              <GoodToKnowTitle
                                placeholder="Good to know title"
                                editable
                              />
                              <GoodToKnow placeholder="Good to know body (max 550 characters)" />
                            </div>
                          )}
                        </div>
                      </div>
                      <ArticleMeta />
                      <hr className="m-t-6 m-b-6" />
                      <Documents />
                      <hr className="m-t-6 m-b-6" />
                      <div className="article-editor__content__button">
                        <button
                          onClick={() => {
                            if (isFirstWizardPageValidated(updatedArticle)) {
                              updateWizardPage(
                                1,
                                contentViewRef,
                                targetingViewRef
                              );
                            }
                          }}
                          className="article-editor__content__button--next"
                          id="article-editor__content__button--next"
                        >
                          Next
                        </button>
                      </div>
                    </>
                  );
                }}
              </Consumer>
            </Box>
          </Column>
        </Container>
      </div>

      <div className="article-editor__targetingview" ref={targetingViewRef}>
        <Container>
          <Column>
            <Box className="m-b-9">
              <>
                <div className="article-editor__targetingview__button">
                  <button
                    onClick={() =>
                      updateWizardPage(0, targetingViewRef, contentViewRef)
                    }
                    className="article-editor__targetingview__button--back"
                    id="article-editor__targetingview__button--back"
                  >
                    <ArrowLongLeft /> Back
                  </button>
                </div>

                <Consumer>
                  {({
                    updatedArticle,
                    onChangeArticle,
                    onToggleArticleValue,
                    article,
                  }) => {
                    const isDraft = updatedArticle.isDraft;
                    return (
                      <>
                        <UserConsumer>
                          {(userContext) => (
                            <>
                              {!isCreating && targetingState === null
                                ? preSelectEditRadioButton(
                                    userContext,
                                    updatedArticle
                                  )
                                : null}
                              <h3 className="article-editor__targetingview--select-topic">
                                Choose your target audience
                              </h3>
                              <div className="article-editor__targetingview--select-container">
                                <OnlyWithRole viewableByRole="AvenueSuperuser">
                                  <Radio
                                    checked={targetingState === 0}
                                    id="toggle-targetingview-option-1"
                                    onChange={(radio) =>
                                      onSetTargetingState(
                                        radio,
                                        userContext,
                                        onChangeArticle
                                      )
                                    }
                                    label="Global"
                                    name="targetingview-radio-global"
                                    value="global"
                                  />
                                </OnlyWithRole>

                                {userContext.divisionId && (
                                  <Radio
                                    checked={targetingState === 1}
                                    id="toggle-targetingview-option-2"
                                    onChange={(radio) =>
                                      onSetTargetingState(
                                        radio,
                                        userContext,
                                        onChangeArticle
                                      )
                                    }
                                    label={formatDivisionName(
                                      userContext.divisionName
                                    )}
                                    name="targetingview-radio-division"
                                    value="division"
                                  />
                                )}
                                {userContext.segmentId &&
                                  (userContext.divisionId ===
                                    ENTRANCE_SYSTEMS_DIVISION_ID ||
                                    userContext.divisionId ===
                                      GLOBAL_SOLUTIONS_DIVISION_ID) && (
                                    <Radio
                                      checked={targetingState === 5}
                                      id="toggle-targetingview-option-5"
                                      onChange={(radio) =>
                                        onSetTargetingState(
                                          radio,
                                          userContext,
                                          onChangeArticle
                                        )
                                      }
                                      label={`${formatDivisionName(
                                        userContext.divisionName
                                      )} ${userContext.segmentName}`}
                                      name="targetingview-radio-segment"
                                      value="segment"
                                    />
                                  )}
                                {userContext.countryName &&
                                  userContext.divisionId &&
                                  userContext.countryId && (
                                    <Radio
                                      checked={targetingState === 2}
                                      id="toggle-targetingview-option-3"
                                      onChange={(radio) =>
                                        onSetTargetingState(
                                          radio,
                                          userContext,
                                          onChangeArticle
                                        )
                                      }
                                      label={`${formatDivisionName(
                                        userContext.divisionName
                                      )} ${userContext.countryName}`}
                                      name="targetingview-radio-division-country"
                                      value="division-country"
                                    />
                                  )}
                                {userContext.siteName && (
                                  <Radio
                                    checked={targetingState === 3}
                                    id="toggle-targetingview-option-4"
                                    onChange={(radio) =>
                                      onSetTargetingState(
                                        radio,
                                        userContext,
                                        onChangeArticle
                                      )
                                    }
                                    label={`${userContext.siteName}`}
                                    name="targetingview-radio-site"
                                    value="site"
                                  />
                                )}
                                <Radio
                                  checked={targetingState === 4}
                                  id="toggle-targetingview-option-5"
                                  onChange={(radio) =>
                                    onSetTargetingState(
                                      radio,
                                      userContext,
                                      onChangeArticle
                                    )
                                  }
                                  label="Customize"
                                  value="customize"
                                  name="targetingview-radio-customize"
                                />
                              </div>
                              {targetingState === 4 ? (
                                <PublishSelector publishWithSegments={true} />
                              ) : null}

                              {targetingState !== 4 && (
                                <hr className="m-t-6 m-b-6" />
                              )}
                            </>
                          )}
                        </UserConsumer>

                        <PublishOptionsOnly
                          onCancel="/feed"
                          subject="news"
                          showDraftButton={isCreating || isDraft}
                          deleteButtonText="Unpublish"
                        >
                          <Channels
                            isCreating={isCreating}
                            titleClass="article-editor__targetingview--channels"
                          />
                          <hr className="m-t-6 m-b-6" />
                          <Typography
                            variant="body1"
                            sx={(theme) => ({
                              marginBottom: '30px',
                              fontWeight: theme.typography.fontWeightBold,
                            })}
                          >
                            Publishing options
                          </Typography>
                          <div className="article-editor__targetingview--checkbox">
                            <div className="article-editor__targetingview--checkbox--featurednews">
                              <Checkbox
                                defaultChecked={article.isPriority}
                                id="priorityItem"
                                onChange={() =>
                                  onToggleArticleValue('isPriority')
                                }
                              />
                              <label htmlFor="priorityItem" className="m-l-3">
                                Featured article
                              </label>
                            </div>
                            <hr className="m-t-2 m-b-2" />
                            <ScreenSelector />
                            <hr className="m-t-2 m-b-2" />
                            <DisableComments />
                          </div>
                          <hr className="m-t-6 m-b-6" />
                          <ArticleInformationOwner />
                          <hr className="m-t-6 m-b-6" />
                          <AdditionalFeedbackReceivers />
                          <hr className="m-t-6 m-b-6" />
                          <PublishDateFifteenMinutesStep />
                          <hr className="m-t-6 m-b-6" />
                        </PublishOptionsOnly>
                      </>
                    );
                  }}
                </Consumer>
              </>
            </Box>
          </Column>
        </Container>
      </div>
    </CreateOrEdit>
  );
};

export default NewsEdit;
