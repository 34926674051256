export type Job = {
  id: number;
  title: string;
  description?: string;
  createdUtc?: string;
  publishedUtc?: string;
  applicationDueUtc?: string;
  applicationFormUrl?: string;
  hiringManager?: string;
  category?: { id: number; name: string };
  department?: { id: number; name: string };
  seniorityLevel?: { id: number; name: string };
  regions: { id: number; name: string }[];
  states: { id: number; name: string }[];
  isMostRelevant?: boolean;
  places: string[];
};

export type JobListingItem = {
  id: number;
  title: string;
  applicationDueUtc?: string;
  hiringManager?: string;
  category?: { id: number; name: string };
  department?: { id: number; name: string };
  isMostRelevant?: boolean;
  places: string[];
};

export enum JobFilterName {
  Locations = 'locations',
  States = 'states',
  Categories = 'categories',
  SeniorityLevels = 'seniorityLevels',
}

export type JobFilterOption = {
  id: number;
  name: string;
};

export type JobFilterOptionState = JobFilterOption & {
  locationId: number;
};

export type JobListingFilter = {
  [JobFilterName.Locations]: JobFilterOption[];
  [JobFilterName.States]: JobFilterOptionState[];
  [JobFilterName.Categories]: JobFilterOption[];
  [JobFilterName.SeniorityLevels]: JobFilterOption[];
};
