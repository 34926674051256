import './SHHouseContent.scss';

import { ReactComponent as ArrowLeft } from 'components/Icon/SmallArrowLeft.svg';
import { Dispatch, FC, SetStateAction } from 'react';

import { ReactComponent as BottomBox } from './BottomBox.svg';
import { ReactComponent as LeftSmallBox } from './LeftSmallBox.svg';
import { ReactComponent as MiddleSmallBox } from './MiddleSmallBox.svg';
import { ReactComponent as NextToBottomBox } from './NextToBottomBox.svg';
import { ReactComponent as RightSmallBox } from './RightSmallBox.svg';
import { ReactComponent as RoofIcon } from './RoofIcon.svg';
import { ReactComponent as UpperBox } from './UpperBox.svg';

interface SHHouseContentProps {
  activeSHButton: number;
  content: {
    title: string;
    textContent: string;
    button: string;
    prevButton: string;
    nextButton: string;
  };
  changeActiveState: (activeBox: number) => void;
  setIsRoofFilled: Dispatch<SetStateAction<boolean>>;
  width: number;
}

const SHHouseContent: FC<SHHouseContentProps> = ({
  activeSHButton,
  content,
  changeActiveState,
  setIsRoofFilled,
  width,
}) => {
  const getSmallHouseIcon = () => {
    switch (activeSHButton) {
      case 1:
        return <RoofIcon />;
      case 2:
        return <UpperBox />;
      case 3:
        return <LeftSmallBox />;
      case 4:
        return <MiddleSmallBox />;
      case 5:
        return <RightSmallBox />;
      case 6:
        return <NextToBottomBox />;
      case 7:
        return <BottomBox />;
      default:
        return <div className="sh-house-content__dummy-icon-house"></div>;
    }
  };

  return (
    <div className="sh-house-content">
      <div className="sh-house-content__container">
        {width < 768 ? (
          <>
            <div className="sh-house-content__icon-house-container">
              {getSmallHouseIcon()}
            </div>
            <div className="sh-house-content__button-container">
              {content?.prevButton ? (
                <div
                  onClick={() => {
                    changeActiveState(activeSHButton - 1);
                    setIsRoofFilled(false);
                  }}
                  className="sh-house-content__prev-button"
                >
                  <ArrowLeft className="sh-house-content__arrow-left" />
                  {`To ${content?.prevButton.toLowerCase()}`}
                </div>
              ) : (
                <></>
              )}
              {content?.nextButton && (
                <div
                  onClick={() => {
                    changeActiveState(activeSHButton + 1);
                    setIsRoofFilled(false);
                  }}
                  className="sh-house-content__next-button"
                >
                  {`To ${content?.nextButton.toLowerCase()}`}

                  <ArrowLeft className="sh-house-content__arrow-right" />
                </div>
              )}
            </div>
          </>
        ) : (
          <></>
        )}

        <h1 className="sh-house-content__title">{content?.title}</h1>
        <div
          dangerouslySetInnerHTML={{ __html: content?.textContent }}
          className="sh-house-content__text-content"
        ></div>

        {width > 767 ? (
          <div className="sh-house-content__button-container">
            {content?.prevButton && (
              <div
                onClick={() => {
                  changeActiveState(activeSHButton - 1);
                  setIsRoofFilled(false);
                }}
                className="sh-house-content__prev-button"
              >
                <ArrowLeft className="sh-house-content__arrow-left" />
                {`To ${content?.prevButton.toLowerCase()}`}
              </div>
            )}
            {content?.nextButton && (
              <div
                onClick={() => {
                  changeActiveState(activeSHButton + 1);
                  setIsRoofFilled(false);
                }}
                className="sh-house-content__next-button"
              >
                {`To ${content?.nextButton.toLowerCase()}`}

                <ArrowLeft className="sh-house-content__arrow-right" />
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SHHouseContent;
