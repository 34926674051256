import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import * as API from 'api/cms/MyMoment';
import CreateOrEdit from 'components/CreateOrEditArticle';
import Body from 'components/CreateOrEditArticle/components/Body';
import CommentsTargetId from 'components/CreateOrEditArticle/components/CommentsTargetId';
import Hero from 'components/CreateOrEditArticle/components/Hero';
import Playlist from 'components/CreateOrEditArticle/components/Playlist';
import PublishOptions from 'components/CreateOrEditArticle/components/PublishOptions';
import Title from 'components/CreateOrEditArticle/components/Title';
import { Page } from 'layout';
import { RouteComponentProps } from 'react-router-dom';
import { translations } from 'translations';

import { MyMomentSkeleton } from '../MyMoment/MyMomentSkeleton';

interface MatchParams {
  id: string;
  type: string;
}

type MyMomentCreateOrEditProps = RouteComponentProps<MatchParams>;

const MyMomentCreateOrEdit = ({
  match,
  history,
  location,
}: MyMomentCreateOrEditProps) => {
  const isCreating = match.params.type === 'create';
  const fields = [
    { key: 'title', displayName: 'Title', maxLength: 90 },
    { key: 'heroImage' },
    { key: 'heroVideo' },
    { key: 'body' },
    { key: 'commentsTargetId', required: true, maxLength: 60 },
    { key: 'videoPlaylistId' },
  ];

  return (
    <Page title={translations.myMoment}>
      <CreateOrEdit
        isCreating={isCreating}
        viewableByRole="AvenueAdmin"
        pageTitle="My Moment"
        location={location}
        fields={fields}
        ContentLoader={MyMomentSkeleton}
        onFetch={() => !isCreating && API.getMyMoment()}
        onCreate={API.createMyMoment}
        onEdit={API.updateMyMoment}
        onAfterCreate={() => {
          history.push(`/`);
        }}
        onAfterDelete={() => {
          history.push('/');
        }}
        onAfterCancel={() => {
          history.push('/');
        }}
      >
        <Stack
          sx={(theme) => ({
            padding: theme.spacing('md'),
            backgroundColor: theme.colors.surface.primary,
            borderRadius: '4px',
            gap: theme.spacing('md'),
            flexDirection: { xs: 'column', md: 'row' },
          })}
        >
          <Stack
            sx={(theme) => ({
              gap: theme.spacing('md'),
              width: '100%',
            })}
          >
            <Title placeholder="Title (max 90 characters)" />
            <Hero useVideo={true} useAltText={true} />
            <Box>
              <Body
                placeholder="My moment body content"
                showSideButtons={false}
              />
            </Box>
            <CommentsTargetId placeholder="My moment id for comments" />
          </Stack>
          <Playlist />
        </Stack>
        <Stack flexDirection="row-reverse">
          <PublishOptions
            onCancel="/"
            subject="my-moment"
            hideDelete={true}
          ></PublishOptions>
        </Stack>
      </CreateOrEdit>
    </Page>
  );
};

export { MyMomentCreateOrEdit };
