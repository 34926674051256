export interface ProfileResponse {
  data: {
    data: {
      id: string;
      type: string;
      attributes: {
        city?: string;
        companyId?: string;
        companyName?: string;
        country?: string;
        countryId?: string;
        divisionId?: string;
        divisionName?: string;
        email?: string;
        firstName?: string;
        imageUrl?: string;
        isGPSLive?: boolean;
        isManager?: boolean;
        lastName?: string;
        mobilePhoneNo?: string;
        phoneNo?: string;
        regionId?: string;
        regionName?: string;
        siteId?: string;
        siteName?: string;
        title?: string;
      };
    };
  };
}

export interface Profile {
  id: string;
  city?: string;
  companyId?: string;
  companyName?: string;
  country?: string;
  countryId?: string;
  divisionId?: string;
  divisionName?: string;
  email?: string;
  firstName?: string;
  imageUrl?: string;
  isGPSLive?: boolean;
  isManager?: boolean;
  lastName?: string;
  mobilePhoneNo?: string;
  phoneNo?: string;
  regionId?: string;
  regionName?: string;
  siteId?: string;
  siteName?: string;
  title?: string;
}

export interface UpdateProfileSettingsPayload {
  // Not all possible fields are included,
  // add more when needed
  division?: {
    data: {
      type: 'division';
      id: string;
      name: string;
    };
  };
  segment?: {
    data: {
      type: 'segment';
      id: string;
      name: string;
    };
  };
  country?: {
    data: {
      type: 'country';
      id: string;
      name: string;
    };
  };
  department?: {
    data: {
      type: 'department';
      id: string;
      name: string;
    };
  };
  site?: {
    data: {
      type: 'site';
      id: string;
      name: string;
    };
  };
}

export interface Division {
  id: string;
  name: string;
}

export interface Segment {
  id: string;
  name: string;
}

export interface Department {
  id: string;
  name: string;
}

export interface Site {
  id: string;
  name: string;
}

export interface Company {
  id: string;
  name: string;
}

export interface Country {
  id: string;
  name: string;
}

export interface DivisionsResponse {
  data: {
    data: {
      id: string;
      type: string;
      attributes: {
        name: string;
      };
      relationships: {
        parentDivision: {
          data: {
            id: string;
            type: string;
          };
        };
        childCountries: {
          data: {
            id: string;
          }[];
        };
      };
    }[];
    included: {
      id: string;
      type: string;
      attributes: {
        name: string;
      };
    }[];
  };
}

export type DivisionsTree = {
  rootNode: {
    id: string;
    name: string;
  };
  divisions: DivisionWithSegmentsAndCountries[];
};

export type DivisionWithSegmentsAndCountries = {
  id: string;
  name: string;
  segments: {
    id: string;
    name: string;
  }[];
  countries: {
    id: string;
    name: string;
  }[];
};

export type DepartmentsResponse = {
  data: {
    data: {
      id: string;
      type: string;
      attributes: {
        name: string;
      };
    }[];
  };
};

export enum ShortcutTypeName {
  Tools = 'Tools',
  MyProjects = 'My Projects',
}

export interface Shortcut {
  id: string;
  title: string;
  description: string;
  url: string;
  sortOrder: number;
  addedAsFavorite: boolean;
  openInNewWindow?: string;
  type: string;
  shortcutType: {
    displayName: ShortcutTypeName;
    id: number;
    type: string;
  };
}
