import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  FormFieldWrapper,
  FormFieldWrapperProps,
} from 'layout/Form/FormFieldWrapper';

type PreferencesFormFieldWrapperProps = FormFieldWrapperProps;

const PreferencesFormFieldWrapper = ({
  label,
  ...rest
}: PreferencesFormFieldWrapperProps) => {
  return (
    <Stack
      sx={(theme) => ({
        flexDirection: { xs: 'column', sm: 'row' },
        rowGap: theme.spacing('xxxs'),
      })}
    >
      <Typography
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          flexShrink: 0,
          color: theme.colors.text.tertiary,
          height: { sm: '40px' },
          width: '8rem',
        })}
      >
        {label}
      </Typography>
      <FormFieldWrapper
        hideLabel
        label={label}
        {...rest}
        sx={{ flexGrow: 1 }}
      />
    </Stack>
  );
};

export { PreferencesFormFieldWrapper };
