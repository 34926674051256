import 'styles/global.scss';

import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AppInsights from 'components/AppInsights';
import NextArticleProvider from 'components/Context/NextArticle';
import UserProvider, { UserConsumer } from 'components/Context/User';
import Footer from 'components/Footer';
import HistoryListener from 'components/HistoryListener';
import Modals from 'components/Modals';
import Outline from 'components/Outline';
import Hotjar from 'components/Plugins/Hotjar';
import UserMetaGA from 'components/Plugins/UserMetaGA';
import ScrollToTop from 'components/ScrollToTop';
import SurveyPopup from 'components/SurveyPopup';
import {
  AIChatProvider,
  AlertsProvider,
  LibraryRootProvider,
  RedirectsProvider,
  SnackbarProvider,
  WhitelistedUrlsProvider,
} from 'context';
import { AvenueRouteEnum, InnovationRouteEnum } from 'enums';
import Portals from 'pages/Portals';
import PortalsRedirect from 'pages/Portals/Redirect';
import { Root as AvenueRoot } from 'pagesAvenue';
import { Root as InnovationRoot } from 'pagesInnovation';
import { useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import settings from 'settings';
import { createTheme } from 'theme';
import { FeatureToggle, featureToggle } from 'utils/misc/featureToggle';

const { insightsDbPageId } = settings.misc;

const theme = createTheme();

const ForceRedirect = ({ url }) => {
  window.location.href = window.location.origin + url;
  return <div style={{ minHeight: '100vh' }} />;
};

const Root = () => {
  const [overrideTheme, setOverrideTheme] = useState(null);

  if (featureToggle(FeatureToggle.AssaFont) && !overrideTheme) {
    const _overrideTheme = createTheme({ enableAssaFont: true });
    setOverrideTheme(_overrideTheme);
  }

  return (
    <ThemeProvider theme={overrideTheme || theme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        dateFormats={{ keyboardDate: 'yyyy-MM-dd' }}
      >
        <WhitelistedUrlsProvider>
          <RedirectsProvider>
            <UserProvider>
              <LibraryRootProvider>
                <SnackbarProvider>
                  <AlertsProvider>
                    <NextArticleProvider>
                      <Router>
                        <AIChatProvider>
                          <>
                            <ScrollToTop />
                            <AppInsights />
                            <Outline />
                            <HistoryListener />
                            <UserConsumer>
                              {({
                                isLoading,
                                blockedUser,
                                isManager,
                                divisionId,
                                departmentId,
                                userId,
                                regionId,
                                nouser,
                                country,
                                showSurvey,
                                segmentId,
                              }) => (
                                <>
                                  {showSurvey && <SurveyPopup />}
                                  {nouser && <Redirect to="/nouser" />}
                                  <UserMetaGA
                                    division={divisionId}
                                    userId={userId}
                                    department={departmentId}
                                    regionId={regionId}
                                    isManager={isManager}
                                    countryName={country}
                                    segmentId={segmentId}
                                  />
                                  {isLoading || blockedUser ? null : <Modals />}
                                  {blockedUser && (
                                    <ForceRedirect url="/public/unauthorized-user" />
                                  )}
                                </>
                              )}
                            </UserConsumer>
                            <Switch>
                              <Route
                                path="/portals/:portalUrl/:pageId?/:type(edit|create|create-block)?"
                                component={Portals}
                              />
                              <Route
                                path="/portals-redirect/:portalId/:pageId?"
                                component={PortalsRedirect}
                              />
                              <Route
                                path="/innovation"
                                render={() => (
                                  <ForceRedirect url="/portals/innovation" />
                                )}
                              />
                              <Route
                                path="/insights-database"
                                render={() => (
                                  <ForceRedirect
                                    url={`/portals/innovation/${insightsDbPageId}`}
                                  />
                                )}
                              />
                              <Route
                                path="/public"
                                render={({ location }) => (
                                  <ForceRedirect url={location.pathname} />
                                )}
                              />
                              <Route
                                path={InnovationRouteEnum.Home}
                                component={InnovationRoot}
                              />
                              <Route
                                path={AvenueRouteEnum.Home}
                                component={AvenueRoot}
                              />
                              <Redirect to="/404" />
                            </Switch>
                            <Footer />
                            <Hotjar />
                          </>
                        </AIChatProvider>
                      </Router>
                    </NextArticleProvider>
                  </AlertsProvider>
                </SnackbarProvider>
              </LibraryRootProvider>
            </UserProvider>
          </RedirectsProvider>
        </WhitelistedUrlsProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default Root;
