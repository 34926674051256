import {
  HeroBannerCreate,
  HeroBannerGetResponseData,
  HeroBannerSize,
} from 'api/cms/HeroBanner/types';
import { GLOBAL_DIVISION_ID } from 'api/organization/transformers';
import { Division } from 'api/organization/types';
import { translations } from 'translations';

import { FormValues } from './HeroBannerForm';

export const GLOBAL_DIVISION: Division = {
  id: GLOBAL_DIVISION_ID,
  name: translations.globalAllEmployees,
};

const transformFormValuesToCreateOrEditPayload = (data: FormValues) => {
  const transformed: HeroBannerCreate = {
    size: data.size,
    title: data.title,
    description: data.description || undefined,
    linkUrl: data.linkUrl || undefined,
    linkButtonText: data.linkLabel || undefined,
    heroImage: data.heroImage.uploaded || undefined,
    heroAltText: data.heroImage.altText || undefined,
    publishedDate: (data.publishedDate || new Date()).toISOString(),
    publishedInDivisions: data.publishedInDivisions.filter(
      (division) => division.id !== GLOBAL_DIVISION_ID
    ),
    overrideDivisionalMessage: data.overrideDivisionalMessage,
  };
  return transformed;
};

const transformGetResponseToFormValues = (
  fetchData: HeroBannerGetResponseData
) => {
  const transformed: FormValues = {
    size: fetchData.size || HeroBannerSize.Medium,
    title: fetchData.title,
    description: fetchData.description || '',
    linkUrl: fetchData.linkUrl || '',
    linkLabel: fetchData.linkButtonText || '',
    heroImage: {
      uploaded: fetchData.heroImage,
      altText: fetchData.heroAltText,
    },
    publishedDate: fetchData.publishedDate
      ? new Date(fetchData.publishedDate)
      : null,
    publishedInDivisions: !fetchData.publishedInDivisions?.length
      ? [GLOBAL_DIVISION]
      : fetchData.publishedInDivisions,
    overrideDivisionalMessage: fetchData.overrideDivisionalMessage || false,
  };
  return transformed;
};

export {
  transformFormValuesToCreateOrEditPayload,
  transformGetResponseToFormValues,
};
