import PageHeader from 'components/PageHeader';
import { InnovationRouteEnum } from 'enums';
import { Learning } from 'pagesInnovation';
import { Route, Switch } from 'react-router-dom';

const Root = () => {
  return (
    <>
      <PageHeader signedIn={true} />
      <main className="main-content">
        <Switch>
          <Route
            exact
            path={InnovationRouteEnum.Learning}
            component={Learning}
          />
        </Switch>
      </main>
    </>
  );
};

export { Root };
