import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as jobsApi from 'api/jobs';
import { Job, JobListingFilter, JobListingItem } from 'api/jobs/types';
import { useUser } from 'components/Context/User';
import { Icon } from 'componentsNew';
import { useSnackbar } from 'context';
import { AvenueRouteEnum } from 'enums';
import { useSearchParams } from 'hooks/useSearchParams';
import { Page, PageTitle } from 'layout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import {
  GAonJobSearchSubmit,
  GAonMostRelevantJobItemClick,
  GAonShowMoreClick,
} from 'utils/analytics';

import * as helpers from './helpers';
import { JobsRequestParams } from './helpers';
import { JobContent } from './JobContent/JobContent';
import { FilterParam, JobFilter } from './JobFilter/JobFilter';
import { JobList } from './JobList/JobList';
import { JobSearch } from './JobSearch/JobSearch';

const INITIAL_PAGINATION = {
  page: 1,
  rowsPerPage: 15,
};

const Jobs = () => {
  const [jobContentById, setJobContentById] = useState<Record<string, Job>>({});

  const [isJobContentError, setIsJobContentError] = useState<boolean>(false);
  const [isJobContentLoading, setIsJobContentLoading] =
    useState<boolean>(false);

  const [jobList, setJobList] = useState<{
    items: JobListingItem[];
    total: number;
  } | null>(null);

  const [isJobListError, setIsJobListError] = useState<boolean>(false);
  const [isJobListLoading, setIsJobListLoading] = useState<boolean>(false);

  const [availableFilter, setAvailableFilter] =
    useState<JobListingFilter | null>(null);

  const [pagination, setPagination] =
    useState<typeof INITIAL_PAGINATION>(INITIAL_PAGINATION);

  const user = useUser();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const urlSearchParams = useSearchParams();
  const urlPathParams = useParams<{ id?: string }>();
  const location = useLocation();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  const idParam = useMemo(() => {
    return urlPathParams.id ? Number(urlPathParams.id) : null;
  }, [urlPathParams.id]);

  const filterParam = useMemo(() => {
    const filter = urlSearchParams.get('filter');
    return filter ? (JSON.parse(filter) as FilterParam) : null;
  }, [urlSearchParams]);

  const searchParam = useMemo(() => {
    const search = urlSearchParams.get('search');
    return search ? (JSON.parse(search) as string) : null;
  }, [urlSearchParams]);

  const userParam = useMemo(() => {
    const departmentName = user.departmentName as string | undefined;
    const countryName = user.countryName as string | undefined;
    return { departmentName, countryName };
  }, [user.departmentName, user.countryName]);

  const documentTitle = useMemo(() => {
    if (idParam && jobContentById[idParam]) {
      return [translations.jobs, jobContentById[idParam].title];
    }
    return null;
  }, [idParam, jobContentById]);

  const fetchJobList = useCallback(async (params: JobsRequestParams) => {
    const jobList: {
      items: JobListingItem[];
      total: number;
    } = { items: [], total: 0 };

    try {
      setIsJobListLoading(true);
      setIsJobListError(false);
      const queryString = helpers.getRequestQueryString(params);
      const response = await jobsApi.getJobs(queryString);
      if (!response) {
        throw Error();
      }
      jobList.total = response.data.meta.page.total;
      jobList.items = response.data.data as JobListingItem[];
    } catch {
      setIsJobListError(true);
    } finally {
      setIsJobListLoading(false);
      return jobList;
    }
  }, []);

  const fetchJobContent = useCallback(async (params: { id: number }) => {
    let jobContent: Job | null = null;
    try {
      setIsJobContentLoading(true);
      setIsJobContentError(false);
      const response = await jobsApi.getJob(params.id);
      if (!response) {
        throw Error();
      }
      jobContent = response.data.data as Job;
    } catch {
      setIsJobContentError(true);
    } finally {
      setIsJobContentLoading(false);
      return jobContent;
    }
  }, []);

  const fetchAvailableFilter = useCallback(async () => {
    try {
      const response = await jobsApi.getJobsFilter();
      if (!response?.data?.data) {
        throw Error();
      }
      return response.data.data as JobListingFilter;
    } catch {
      showSnackbar({ type: 'error', text: translations.jobsFilterFetchError });
      return null;
    }
  }, [showSnackbar]);

  const handleJobListItemClick = useCallback(
    (item: JobListingItem) => {
      if (item.isMostRelevant) {
        GAonMostRelevantJobItemClick(item.id, item.title);
      }
      history.push({
        pathname: `${AvenueRouteEnum.Jobs}/${item.id}`,
        search: location.search,
      });
    },
    [history, location.search]
  );

  const handlePaginationChange = useCallback(
    async (newPagination: { page: number; rowsPerPage: number }) => {
      setPagination(newPagination);
      const newListContent = await fetchJobList({
        user: userParam,
        filter: filterParam || {},
        search: searchParam || '',
        pagination: newPagination,
      });
      setJobList(newListContent);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      GAonShowMoreClick('Jobs');
    },
    [fetchJobList, filterParam, searchParam, userParam]
  );

  const handleFilterChange = useCallback(
    async (filter: FilterParam) => {
      const newListContent = await fetchJobList({
        user: userParam,
        filter: filter || {},
        search: searchParam || '',
        pagination: INITIAL_PAGINATION,
      });
      setJobList(newListContent);
      setPagination(INITIAL_PAGINATION);
    },
    [fetchJobList, searchParam, userParam]
  );

  const handleSearchSubmit = useCallback(
    async (value: string) => {
      const newListContent = await fetchJobList({
        user: userParam,
        filter: filterParam || {},
        search: value || '',
        pagination: INITIAL_PAGINATION,
      });
      setJobList(newListContent);
      setPagination(INITIAL_PAGINATION);
      if (value) {
        GAonJobSearchSubmit(value);
      }
    },
    [fetchJobList, filterParam, userParam]
  );

  // Fetch initial job list with "filter" and "search" from url search params
  useEffect(() => {
    async function fetchInitialJobList() {
      const isAlreadyLoaded = Boolean(jobList);
      const isNotVisible = !isDesktop && Boolean(idParam);

      if (user.isLoading || isAlreadyLoaded || isNotVisible) {
        return;
      }
      const newJobList = await fetchJobList({
        user: userParam,
        filter: filterParam || {},
        search: searchParam || '',
        pagination: INITIAL_PAGINATION,
      });
      setJobList(newJobList);
    }
    fetchInitialJobList();
  }, [
    fetchJobList,
    filterParam,
    idParam,
    isDesktop,
    jobList,
    searchParam,
    user.isLoading,
    userParam,
  ]);

  // Fetch job content based on "id" in url path params
  useEffect(() => {
    async function fetchInitialJobContent() {
      if (!idParam || jobContentById[idParam]) {
        return;
      }
      const newJobContent = await fetchJobContent({ id: idParam });
      if (!newJobContent) {
        return;
      }
      setJobContentById((prevJobContentById) => ({
        ...prevJobContentById,
        [idParam]: newJobContent,
      }));
    }
    fetchInitialJobContent();
  }, [fetchJobContent, jobContentById, idParam]);

  // Fetch available filters for jobs list
  useEffect(() => {
    async function fetchInitialAvailableFilter() {
      const newAvailableFilter = await fetchAvailableFilter();
      if (!newAvailableFilter) {
        return;
      }
      setAvailableFilter(newAvailableFilter);
    }
    fetchInitialAvailableFilter();
  }, [fetchAvailableFilter]);

  // Set "id" in url path params if url has no id already
  useEffect(() => {
    if (idParam || !isDesktop || !jobList || !jobList.items.length) {
      return;
    }
    const defaultJobId = jobList.items[0].id;
    history.replace({
      pathname: `${AvenueRouteEnum.Jobs}/${defaultJobId}`,
      search: location.search,
    });
  }, [history, idParam, isDesktop, jobList, location.search]);

  if (isDesktop) {
    return (
      <Page title={documentTitle}>
        <Stack sx={(theme) => ({ gap: theme.spacing('xxs') })}>
          <Stack
            sx={(theme) => ({
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: theme.spacing('xxxs'),
            })}
          >
            <PageTitle
              display={translations.jobs}
              hidden={documentTitle ? documentTitle.join(' | ') : ''}
            />
            <JobSearch onSubmit={handleSearchSubmit} />
          </Stack>
          <Divider />
          {availableFilter && jobList && (
            <>
              <JobFilter
                jobListTotal={jobList.total}
                availableFilter={availableFilter}
                onChange={handleFilterChange}
              />
              <Divider />
            </>
          )}
          <Stack sx={() => ({ flexDirection: 'row' })}>
            <JobList
              sx={{ width: '30%', flexShrink: 0 }}
              selectedItemId={idParam || undefined}
              jobList={jobList}
              pagination={pagination}
              isLoading={isJobListLoading}
              isError={isJobListError}
              onPaginationChange={handlePaginationChange}
              onItemClick={handleJobListItemClick}
            />
            <JobContent
              sx={{ flexGrow: 1 }}
              job={idParam ? jobContentById[idParam] : undefined}
              isLoading={isJobContentLoading || (!idParam && isJobListLoading)}
              isError={
                isJobContentError && !isJobListLoading && !isJobListError
              }
            />
          </Stack>
        </Stack>
      </Page>
    );
  }

  if (idParam) {
    return (
      <Page title={documentTitle}>
        <Stack sx={(theme) => ({ gap: theme.spacing('xxs') })}>
          <PageTitle
            display={translations.jobs}
            hidden={documentTitle ? documentTitle.join(' | ') : ''}
          />
          <Divider />
          <Button
            variant="text"
            sx={{ alignSelf: 'baseline' }}
            startIcon={<Icon type="arrowLongLeft" color="brandBase" />}
            component={ReactRouterLink}
            to={{
              pathname: `${AvenueRouteEnum.Jobs}`,
              search: location.search,
            }}
          >
            {translations.back}
          </Button>
          <Divider />
          <JobContent
            job={jobContentById[idParam]}
            isLoading={isJobContentLoading}
            isError={isJobContentError}
          />
        </Stack>
      </Page>
    );
  }

  return (
    <Page title={[translations.jobs]}>
      <Stack sx={(theme) => ({ gap: theme.spacing('xxs') })}>
        <Stack
          sx={(theme) => ({
            flexDirection: 'column',
            gap: theme.spacing('xxs'),
            marginBottom: theme.spacing('xxxs'),
          })}
        >
          <PageTitle
            display={translations.jobs}
            hidden={documentTitle ? documentTitle.join(' | ') : ''}
          />
          <JobSearch onSubmit={handleSearchSubmit} />
        </Stack>
        <Divider />
        {availableFilter && jobList && (
          <>
            <JobFilter
              jobListTotal={jobList.total}
              availableFilter={availableFilter}
              onChange={handleFilterChange}
            />
            <Divider />
          </>
        )}
        <JobList
          jobList={jobList}
          pagination={pagination}
          isLoading={isJobListLoading}
          isError={isJobListError}
          onPaginationChange={handlePaginationChange}
          onItemClick={handleJobListItemClick}
        />
      </Stack>
    </Page>
  );
};

export { Jobs };
