import { AlertCreate } from 'api/cms/alert/types';
import { GLOBAL_DIVISION_ID } from 'api/organization/transformers';
import { Division } from 'api/organization/types';
import { translations } from 'translations';
import {
  createUTCDateEndOfDay,
  createUTCDateStartOfDay,
} from 'utils/misc/time';

import { FormValues } from './AlertForm';

export const GLOBAL_DIVISION: Division = {
  id: GLOBAL_DIVISION_ID,
  name: translations.globalAllEmployees,
};

export const transformFormValuesToCreatePayload = (data: FormValues) => {
  if (!data.startDate || !data.endDate) {
    throw Error();
  }

  const transformedData: AlertCreate = {
    title: data.title,
    notification: data.description,
    publishedInDivisions: data.publishedInDivisions.filter(
      (division) => division.id !== GLOBAL_DIVISION_ID
    ),
    publishedInCountries: data.publishedInCountries,
    startDate: createUTCDateStartOfDay(data.startDate).toISOString(),
    endDate: createUTCDateEndOfDay(data.endDate).toISOString(),
  };

  return transformedData;
};
