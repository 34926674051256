import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Icon } from 'componentsNew';
import { KeyboardKeyEnum } from 'enums';
import { useState } from 'react';
import { useMemo } from 'react';
import settings from 'settings';
import { translations } from 'translations';

const { cdnTokenKey } = settings.misc;
const { cms } = settings.cmsBaseImagePath;

export type ImageGalleryItem = {
  name: string;
  sortIndex: number;
  path: string;
};

type ImageGalleryProps = {
  items: ImageGalleryItem[];
};

export const ImageGallery = ({ items }: ImageGalleryProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const displayItems = useMemo(() => {
    return {
      firstRowItem: items[0],
      secondRowItems: items.slice(1, 5),
      remainingItems: items.slice(5),
    };
  }, [items]);

  const onClickDisplayItem = (item: ImageGalleryItem) => {
    const clickedItemIndex = items.findIndex(
      (_item) => _item.path === item.path
    );
    setActiveItemIndex(clickedItemIndex);
    setIsModalOpen(true);
  };

  const onModalKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KeyboardKeyEnum.ArrowRight) {
      onClickNextButton();
      return;
    }
    if (event.key === KeyboardKeyEnum.ArrowLeft) {
      onClickPreviousButton();
    }
  };

  const onClickNextButton = () => {
    const newImageGalleryModalItemIndex =
      activeItemIndex === items.length - 1 ? 0 : activeItemIndex + 1;
    setActiveItemIndex(newImageGalleryModalItemIndex);
  };

  const onClickPreviousButton = () => {
    const newActiveItemIndex =
      activeItemIndex === 0 ? items.length - 1 : activeItemIndex - 1;
    setActiveItemIndex(newActiveItemIndex);
  };

  const { firstRowItem, secondRowItems, remainingItems } = displayItems;

  return (
    <Stack
      sx={(theme) => ({
        margin: `${theme.spacing('sm')} 0`,
        gap: theme.spacing('xxxxs'),
        ':hover': {
          cursor: 'zoom-in',
        },
      })}
    >
      <Box
        component="img"
        src={`${cms}${firstRowItem.path}?${cdnTokenKey}`}
        onClick={() => onClickDisplayItem(firstRowItem)}
        sx={() => ({
          width: '100%',
          height: '250px',
          objectFit: 'cover',
          transition: 'all 0.3s ease',
          ':hover': {
            filter: `brightness(80%)`,
          },
        })}
      />
      {secondRowItems.length > 0 && (
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            gap: theme.spacing('xxxxs'),
            height: '170px',
          })}
        >
          {secondRowItems.map((item, index) => (
            <Box
              key={`${item.path}-${index}`}
              sx={() => ({
                flex: '1 1 0px',
                position: 'relative',
              })}
            >
              <Box
                component="img"
                src={`${cms}${item.path}?${cdnTokenKey}`}
                onClick={() => onClickDisplayItem(item)}
                sx={() => ({
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  transition: 'all 0.3s ease',
                  ':hover': {
                    filter: `brightness(80%)`,
                  },
                })}
              />
              {remainingItems.length > 0 &&
                index === secondRowItems.length - 1 && (
                  <Stack
                    sx={(theme) => ({
                      position: 'relative',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.colors.surface.overlay,
                    })}
                    onClick={() => onClickDisplayItem(item)}
                  >
                    <Typography
                      variant="display4"
                      sx={(theme) => ({
                        color: theme.colors.text.inversePrimary,
                      })}
                    >
                      +{remainingItems.length}
                    </Typography>
                  </Stack>
                )}
            </Box>
          ))}
        </Stack>
      )}

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(!isModalOpen)}
        onKeyDown={onModalKeyDown}
        sx={() => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'all 0.3s ease',
        })}
      >
        <Stack
          sx={(theme) => ({
            width: '1250px',
            maxWidth: '100%',
            height: '80%',
            aspectRatio: '1.25',
            position: 'relative',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: theme.colors.surface.inversePrimary,
            borderRadius: theme.border.radius.lg,
          })}
        >
          <Typography
            variant="body1"
            sx={(theme) => ({
              position: 'absolute',
              top: 0,
              left: 0,
              padding: theme.spacing('sm'),
              color: theme.colors.icon.inversePrimary,
            })}
          >
            {activeItemIndex + 1} / {items.length}
          </Typography>
          {items.length > 1 && (
            <IconButton
              size="small"
              aria-label={translations.imageGalleryModalNextImage}
              onClick={onClickPreviousButton}
              sx={(theme) => ({
                position: 'absolute',
                left: 0,
                margin: theme.spacing('xxs'),
                transition: 'all 0.3s ease',
                '&.MuiButtonBase-root.MuiIconButton-root:hover': {
                  outline: `1px solid ${theme.colors.border.focus}`,
                  boxShadow: `0px 0px 0px 3px ${theme.colors.border.surfaceInformative}`,
                },
              })}
            >
              <Icon
                type="chevronLeft"
                color="inversePrimary"
                svgProps={{ height: 48, width: 48 }}
              />
            </IconButton>
          )}
          <Box
            component="img"
            key={items[activeItemIndex].path}
            src={`${cms}${items[activeItemIndex].path}?${cdnTokenKey}`}
            sx={() => ({
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'cover',
            })}
          />
          {items.length > 1 && (
            <IconButton
              size="small"
              aria-label={translations.imageGalleryModalPreviousImage}
              onClick={onClickNextButton}
              sx={(theme) => ({
                position: 'absolute',
                right: 0,
                margin: theme.spacing('xxs'),
                transition: 'all 0.3s ease',
                '&.MuiButtonBase-root.MuiIconButton-root:hover': {
                  outline: `1px solid ${theme.colors.border.focus}`,
                  boxShadow: `0px 0px 0px 3px ${theme.colors.border.surfaceInformative}`,
                },
              })}
            >
              <Icon
                type="chevronRight"
                color="inversePrimary"
                svgProps={{ height: 48, width: 48 }}
              />
            </IconButton>
          )}
          <IconButton
            size="small"
            aria-label={translations.close}
            onClick={() => setIsModalOpen(!isModalOpen)}
            sx={(theme) => ({
              position: 'absolute',
              top: 0,
              right: 0,
              padding: theme.spacing('xs'),
              margin: theme.spacing('xxs'),
              transition: 'all 0.3s ease',
              '&.MuiButtonBase-root.MuiIconButton-root:hover': {
                outline: `1px solid ${theme.colors.border.focus}`,
                boxShadow: `0px 0px 0px 3px ${theme.colors.border.surfaceInformative}`,
              },
            })}
          >
            <Icon type="xMark" color="inversePrimary" size={32} />
          </IconButton>
        </Stack>
      </Modal>
    </Stack>
  );
};
