import Stack from '@mui/material/Stack';
import { Page, PageTitle } from 'layout';
import { useMemo } from 'react';
import { translations } from 'translations';

import { LearningArticleList } from './LearningArticleList';
import { LearningFilter } from './LearningFilter';

const Learning = () => {
  const documentTitle = useMemo(
    () => [translations.innovationDocumentTitle, translations.learning],
    []
  );
  return (
    <Page title={documentTitle}>
      <Stack sx={(theme) => ({ gap: theme.spacing('md') })}>
        <PageTitle
          display={translations.learning}
          hidden={documentTitle.join(' | ')}
        />
        <Stack
          sx={(theme) => ({
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: theme.spacing('sm'), md: '2rem' },
          })}
        >
          <LearningFilter
            sx={{
              width: { xs: '100%', md: '30%' },
              flexShrink: 0,
            }}
          />
          <LearningArticleList sx={{ flexGrow: 1 }} />
        </Stack>
      </Stack>
    </Page>
  );
};

export { Learning };
