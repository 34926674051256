import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Icon } from 'componentsNew';
import { useMemo, useState } from 'react';
import { translations } from 'translations';

const LIMIT = 3;

type JobContentPlacesProps = {
  elementId: string;
  places: string[];
  isExpandable?: boolean;
};

const JobContentPlaces = ({
  elementId,
  places,
  isExpandable = false,
}: JobContentPlacesProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const content = useMemo(() => {
    if (!places) {
      return { lines: [], text: translations.notAvailable };
    }
    if (!isExpandable && places.length === 1) {
      return { lines: [], text: places[0] };
    }
    if (!isExpandable && places.length > 1) {
      return { lines: [], text: translations.jobsMultipleLocations };
    }
    const hasOverflow = places.length > LIMIT;

    if (isOpen) {
      return { lines: places, hasOverflow, text: null };
    }
    return {
      lines: places.slice(0, LIMIT),
      hasOverflow,
      text: null,
    };
  }, [places, isExpandable, isOpen]);

  return (
    <Stack id={elementId} flexDirection="row">
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          gap: theme.spacing('xxxs'),
          flexGrow: 1,
          ...(content.hasOverflow && {
            cursor: 'pointer',
            '&:hover + .MuiLink-root': {
              color: theme.colors.text.actionHover,
              textDecorationColor: theme.colors.text.actionHover,
            },
          }),
        })}
        {...(content.hasOverflow && {
          onClick: () => setIsOpen((prevIsOpen) => !prevIsOpen),
        })}
      >
        <Icon type="mapPin" color="secondary" size={20} />
        {content.text ? (
          <Typography
            variant="caption"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              color: theme.colors.text.tertiary,
              maxWidth: '40rem',
            })}
          >
            {content.text}
          </Typography>
        ) : (
          <Stack minHeight="100%">
            {content.lines?.map((line, index) => (
              <Typography
                variant="caption"
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.colors.text.tertiary,
                  maxWidth: '40rem',
                  height: '100%',
                })}
              >
                {line}
                {!isOpen &&
                index === content.lines.length - 1 &&
                content.hasOverflow
                  ? `...`
                  : ``}
              </Typography>
            ))}
          </Stack>
        )}
      </Stack>
      {content.hasOverflow && (
        <Link
          id={`${elementId}-expand`}
          variant="caption"
          component="button"
          onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'baseline',
            gap: theme.spacing('xxxxs'),
            flexShrink: 0,
          })}
        >
          {translations.more}
          <Icon
            type="chevronDown"
            color="brandBase"
            size={20}
            sx={{
              transform: isOpen ? 'rotate(-180deg)' : 'none',
              transition: 'transform 100ms linear',
            }}
          />
        </Link>
      )}
    </Stack>
  );
};

export { JobContentPlaces };
