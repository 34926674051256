export const getMySegmentName = (
  publishedInSegments: { id: string; name: string }[],
  segmentId: string
) => {
  const segmentName = publishedInSegments.find(
    (segment) => segment.id === segmentId
  )?.name;

  return segmentName || publishedInSegments[0]?.name;
};
